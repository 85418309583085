import React from 'react';
import { FormikProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import languageService from '../../../../../service/language/languageService';
import FieldItem from '../../../../../component/form/formField/typesField/FieldItem';

interface GeneralFormProps {
  formikProps: FormikProps<any>
  onLanguageChange: (newLocale: string) => void
}

const GeneralForm = ({ formikProps, onLanguageChange }: GeneralFormProps) => {
  const currentUser = formikProps.values;
  const profileCountries = [
    {
      label: 'form.label.country',
      value: currentUser.countryCode,
      type: 'select',
      option: [
        { value: 'ua', label: 'form.label.country.ua' },
        { value: 'us', label: 'form.label.country.us' },
      ],
      onChange: (val: string) => formikProps.setValues({ ...currentUser, countryCode: val }),
      isEdit: true,
    },
    {
      label: 'form.label.language',
      value: languageService.getCurrentLocale(),
      type: 'select',
      option: languageService.getSupportedLanguage(),
      onChange: (newValue: string) => {
        formikProps.setValues({ ...currentUser, languageCode: newValue });
        languageService.setCurrentLocale(newValue.split('-')[0]);
        onLanguageChange(newValue.split('-')[0]);
      },
      isEdit: true,
    },
  ];

  return (
    <div className="general-form">
      <h2 className="form-title">
        <FormattedMessage id="profile.general" />
      </h2>
      {profileCountries.map(({
        label, value, type, option, onChange, isEdit,
      }) => (
        <FieldItem
          key={label}
          className="form-group custom-select"
          label={label}
          value={value}
          onChange={onChange}
          isEdit={isEdit}
          type={type}
          option={option}
          code={value}
        />
      ))}
    </div>
  );
};

export default GeneralForm;
