export const BREAKPOINTS = {
  RATIONS_SLIDER: {
    320: {
      direction: 'vertical' as const,
      slidesPerView: 2.2,
      spaceBetween: 10,
    },
    496: {
      direction: 'horizontal' as const,
      slidesPerView: 2.1,
    },
    1024: {
      direction: 'horizontal' as const,
      slidesPerView: 2.5,
    },
    1200: {
      direction: 'horizontal' as const,
      slidesPerView: 2.1,
    },
  },
};
