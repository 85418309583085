import React, { useEffect, useRef, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage } from 'react-intl';
import Search from '../../../component/search/Search';
import RationItem from './component/RationItem';
import './RationList.scss';
import '../../meal/list/MealList.scss';

import { useAppDispatch } from '../../../hook/useAppDispatch';
import {
  getAvailableFiltersDataToSelect,
  getRations,
  initialAvailableFilter as initialRationFilters,
} from '../../../redux/slice/rationSlice';
import { useAppSelector } from '../../../hook/useAppSelector';
import LoadingSpinner from '../../../component/spinner/LoadingSpinner';
import { RootState } from '../../../redux';
import Filter from '../../../asset/icons/filter.svg';
import RationListFilter from './filter/RationListFilter';
import { BACKEND_RATIONS_PARAMS } from '../../../common/constant/pathConstant';

const RATIONS_SEARCH_COUNT_SIZE = 10;

const RationList = () => {
  const dispatch = useAppDispatch();
  const filterRef = useRef();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState<boolean>(false);

  const rations = useAppSelector((state: RootState) => state.ration.rations);
  const nextCursorId = useAppSelector((state: RootState) => state.ration.nextCursorId);
  const searchRationsResult = useAppSelector((state) => state.ration.search.searchResults);
  // eslint-disable-next-line max-len
  const nextSearchRationsCursorId = useAppSelector(
    (state) => state.ration.search.searchNextCursorId,
  );
  const availableSearchObj = useAppSelector((state) => state.ration.search.availableSearchParam);

  useEffect(() => {
    if (rations.length) return;
    dispatch(getRations({ cursorId: '', size: RATIONS_SEARCH_COUNT_SIZE }));
  }, [dispatch]);

  useEffect(() => {
    if (availableSearchObj === initialRationFilters) {
      dispatch(getAvailableFiltersDataToSelect());
    }
  }, []);

  const loadMore = () => {
    if (!nextCursorId) return;
    dispatch(getRations({ cursorId: nextCursorId, size: RATIONS_SEARCH_COUNT_SIZE }));
  };

  return (
    <section className="ration">
      <div className={`ration__left-container ${isFilterOpen ? 'with-filter-open' : ''}`}>
        <div className="ration__search">
          <Search
            searchQueryKey={BACKEND_RATIONS_PARAMS.NAME}
            // @ts-ignore
            onSearch={filterRef.current?.handleNameSearch}
          />
          <button
            type="button"
            className="meal__filters-button animated-gradient-btn"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            aria-label="ration-filter-button"
            data-testid="ration-filter-button"
          >
            <img src={Filter} alt="filter" />
            <FormattedMessage id="ration.list.filters" />
          </button>
        </div>
        <div className="ration__list" data-testid="ration-list">
          <InfiniteScroll
            dataLength={showSearchResult ? searchRationsResult.length : rations.length}
            hasMore={
              showSearchResult
                ? nextSearchRationsCursorId !== undefined
                : nextCursorId !== undefined
            }
            height="100%"
            next={loadMore}
            loader={<LoadingSpinner />}
          >
            {(showSearchResult ? searchRationsResult : rations).map((ration) => (
              <RationItem
                key={ration.id}
                rationId={ration.id}
                image={ration.images[0]?.url}
                title={ration.name}
                tags={ration.tags}
                duration={ration.durationDays}
                kcal={ration.nutrition.calorie}
                proteins={ration.nutrition.protein}
                fats={ration.nutrition.fat}
                carbohydrates={ration.nutrition.carbo}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
      <div className={`ration__right-container ${isFilterOpen && 'open'}`}>
        <div
          className={`ration__right-top-container ${isFilterOpen && 'open'}`}
          data-testid="ration-top-container"
        >
          <RationListFilter
            setShowSearchResult={setShowSearchResult}
            onFilterClick={() => setIsFilterOpen(!isFilterOpen)}
            setIsFilterOpen={setIsFilterOpen}
            ref={filterRef}
          />
        </div>
      </div>
    </section>
  );
};

export default RationList;
