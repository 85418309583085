import {
  ErrorMessage, Field, Formik, FormikProps,
} from 'formik';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import ChangePass from '../../../../../asset/icons/profile/pass-key.svg';
import './ChangePassword.scss';
import seeIcon from '../../../../../asset/icons/see-eye.svg';
import unSeeIcon from '../../../../../asset/icons/unsee-eye.svg';
import authService from '../../../../../service/auth/authService';
import { convertToErrorMessage } from '../../../../../service/message/messageConverter';
import { changePasswordSchema } from '../../../../../service/validation/schema/profile/changePasswordSchema';
import { useAppDispatch } from '../../../../../hook/useAppDispatch';
import { setMessage } from '../../../../../redux/slice/messageSlice';
import { REGISTRATION_FORM_CONF } from '../../../../../service/validation/schema/registration/registrationSchema';

const TIMER_SHOW_MESSAGE = 3000;

interface ChangePasswordType {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

const initialValue: ChangePasswordType = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const ChangePassword = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [watchPassword, setWatchPassword] = useState<boolean>(false);
  const [watchNewPassword, setWatchNewPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccesMessage, setShowSuccesMessage] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  let timer: NodeJS.Timeout;

  useEffect(() => () => clearTimeout(timer), [setShowSuccesMessage]);

  const handleChangePassword = async (formProps: FormikProps<ChangePasswordType>) => {
    setLoading(true);
    try {
      await changePasswordSchema.validate(formProps.values, REGISTRATION_FORM_CONF);

      authService
        .changePassword(formProps.values.oldPassword, formProps.values.newPassword)
        .then(() => {
          setShowSuccesMessage(true);
          formProps.resetForm();
          timer = setTimeout(() => setShowSuccesMessage(false), TIMER_SHOW_MESSAGE);
        })
        .catch((error: any) => {
          dispatch(setMessage(convertToErrorMessage(error)));
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error: any) {
      dispatch(setMessage(convertToErrorMessage(error)));
      setLoading(false);
    }
  };

  return (
    <>
      <button
        className="change-password setting-btn "
        type="button"
        onClick={() => setIsVisible(!isVisible)}
      >
        <FormattedMessage id="button.changePassword" />
        <img src={ChangePass} alt="Change Password" />
      </button>
      <div className={`change-password__container ${isVisible ? 'active' : ''}`}>
        {isVisible && (
          <Formik
            initialValues={initialValue}
            validationSchema={changePasswordSchema}
            onSubmit={() => {}}
          >
            {(formProps) => (
              <div className="change-password-fields">
                <label htmlFor="oldPassword">
                  <FormattedMessage id="form.label.oldPassword" />
                </label>
                <div className="input-field">
                  <Field
                    data-testid="oldPassword"
                    autoComplete="off"
                    name="oldPassword"
                    type={watchPassword ? 'text' : 'password'}
                  />
                  <button
                    data-testid="watchPassword"
                    onClick={() => setWatchPassword((prev) => !prev)}
                    type="button"
                  >
                    <img src={watchPassword ? unSeeIcon : seeIcon} alt="View" />
                  </button>
                </div>
                <ErrorMessage
                  name="oldPassword"
                  render={(message) => <p className="error-message">{message}</p>}
                />
                <label htmlFor="newPassword">
                  <FormattedMessage id="form.label.newPassword" />
                </label>
                <div className="input-field">
                  <Field
                    data-testid="newPassword"
                    autoComplete="off"
                    name="newPassword"
                    type={watchNewPassword ? 'text' : 'password'}
                  />
                  <button
                    data-testid="watchNewPassword"
                    onClick={() => setWatchNewPassword((prev) => !prev)}
                    type="button"
                  >
                    <img src={watchNewPassword ? unSeeIcon : seeIcon} alt="View" />
                  </button>
                </div>
                <ErrorMessage
                  name="newPassword"
                  render={(message) => <p className="error-message">{message}</p>}
                />
                <label htmlFor="confirmPassword">
                  <FormattedMessage id="form.label.confirmNewPassword" />
                </label>
                <div className="input-field">
                  <Field
                    data-testid="confirmPassword"
                    autoComplete="off"
                    name="confirmPassword"
                    type={watchNewPassword ? 'text' : 'password'}
                  />
                </div>
                <ErrorMessage
                  name="confirmPassword"
                  render={(message) => <p className="error-message">{message}</p>}
                />
                {showSuccesMessage ? (
                  <p className="confirm-message">
                    <FormattedMessage id="profile.update.password" />
                  </p>
                ) : (
                  <button
                    data-testid="changePassword"
                    className="profile-window__save-btn"
                    type="button"
                    onClick={() => handleChangePassword(formProps)}
                    disabled={loading || showSuccesMessage}
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm" />
                    ) : (
                      <FormattedMessage id="button.changePassword" />
                    )}
                  </button>
                )}
              </div>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default ChangePassword;
