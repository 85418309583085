import { useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import RegistrationStep1 from './step/RegistrationStep1';
import RegistrationStep2 from './step/RegistrationStep2';
import RegistrationStep3 from './step/RegistrationStep3';
import RegistrationStep4 from './step/RegistrationStep4';
import RegistrationStep5 from './step/RegistrationStep5';
import RegistrationStep6 from './step/RegistrationStep6';
import './Registration.scss';
import '../../style/scss/style.scss';
import {
  registrationValidationSchemas,
} from '../../service/validation/schema/registration/registrationSchema';
import AuthBackgroudLayout from '../../component/layout/authBackgroudLayout/AuthBackgroudLayout';

export const STEP_FOR_USER_EMAIL_CONFIRM = 1;
export const STEP_FOR_USER_DATA_FILL = 2;

export interface RegistrationFormValues {
  name: string
  username: string
  password: string
  email: string
  confirmationCode: string,
  confirmPassword: string,
  countryCode: string
  languageCode: string
  height: number | '',
  heightUnit: string
  weight: number | '',
  weightGoal: number | '',
  weightUnit: string,
  gender: string
  birthdayDate: string
  activityLevel: string
  imageUrl: string | null
}

export interface RegistrationStepProps {
  onNextStep: () => void
  onPrevStep: () => void
  currentStep: number
  formikProps: FormikProps<RegistrationFormValues>
  loading?: boolean
  validationSchema?: Yup.Schema<RegistrationFormValues>
  setLoading?: (set: boolean) => void
}

const initial : RegistrationFormValues = {
  name: '',
  username: '',
  email: '',
  password: '',
  confirmPassword: '',
  confirmationCode: '',
  countryCode: 'ua',
  languageCode: 'en',
  height: '',
  heightUnit: 'CENTIMETER',
  weight: '',
  weightGoal: '',
  weightUnit: 'KILOGRAM',
  gender: 'MALE',
  birthdayDate: '',
  activityLevel: 'MODERATE_ACTIVE',
  imageUrl: null,
};

export interface CurrentUser {
  username: string
  userId: string
  accessToken: string
}

interface RegistrationProps {
  startRegistrationStep?: number,
  formValues?: Partial<RegistrationFormValues>
}

export const setRegistrationForNumberField = (value: string): string => {
  const numberValue = Number(value);
  return numberValue !== 0 ? numberValue.toString() : '';
};

const Registration = ({ startRegistrationStep = 0, formValues = {} }: RegistrationProps) => {
  const initialValues = { ...initial, ...formValues };
  const [loading, setLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(startRegistrationStep);

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderStep = (formikProps: any) => {
    switch (currentStep) {
      case 0:
        return (
          <RegistrationStep1
            currentStep={currentStep}
            loading={loading}
            formikProps={formikProps}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
          />
        );
      case 1:
        return (
          <RegistrationStep2
            formikProps={formikProps}
            currentStep={currentStep}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
          />
        );
      case 2:
        return (
          <RegistrationStep3
            currentStep={currentStep}
            formikProps={formikProps}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
          />
        );
      case 3:
        return (
          <RegistrationStep4
            currentStep={currentStep}
            formikProps={formikProps}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
          />
        );
      case 4:
        return (
          <RegistrationStep5
            currentStep={currentStep}
            formikProps={formikProps}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
          />
        );
      case 5:
        return (
          <RegistrationStep6
            setLoading={setLoading}
            loading={loading}
            currentStep={currentStep}
            formikProps={formikProps}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={registrationValidationSchemas[currentStep]}
      onSubmit={() => {}}
    >
      {(formikProps) => (
        <AuthBackgroudLayout currentStep={currentStep}>
          <Form className='form-registration' data-testid="form-registration">{renderStep(formikProps)}</Form>
        </AuthBackgroudLayout>
      )}
    </Formik>
  );
};

export default Registration;
