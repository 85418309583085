import apiService from '../api/apiService';
import { getApiBaseUrl } from '../../config/config';
import {
  BACKEND_API_PATH, BACKEND_COMMON_PARAMS,
  BACKEND_INGREDIENTS_PARAMS,
} from '../../common/constant/pathConstant';
import { IngredientGroupApiResponse, IngredientsApiResponse } from '../../redux/slice/ingredientSlice';
import languageService from '../language/languageService';
import { Ingredient } from '../../common/constant/interface/interfaces';

async function getIngredientGroups(
  cursorId: string,
  size: number,
  level: number,
): Promise<IngredientGroupApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.INGREDIENTS_GROUPS}?${
      BACKEND_INGREDIENTS_PARAMS.CURSOR_ID}=${cursorId}&${
      BACKEND_INGREDIENTS_PARAMS.SIZE}=${size}&${
      BACKEND_INGREDIENTS_PARAMS.LEVEL}=${level}&${
      BACKEND_COMMON_PARAMS.LANGUAGE}=${languageService.getCurrentLocale()}`,
  );
}

async function getIngredients(
  cursorId: string,
  size: number,
  groupId: string,
): Promise<IngredientsApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.INGREDIENTS}/${BACKEND_API_PATH.SEARCH}?${
      BACKEND_INGREDIENTS_PARAMS.CURSOR_ID}=${cursorId}&${
      BACKEND_INGREDIENTS_PARAMS.SIZE}=${size}&${
      BACKEND_INGREDIENTS_PARAMS.GROUP_ID}=${groupId}&${
      BACKEND_COMMON_PARAMS.LANGUAGE}=${languageService.getCurrentLocale()}`,
  );
}

async function searchIngredients(
  name: string,
  cursorId: string,
  size: number,
): Promise<IngredientsApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.INGREDIENTS}/${BACKEND_API_PATH.SEARCH}?${
      BACKEND_COMMON_PARAMS.LANGUAGE}=${languageService.getCurrentLocale()}&${
      BACKEND_INGREDIENTS_PARAMS.NAME}=${name}&${
      BACKEND_INGREDIENTS_PARAMS.CURSOR_ID}=${cursorId}&${
      BACKEND_INGREDIENTS_PARAMS.SIZE}=${size}`,
  );
}

async function getIngredientById(id: string): Promise<Ingredient> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.INGREDIENTS}/${id}?${
      BACKEND_COMMON_PARAMS.LANGUAGE}=${languageService.getCurrentLocale()}`,
  );
}

const ingredientService = {
  getIngredientGroups,
  getIngredients,
  searchIngredients,
  getIngredientById,
};

export default ingredientService;
