import React, { useEffect, useState } from 'react';
import './UnitSelect.scss';
import { IngredientUnit, Units } from '../../../../../common/constant/interface/interfaces';
import { getShortUnitName } from '../../../../../service/myRation/getShortUnitName';

interface UnitSelectorProps {
    units: Units[];
    onChange: (unitName: IngredientUnit['name']) => void;
}

const UnitSelector: React.FC<UnitSelectorProps> = ({ units, onChange }) => {
  const [value, setValue] = useState(units[0].unit.name);

  useEffect(() => {
    onChange(value as IngredientUnit['name']);
  }, [value, setValue]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(event.target.value);
  };

  return (
    <div className="unit-selector">
      <select
        className="unit-select"
        name="unit.value.description"
        id="description"
        value={value}
        onChange={handleChange}
      >
        {units.map((option) => (
          <option key={option.unit.id} value={option.unit.name}>
            {getShortUnitName(option.unit.name as IngredientUnit['name'])}
          </option>
        ))}
      </select>
    </div>
  );
};

export default UnitSelector;
