import { AxiosError } from 'axios';
import { ValidationError } from 'yup';
import { Message, MessageType } from '../../redux/slice/messageSlice';
import languageService from '../language/languageService';

const FORM_FILL_VALIDATION_MESSAGE_KEY = 'error.form.fill';

export const convertAxiosErrorToMessage = (err: AxiosError): string => {
  const errorCode = err.request.status;
  if (errorCode === 400) {
    // @ts-ignore
    return err.response.data.message;
  }

  return err.message;
};

export const convertToErrorMessage = (err: any): Message => {
  let message: Message = { type: MessageType.ERROR, text: '' };

  if (err instanceof AxiosError) {
    message = { ...message, text: convertAxiosErrorToMessage(err) };
  } else if (err instanceof ValidationError) {
    message = {
      ...message,
      text: languageService.getTextValueForCurrentLocale(FORM_FILL_VALIDATION_MESSAGE_KEY),
    };
  } else {
    message = { ...message, text: err.message };
  }

  return message;
};

const messageConverter = {
  convertToErrorMessage,
};

export default messageConverter;
