import '../Profile.scss';
import ProductImage from '../../../asset/icons/profile/product.jpg';
import ProductShare from '../../../asset/icons/profile/share.svg';
import Avatar from '../../../asset/icons/navigation/nav-account.svg';
import StarRating from '../../../component/rating/starRating/StarRating';

function Publication() {
  return (
    <div className="product-item">
      <div className="product-item__content">
        <button type="button">
          <img src={ProductShare} alt="Share" className="product-item__share" />
        </button>
        <a href="./profile" className="product-item__link">
          <img src={ProductImage} alt="Publication" className="product-item__picture" />
        </a>
        <a href="./profile" className="product-item__title">
          Cold borscht
        </a>
        <p className="product-item__descr">
          Totam rem aperiam, eaque ipsa quae ab illo invent veritatis et quasi architecto beatae
          vitae dicta sun.
        </p>
      </div>
      <div className="product-item__info info-item">
        <div className="info-item__user">
          <img src={Avatar} alt="Avatar" />
          <h4 className="info-item__name">Михаил Р</h4>
        </div>
        <div className="info-item__raiting">
          <StarRating stars={5} amount={25} />
        </div>
      </div>
    </div>
  );
}

export default Publication;
