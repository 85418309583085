import { IngredientUnit } from '../../common/constant/interface/interfaces';

export const getShortUnitName = (unitName: IngredientUnit['name']): string => {
  switch (unitName) {
    case 'GRAM':
      return 'gr';
    case 'MILLILITER':
      return 'ml';
    case 'TEASPOON':
      return 'tp';
    case 'POUND':
      return 'lb';
    case 'PIECE':
      return 'pc';
    default:
      return '-';
  }
};
