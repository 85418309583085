import { Link } from 'react-router-dom';
import Title from '../title/Title';
import { APP_PATH } from '../../common/constant/pathConstant';
import TwitterSvg from '../../asset/icons/twitter-green-icon.svg';
import InstagramSvg from '../../asset/icons/instagram-green-icon.svg';
import TelegramSvg from '../../asset/icons/telegram-green-icon.svg';
import './Footer.scss';

const Footer = () => (
  <footer className="footer">
    <section className="footer__main">
      <section className="footer__main__l-side">
        <Title />
        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget
          dolor. Aenean massa
        </p>
      </section>
      <section className="footer__main__m-side">
        <Link to={APP_PATH.DEFAULT}>FAQ</Link>
        <div className="footer__main__m-side_line" />
        <Link to={APP_PATH.TERMS_AND_CONDITIONS}>Terms and Conditions</Link>
        <div className="footer__main__m-side_line" />
        <Link to={APP_PATH.PRIVACY_POLICY}>Privacy Policy</Link>
      </section>
      <section className="footer__main__r-side">
        <a href="https://twitter.com">
          <img src={TwitterSvg} alt="Twitter icon" />
        </a>
        <a href="https://instagram.com">
          <img src={InstagramSvg} alt="Instagram icon" />
        </a>
        <a href="https://t.me">
          <img src={TelegramSvg} alt="Telegram icon" />
        </a>
      </section>
      <p className="footer__main__all-rights">MealMate © 2021 All rights reserved</p>
    </section>
  </footer>
);

export default Footer;
