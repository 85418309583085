import '../../container/profile/Profile.scss';
import './Aside.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as OpenAsideButton } from '../../asset/icons/aside/open-arrow.svg';
import { ReactComponent as Dashboard } from '../../asset/icons/aside/dashboard.svg';
import { ReactComponent as AppliedRations } from '../../asset/icons/aside/applied-rations.svg';
import { ReactComponent as Profile } from '../../asset/icons/aside/profile.svg';
import { ReactComponent as Settings } from '../../asset/icons/aside/settings.svg';
import { APP_PATH } from '../../common/constant/pathConstant';

const navListPages = [
  {
    name: 'dashboard',
    id: 'aside.dashboard',
    icon: <Dashboard />,
    url: APP_PATH.DASHBOARD,
  },
  {
    name: 'applied-rations',
    id: 'aside.appliedRations',
    icon: <AppliedRations />,
    url: `${APP_PATH.APP_APPLIED_RATIONS}?status=PRESENT`,
  },
  {
    name: 'profile',
    id: 'aside.profile',
    icon: <Profile />,
    url: APP_PATH.APP_PROFILE,
  },
  {
    name: 'settings',
    id: 'aside.settings',
    icon: <Settings />,
    url: APP_PATH.APP_SETTINGS,
  },
];

const Aside = () => {
  const [isAsideOpen, setIsAsideOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const asideRef = useRef<HTMLDivElement>(null);

  const getCurrentActiveButton = () => {
    const currentPath = location.pathname;
    const activePage = navListPages.find((page) => page.url.split('?')[0] === currentPath);

    return activePage ? activePage.name : '';
  };

  const getButtonClassNames = (name: string) => `aside__button ${name === getCurrentActiveButton() ? 'aside__button--active' : ''}`;

  const handleButtonClick = (url: string) => {
    navigate(url);
  };

  const handleAsideToggle = () => {
    setIsAsideOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (asideRef.current && !asideRef.current.contains(e.target as Node)) {
        setIsAsideOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <aside className={`aside ${isAsideOpen ? 'open' : ''}`} data-testid="aside" ref={asideRef}>
      <button
        className="aside__open-button button-open"
        type="button"
        data-testid="aside-open-button"
        onClick={() => handleAsideToggle()}
      >
        <OpenAsideButton />
      </button>
      <ul className="aside__list">
        {navListPages.map(({
          name,
          id,
          icon,
          url,
        }) => (
          <li className="aside__item" key={name}>
            <button
              data-testid={name}
              className={getButtonClassNames(name)}
              type="button"
              onClick={() => handleButtonClick(url)}
            >
              {icon}
              <p>
                <FormattedMessage id={id} />
              </p>
            </button>
          </li>
        ))}
      </ul>
      <button
        data-testid="setting-main"
        className={getButtonClassNames('settings')}
        type="button"
        id='setting-main'
        onClick={() => handleButtonClick(APP_PATH.PROFILE_SETTING)}
      >
        <Settings />
        <p>
          <FormattedMessage id='aside.settings' />
        </p>
      </button>
    </aside>
  );
};

export default Aside;
