import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessage } from './messageSlice';
import { convertToErrorMessage } from '../../service/message/messageConverter';
import calculatorMapper from '../../service/mapper/calculator/calculatorMapperService';
import {
  CalculationResult, Gender, HeightUnits,
  InitCalculationDataApiResponse, WeightUnits,
} from '../../common/constant/interface/interfaces';
import calculatorService from '../../service/calculate/calculatorService';

export interface CalculatorFormData {
  gender: Gender;
  age: number;
  height: number;
  heightUnit: HeightUnits;
  weight: number;
  weightUnit: WeightUnits;
  activityLevel: string;
  formula: string;
  goal: string;
  adjustingCalories: number;
  bodyFatPercentage: number | null;
  proteinRatio: number,
  carboRatio: number,
  fatRatio: number,
}

export interface CalculatorState {
  initialValues: InitCalculationDataApiResponse;
  selectedFormDataFields: CalculatorFormData,
  calculationResult: CalculationResult | null;
}

const DEFAULT_VALUE_PROTEIN_RATIO = 10;
const DEFAULT_VALUE_FATS_RATIO = 30;
const DEFAULT_VALUE_CARBS_RATIO = 60;
const DEFAULT_VALUE_ADJUSTING_CAL = -15;

export const initFormData: CalculatorFormData = {
  gender: 'MALE',
  age: 0,
  height: 0,
  heightUnit: 'CENTIMETER',
  weight: 0,
  weightUnit: 'KILOGRAM',
  activityLevel: 'NOT_ACTIVE',
  formula: 'HARRIS_BENEDICT',
  goal: 'LOSE_WEIGHT',
  adjustingCalories: DEFAULT_VALUE_ADJUSTING_CAL,
  proteinRatio: DEFAULT_VALUE_PROTEIN_RATIO,
  carboRatio: DEFAULT_VALUE_CARBS_RATIO,
  fatRatio: DEFAULT_VALUE_FATS_RATIO,
  bodyFatPercentage: null,
};

export const initialState: CalculatorState = {
  initialValues: {
    formulas: ['HARRIS_BENEDICT'],
    activityLevels: {
      NOT_ACTIVE: { proteinRatio: 0, carboRatio: 0, fatRatio: 0 },
      LOW_ACTIVE: { proteinRatio: 0, carboRatio: 0, fatRatio: 0 },
      MODERATE_ACTIVE: { proteinRatio: 0, carboRatio: 0, fatRatio: 0 },
      VERY_ACTIVE: { proteinRatio: 0, carboRatio: 0, fatRatio: 0 },
      EXTREMELY_ACTIVE: { proteinRatio: 0, carboRatio: 0, fatRatio: 0 },
    },
    goals: {
      WEIGHT_LOSS: { caloriesAdjustment: 0 },
      WEIGHT_GAIN: { caloriesAdjustment: 0 },
      MAINTAIN_WEIGHT: { caloriesAdjustment: 0 },
    },
    userAccount: {
      gender: 'MALE',
      age: 0,
      height: 0,
      heightUnit: 'CENTIMETER',
      weight: 0,
      weightUnit: 'KILOGRAM',
      activityLevel: 'NOT_ACTIVE',
    },
  },
  selectedFormDataFields: initFormData,
  calculationResult: null,
};

export const fetchInitData = createAsyncThunk<
  InitCalculationDataApiResponse,
  void,
  { rejectValue: string }
>('calculate/fetchInitData', async (_, thunkAPI) => {
  try {
    return await calculatorService.getInitData();
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

export const calulateSuggestedNutrition = createAsyncThunk<
  CalculationResult,
  CalculatorFormData,
  { rejectValue: string }
>('calculate/calculateSuggestedNutrition', async (formData, thunkAPI) => {
  try {
    return await calculatorService.getCalculatedData(formData);
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

const calculatorSlice = createSlice({
  name: 'calculate',
  initialState,
  reducers: {
    clearCalculationData: (state) => {
      state.calculationResult = null;
      state.selectedFormDataFields = initFormData;
    },
    setCalculatorInputFields: (state, action) => {
      state.selectedFormDataFields = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitData.fulfilled, (state, action) => {
        state.initialValues = action.payload;
        state
          .selectedFormDataFields = calculatorMapper.mapInitialFormDataToSelected(action.payload);
      })
      .addCase(calulateSuggestedNutrition.fulfilled, (state, action) => {
        state.calculationResult = action.payload;
      });
  },
});

export const { clearCalculationData, setCalculatorInputFields } = calculatorSlice.actions;
export const { reducer } = calculatorSlice;

export default reducer;
