import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../hook/useAppDispatch';
import { updateStateFromAuthSession } from '../../../redux/slice/authSlice';
import Registration, {
  RegistrationFormValues,
  STEP_FOR_USER_DATA_FILL,
} from '../../registration/Registration';
import { CurrentUser } from '../../../service/auth/authService';
import { APP_PATH } from '../../../common/constant/pathConstant';
import { RootState } from '../../../redux';

function OauthSuccessLogin() {
  const [registrationForm, setRegistrationForm] = useState<Partial<RegistrationFormValues>>({});
  const isLoggedIn: boolean = useSelector((state: RootState) => state.auth.login.isLoggedIn);
  const currentUser: CurrentUser | null = useSelector(
    (state: RootState) => state.auth.login.currentUser,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateStateFromAuthSession())
      .then(() => {
        setRegistrationForm({ username: currentUser?.username, email: currentUser?.email });
      });
  }, [dispatch]);

  if (currentUser == null || !isLoggedIn) return null;

  return (currentUser.isCompletedRegistration
    ? <Navigate to={APP_PATH.DASHBOARD} replace />
    : <Registration startRegistrationStep={STEP_FOR_USER_DATA_FILL} formValues={registrationForm} />
  );
}

export default OauthSuccessLogin;
