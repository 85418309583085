import React from 'react';
import './PageNotFound.scss';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="page-not-found">
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>The page you are looking for might have been removed or is temporarily unavailable.</p>
      <button onClick={goBack} className="apply animated-gradient">
        Go Back
      </button>
    </div>
  );
};

export default PageNotFound;
