import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessage } from './messageSlice';
import { convertToErrorMessage } from '../../service/message/messageConverter';
import {
  FoodType,
  FoodTypeEnum,
  Ingredient,
  Meal,
  MyRation,
  Nutrition,
  Ration,
} from '../../common/constant/interface/interfaces';
import myRationService from '../../service/myRation/myRationService';
import {
  FUTURE_APPLIED_RATION,
  PAST_APPLIED_RATION,
  PRESENT_APPLIED_RATION,
} from '../../container/appliedRationList/AppliedRationList';

export interface MyRationItemContent
{
  day: string;
  language: string;
  foodType: string;
  item: {
    mealId?: string;
    ingredientId?: string
    name: string
    language: string;
    isEaten?: boolean
    nutrition: Nutrition;
    unit: {
      id: string
      name: string;
      value: number;
    };
    type: string;
  }
}

export interface PostMyRationItem {
    language: string;
    content: MyRationItemContent[]
}

export type AppliedRationTimeStatus = 'PAST' | 'PRESENT' | 'FUTURE';

export type AppliedRations = {
  pastAppliedRation: AppliedRation[],
  presentAppliedRation: AppliedRation[],
  futureAppliedRation: AppliedRation[],
};

export interface AppliedRation {
  id: string;
  start: string;
  finish: string;
  status: AppliedRationTimeStatus;
  ration: Ration;
}

interface CancelAppliedRationParams {
  id: string;
  status: string;
}

export interface PutToggleFoodIsEaten {
  day: string,
  language: string,
  itemId: string,
  isEaten: boolean
}

export interface DeleteMyRationItemOnDay {
  day: string,
  language: string,
  itemId: string,
}

export interface MyRationResponse {
  day: string;
  myRation: MyRation;
}

interface ItemApiRequest {
  cursorId: string;
  size: number;
}

interface SearchItemApiRequest {
  cursorId: string;
  size: number;
  searchParam: string;
}

export interface IngredientsApiResponse {
  content: Ingredient[];
  nextCursorId: string;
}

export interface MealsApiResponse {
  content: Meal[];
  nextCursorId: string;
}

export interface OccupationDaysApiResponse {
  content: string[];
}

export interface AppliedRationsResponse {
  content: AppliedRation[];
}

export interface AppliedProcessedRationsResponse {
  response: AppliedRation[];
  status: string,
}

export interface MyRationState {
  myRations: MyRationResponse[];
  ingredient: {
    searchParam: string,
    searchResult: IngredientsApiResponse,
    allIngredient: IngredientsApiResponse,
  },
  meal: {
    searchParam: string,
    searchResult: MealsApiResponse,
    allMeal: MealsApiResponse,
  },
  occupiedDays: { month: number, content: string[] }[],
  appliedRations: {
    pastAppliedRation: AppliedRation[];
    presentAppliedRation: AppliedRation[];
    futureAppliedRation: AppliedRation[];
  };
}

export const initialNutrition: Nutrition = {
  calorie: 0,
  carbo: 0,
  fat: 0,
  protein: 0,
};

const initialFoodTypes: FoodType[] = [
  {
    name: FoodTypeEnum.Breakfast,
    items: [],
    nutrition: initialNutrition,
    nutritionEaten: initialNutrition,
  },
  {
    name: FoodTypeEnum.Lunch,
    items: [],
    nutrition: initialNutrition,
    nutritionEaten: initialNutrition,
  },
  {
    name: FoodTypeEnum.Dinner,
    items: [],
    nutrition: initialNutrition,
    nutritionEaten: initialNutrition,
  },
  {
    name: FoodTypeEnum.Snacks,
    items: [],
    nutrition: initialNutrition,
    nutritionEaten: initialNutrition,
  },
];

export const initialRation: MyRation = {
  id: '',
  foodTypes: initialFoodTypes,
  nutrition: initialNutrition,
  nutritionEaten: initialNutrition,
};

export const initialIngredientContent: IngredientsApiResponse = {
  content: [],
  nextCursorId: '',
};

export const initialMealContent: MealsApiResponse = {
  content: [],
  nextCursorId: '',
};

export const initialState: MyRationState = {
  myRations: [],
  ingredient: {
    searchParam: '',
    searchResult: initialIngredientContent,
    allIngredient: initialIngredientContent,
  },
  meal: {
    searchParam: '',
    searchResult: initialMealContent,
    allMeal: initialMealContent,
  },
  occupiedDays: [{
    month: 0,
    content: [],
  }],
  appliedRations: {
    pastAppliedRation: [],
    presentAppliedRation: [],
    futureAppliedRation: [],
  },
};

export const getMyRationForDay = createAsyncThunk<MyRationResponse,
  string,
  { rejectValue: string }>('myRation/getMyRations', async (day, thunkAPI) => {
    try {
      const response = await myRationService.getMyRations(day);
      return {
        day,
        myRation: response,
      };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  });

export const postMyRationItemsOnDay = createAsyncThunk<{ day: string, myRation: MyRation },
  { day: string, items: PostMyRationItem },
  { rejectValue: string }>('myRation/postMyRationItemsOnDay', async ({ day, items }, thunkAPI) => {
    try {
      const response = await myRationService.postMyRationItemsOnDay(day, items);
      return {
        day,
        myRation: response,
      };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  });

export const putMyRationFoodIsEaten = createAsyncThunk<{ day: string, response: MyRation },
  PutToggleFoodIsEaten,
  { rejectValue: string }>('myRation/putMyRationFoodIsEaten', async (toggleFood, thunkAPI) => {
    try {
      const response = await myRationService.putMyRationFoodIsEaten(toggleFood);
      return {
        day: toggleFood.day,
        response,
      };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  });

export const deleteMyRationItem = createAsyncThunk<{ day: string, myRation: MyRation },
  DeleteMyRationItemOnDay,
  { rejectValue: string }>('myRation/deleteMyRationItem', async (deleteItem, thunkAPI) => {
    try {
      const response = await myRationService.deleteMyRationItem(deleteItem);
      return {
        day: deleteItem.day,
        myRation: response,
      };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  });

export const clearMyRationForDay = createAsyncThunk<
    { day: string, myRation: MyRation },
    string,
    { rejectValue: string }
>('myRation/clearMyRationForDay', async (day, thunkAPI) => {
  try {
    const response = await myRationService.clearMyRationForDay(day);
    return {
      day,
      myRation: response,
    };
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

export const getIngredients = createAsyncThunk<IngredientsApiResponse,
  ItemApiRequest,
  { rejectValue: string }>(
    'myRation/getIngredients',
    async ({
      cursorId,
      size,
    }, thunkAPI) => {
      try {
        return await myRationService.getIngredients(
          cursorId,
          size,
        );
      } catch (error: any) {
        const { message } = error;
        thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
        return thunkAPI.rejectWithValue(message);
      }
    },
  );

export const searchIngredient = createAsyncThunk<IngredientsApiResponse,
  SearchItemApiRequest,
  { rejectValue: string }>(
    'myRation/searchIngredient',
    async ({
      searchParam,
      cursorId,
      size,
    }, thunkAPI) => {
      try {
        return await myRationService.searchIngredient(
          searchParam,
          cursorId,
          size,
        );
      } catch (error: any) {
        const { message } = error;
        thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
        return thunkAPI.rejectWithValue(message);
      }
    },
  );

export const getMeals = createAsyncThunk<MealsApiResponse,
  ItemApiRequest,
  { rejectValue: string }>('myRation/getMeals', async ({
    cursorId,
    size,
  }, thunkAPI) => {
    try {
      return await myRationService.getMeals(cursorId, size);
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  });

export const searchMeal = createAsyncThunk<MealsApiResponse,
  SearchItemApiRequest,
  { rejectValue: string; }>(
    'myRation/searchMeal',
    async ({
      searchParam,
      cursorId,
      size,
    }, thunkAPI) => {
      try {
        return await myRationService.searchMeal(searchParam, cursorId, size);
      } catch (error: any) {
        const { message } = error;
        thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
        return thunkAPI.rejectWithValue(message);
      }
    },
  );

export const getOccupiedDaysInMyRation = createAsyncThunk<{
  content: OccupationDaysApiResponse,
  month: number },
  { month: number, year: number },
  { rejectValue: string }>('myRation/getOccupiedDaysInMyRation', async ({
    month,
    year,
  }, thunkAPI) => {
    try {
      const response = await myRationService.getOccupiedDaysInMyRation(month, year);
      return {
        content: response,
        month,
      };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  });

export const getAppliedRationsByStatus = createAsyncThunk<AppliedProcessedRationsResponse,
  string>('rations/getAppliedRations', async (status, thunkAPI) => {
    try {
      const res = await myRationService.getAppliedRationsByStatuses([status]);

      return {
        status,
        response: res.content,
      };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  });

export const cancelAppliedRation = createAsyncThunk(
  'myRation/cancelAppliedRation',
  async ({
    id,
    status,
  }: CancelAppliedRationParams, thunkAPI) => {
    try {
      await myRationService.cancelAppliedRation(id);
      return {
        status,
        id,
      };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

const getUpdatedFoodTypes = (
  updatedFoodTypes: FoodType[],
) => initialRation.foodTypes
  .map((initialFoodType) => {
    const updatedFoodType = updatedFoodTypes?.find((ft) => ft.name === initialFoodType.name);
    return updatedFoodType ? {
      ...initialFoodType,
      ...updatedFoodType,
      items: updatedFoodType.items || [],
      nutrition: updatedFoodType.nutrition || initialNutrition,
      nutritionEaten: updatedFoodType.nutritionEaten || initialNutrition,
    } : initialFoodType;
  });

const updateRation = (state: MyRationState, day: string, myRation: any) => {
  const existingRation = state.myRations.find((ration) => ration.day === day);
  const allFoodTypes = getUpdatedFoodTypes(myRation.foodTypes);

  if (existingRation) {
    existingRation.myRation = {
      ...existingRation.myRation,
      ...myRation,
      foodTypes: allFoodTypes,
      nutrition: myRation.nutrition || initialNutrition,
      nutritionEaten: myRation.nutritionEaten || initialNutrition,
    };
  } else {
    state.myRations.push({
      day,
      myRation: {
        ...initialRation,
        ...myRation,
        foodTypes: allFoodTypes,
        nutrition: myRation.nutrition || initialNutrition,
        nutritionEaten: myRation.nutritionEaten || initialNutrition,
      },
    });
  }
};

const myRationSlice = createSlice({
  name: 'myRation',
  initialState,
  reducers: {
    resetState: (state) => {
      state.myRations = [];
      state.ingredient = {
        searchParam: '',
        searchResult: initialIngredientContent,
        allIngredient: initialIngredientContent,
      };
      state.meal = {
        searchParam: '',
        searchResult: initialMealContent,
        allMeal: initialMealContent,
      };
      state.occupiedDays = [{
        month: 0,
        content: [],
      }];
    },
    resetOccupiedDays: (state) => {
      state.occupiedDays = [];
    },
    clearAppliedRations(state) {
      state.appliedRations = {
        pastAppliedRation: [],
        presentAppliedRation: [],
        futureAppliedRation: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyRationForDay.fulfilled, (state, action) => {
        const { day, myRation } = action.payload;
        updateRation(state, day, myRation);
      })
      .addCase(postMyRationItemsOnDay.fulfilled, (state, action) => {
        const { day, myRation } = action.payload;
        updateRation(state, day, myRation);
      })
      .addCase(deleteMyRationItem.fulfilled, (state, action) => {
        const { day, myRation } = action.payload;
        updateRation(state, day, myRation);
      })
      .addCase(putMyRationFoodIsEaten.fulfilled, (state, action) => {
        const {
          day,
          response,
        } = action.payload;
        const myRation = state.myRations.find((ration) => ration.day === day);
        if (myRation) {
          myRation.myRation.nutritionEaten.calorie = response.nutritionEaten.calorie;
          myRation.myRation.nutritionEaten.protein = response.nutritionEaten.protein;
          myRation.myRation.nutritionEaten.fat = response.nutritionEaten.fat;
          myRation.myRation.nutritionEaten.carbo = response.nutritionEaten.carbo;
        }
      })
      .addCase(getIngredients.fulfilled, (state, action) => {
        state.ingredient.allIngredient.content = [
          ...state.ingredient.allIngredient.content,
          ...action.payload.content];
        state.ingredient.allIngredient.nextCursorId = action.payload.nextCursorId;
      })
      .addCase(searchIngredient.fulfilled, (state, action) => {
        if (action.payload.nextCursorId) {
          state.ingredient.searchResult.content = [
            ...state.ingredient.searchResult.content,
            ...action.payload.content];
          state.ingredient.searchResult.nextCursorId = action.payload.nextCursorId;
        } else {
          state.ingredient.searchResult.content = action.payload.content;
          state.ingredient.searchResult.nextCursorId = action.payload.nextCursorId ? action.payload.nextCursorId : '';
        }
      })
      .addCase(getMeals.fulfilled, (state, action) => {
        state.meal.allMeal.content = [...state.meal.allMeal.content, ...action.payload.content];
        state.meal.allMeal.nextCursorId = action.payload.nextCursorId;
      })
      .addCase(clearMyRationForDay.fulfilled, (state, action) => {
        const { day, myRation } = action.payload;
        const existingRation = state.myRations.find((ration) => ration.day === day);

        if (existingRation) {
          existingRation.myRation = {
            ...initialRation,
            id: myRation.id,
          };
        }
      })
      .addCase(searchMeal.fulfilled, (state, action) => {
        if (action.payload.nextCursorId) {
          state.meal.searchResult.content = [
            ...state.meal.searchResult.content,
            ...action.payload.content];
          state.meal.searchResult.nextCursorId = action.payload.nextCursorId;
        } else {
          state.meal.searchResult.content = action.payload.content;
          state.meal.searchResult.nextCursorId = '';
        }
      })
      .addCase(getOccupiedDaysInMyRation.fulfilled, (state, action) => {
        const { content, month } = action.payload;

        state.occupiedDays.push({
          month,
          content: content.content,
        });
      })
      .addCase(getAppliedRationsByStatus.fulfilled, (state, action) => {
        const { response, status } = action.payload;

        if (status === FUTURE_APPLIED_RATION) {
          state.appliedRations.futureAppliedRation = response;
        } else if (status === PRESENT_APPLIED_RATION) {
          state.appliedRations.presentAppliedRation = response;
        } else if (status === PAST_APPLIED_RATION) {
          state.appliedRations.pastAppliedRation = response;
        }
      })
      .addCase(cancelAppliedRation.fulfilled, (state, action) => {
        const { status, id } = action.payload;
        const { futureAppliedRation } = state.appliedRations;
        const { presentAppliedRation } = state.appliedRations;
        const { pastAppliedRation } = state.appliedRations;

        if (status === FUTURE_APPLIED_RATION) {
          state.appliedRations.futureAppliedRation = futureAppliedRation
            .filter((item) => item.id !== id);
        } else if (status === PRESENT_APPLIED_RATION) {
          state.appliedRations.presentAppliedRation = presentAppliedRation
            .filter((item) => item.id !== id);
        } else if (status === PAST_APPLIED_RATION) {
          state.appliedRations.pastAppliedRation = pastAppliedRation
            .filter((item) => item.id !== id);
        }
      });
  },
});

export const {
  resetState,
  resetOccupiedDays,
  clearAppliedRations,
} = myRationSlice.actions;
export default myRationSlice.reducer;
