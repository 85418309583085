import { useState } from 'react';
import './RationReviewForm.scss';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import SelectableStarRating from '../../../../../component/rating/selectableStarRating/SelectableStarRating';
import languageService from '../../../../../service/language/languageService';
import { MAX_CHARACTERS_TEXTAREA } from '../../../../../common/constant/charactersMaxLength';

interface RationReviewFormProps {
  onSubmit: (inputValue: string, selectedRating: number, language: string) => void;
}

const RationReviewForm = ({ onSubmit }: RationReviewFormProps) => {
  const language = languageService.getCurrentLocale();

  const [inputValue, setInputValue] = useState('');
  const [selectedRating, setSelectedRating] = useState<number>(5);
  const intl = useIntl();

  const getPlaceholders = (intl: IntlShape) => ({
    create: intl.formatMessage({ id: 'ration.reviews.textarea.create' }),
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(inputValue, selectedRating, language);
    setInputValue('');
  };

  const placeholders = getPlaceholders(intl);

  return (
    <form className="review-form" onSubmit={(e) => handleSubmit(e)}>
      <div className="review-form__top">
        <h2>
          <FormattedMessage id="ration.reviews.form.title" />
        </h2>

        <div className="review-form__top-stars" data-testid="star-rating">
          <p className="review-form__top-stars-text">
            <FormattedMessage id="ration.reviews.form.rating" />
          </p>
          <SelectableStarRating stars={5} onRatingChange={setSelectedRating} />
        </div>
      </div>

      <textarea
        className="review-form__main"
        name="review form"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder={placeholders.create}
        maxLength={MAX_CHARACTERS_TEXTAREA}
      />

      <button type="submit" className="review-form__submit animated-gradient-btn">
        <FormattedMessage id="ration.reviews.form.button" />
      </button>
    </form>
  );
};

export default RationReviewForm;
