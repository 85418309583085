import '../../../style/scss/style.scss';
import '../Registration.scss';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import backBtnImage from '../../../asset/icons/back-arrow.svg';
import { RegistrationFormValues, RegistrationStepProps } from '../Registration';
import FormField from '../../../component/form/formField/formikField/FormField';
import { useAppDispatch } from '../../../hook/useAppDispatch';
import { setMessage } from '../../../redux/slice/messageSlice';
import { APP_PATH } from '../../../common/constant/pathConstant';
import { convertToErrorMessage } from '../../../service/message/messageConverter';
import { register } from '../../../redux/slice/authSlice';
import validationService from '../../../service/validation/validationService';

const FIELD_NAMES_TO_BACKEND_VALIDATE: Array<keyof RegistrationFormValues> = ['username', 'email'];

const trimAllStringValues = (formikProps:any) => {
  Object.keys(formikProps.values).forEach((key) => {
    if (typeof formikProps.values[key] === 'string') {
      formikProps.setFieldValue(key, formikProps.values[key].trim());
    }
  });
};

function RegistrationStep1({
  formikProps,
  currentStep,
  onNextStep,
}: Readonly<RegistrationStepProps>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const handleValidateAndSubmit = async () => {
    try {
      setLoading(true);
      await validationService.validateRegistrationForm(
        formikProps,
        currentStep,
        FIELD_NAMES_TO_BACKEND_VALIDATE,
      );
      trimAllStringValues(formikProps);
      dispatch(register(formikProps.values))
        .unwrap()
        .then(() => {
          onNextStep();
          setLoading(false);
        })
        .catch((error: any) => {
          dispatch(setMessage(convertToErrorMessage(error)));
          setLoading(false);
        });
    } catch (error: any) {
      dispatch(setMessage(convertToErrorMessage(error)));
      setLoading(false);
    }
  };

  return (
    <div className="window registration-window registration-window--confirm">
      <button
        data-testid="button-back"
        type="button"
        onClick={() => navigate(APP_PATH.DEFAULT)}
        className="window__back-btn back-btn"
      >
        <img alt="back" className="back-btn__picture" src={backBtnImage} />
      </button>
      <h2 className="window__title registration-window__title">
        <FormattedMessage id="form.label.registration" />
      </h2>
      <p className="window__subtitle">
        <FormattedMessage id="form.label.subtitle" />
      </p>
      <div className="window__form form-window form-window--registration">
        <FormField
          placeholder="form.label.username"
          name="username"
          type="text"
        />
        <FormField
          placeholder="form.label.email"
          name="email"
          type="text"
        />
        <FormField
          placeholder="form.label.password"
          name="password"
          type="password"
        />
        <FormField
          placeholder="form.label.confirmPassword"
          name="confirmPassword"
          type="password"
        />
        <button
          data-testid="button-registration"
          type="submit"
          className="form-window__btn window-btn"
          disabled={loading}
          onClick={handleValidateAndSubmit}
        >
          {loading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            <FormattedMessage id="form.label.registration" />
          )}
        </button>
      </div>
      <div className="window__enabled-acc enabled-acc">
        <p className="enabled-acc__descr">
          <FormattedMessage id="form.label.registration.desc" />
        </p>
        <strong>
          <a href={APP_PATH.LOGIN} className="enabled-acc__link">
            <FormattedMessage id="login.button" />
          </a>
        </strong>
      </div>
    </div>
  );
}

export default RegistrationStep1;
