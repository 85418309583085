import React, { useState, useEffect } from 'react';
import '../../Dashboard.scss';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as MealNotEaten } from '../../../../asset/icons/dashboard/prohibition.svg';
import { ReactComponent as MealEaten } from '../../../../asset/icons/dashboard/apple-bite.svg';
import { ReactComponent as MealDelete } from '../../../../asset/icons/dashboard/meal-delete.svg';
import { IngredientUnit, ItemFoodType } from '../../../../common/constant/interface/interfaces';
import {
  deleteMyRationItem,
  DeleteMyRationItemOnDay,
  putMyRationFoodIsEaten,
  PutToggleFoodIsEaten,
} from '../../../../redux/slice/myRationSlice';
import languageService from '../../../../service/language/languageService';
import { useAppDispatch } from '../../../../hook/useAppDispatch';
import { getShortUnitName } from '../../../../service/myRation/getShortUnitName';
import { APP_PATH, BACKEND_INGREDIENTS_PARAMS } from '../../../../common/constant/pathConstant';

interface MyRationModalItemProps {
  meal: ItemFoodType;
  selectedDate: string;
}

const buttonsIsEaten = [
  { key: 'isNotEaten', img: <MealNotEaten />, className: 'ration-meal-options__prohibition' },
  { key: 'isEaten', img: <MealEaten />, className: 'ration-meal-options__eaten' },
];

const MyRationModalItem = ({ meal, selectedDate }: MyRationModalItemProps) => {
  const dispatch = useAppDispatch();
  const [selectedOption, setSelectedOption] = useState<{ [key: string]: boolean }>({
    isEaten: false,
    isNotEaten: false,
  });
  const [isShowing, setIsShowing] = useState(false);
  const location = useLocation();

  const toggleIsEatenFood = (key: string) => {
    const isEaten = key === 'isEaten';
    setSelectedOption((prev) => ({
      ...prev,
      ...Object.fromEntries(buttonsIsEaten.map((option) => [option.key, option.key === key])),
    }));
    const collectedToggleFood: PutToggleFoodIsEaten = {
      day: selectedDate,
      language: languageService.getCurrentLocale(),
      itemId: meal.id,
      isEaten,
    };
    dispatch(putMyRationFoodIsEaten(collectedToggleFood));
  };

  const deletedItemFood = () => {
    const collectedDeleteItemFood: DeleteMyRationItemOnDay = {
      day: selectedDate,
      language: languageService.getCurrentLocale(),
      itemId: meal.id,
    };
    dispatch(deleteMyRationItem(collectedDeleteItemFood));
  };

  useEffect(() => {
    setSelectedOption({ ...selectedOption, isEaten: meal.isEaten, isNotEaten: !meal.isEaten });
    setIsShowing(true);
  }, [meal.isEaten]);
  return (
    <div className={`ration-meal-item ${isShowing ? 'show' : ''}`}>
      <div className="ration-meal-item__descr">
        <div className="ration-meal-item__pic">
          <img src={meal.images[0].url} alt="Fried eggs" />
        </div>
        <div className='ration-meal-item__info ration-meal-info'>
          <Link
            to={
              meal.mealId
                ? `${APP_PATH.APP_MEALS}/${meal.mealId}`
                : `${APP_PATH.APP_INGREDIENTS}?${BACKEND_INGREDIENTS_PARAMS.INGREDIENT_ID}=${meal.ingredientId}`
            }
            className={`ration-meal-info__name ${location.pathname !== APP_PATH.DASHBOARD && 'disabled-link'}`}
            title={meal.name}
            target='blank'
          >
            {meal.name}
          </Link>
          <div className="ration-meal-info__kcal">
            {meal.nutrition.calorie}
            {' '}
            <FormattedMessage id="dashboard.title.kcal" />
          </div>
        </div>
      </div>
      <div className="ration-meal-item__options ration-meal-options">
        {buttonsIsEaten.map(({ key, img, className }) => (
          <button
            key={key}
            value={key}
            className={`${className} ${selectedOption[key] ? 'selected' : ''}`}
            onClick={() => toggleIsEatenFood(key)}
            data-testid={key}
          >
            {img}
          </button>
        ))}
        <div id="ration-meal-weight" className="ration-meal-options__weight">
          {meal.unit ? meal.unit.value : '-'}
          {' '}
          {getShortUnitName(meal.unit.name as IngredientUnit['name'])}
        </div>
      </div>
      <button
        className="ration-meal-item__delete"
        data-testid="deleteItemButton"
        onClick={deletedItemFood}
      >
        <MealDelete />
      </button>
    </div>
  );
};

export default MyRationModalItem;
