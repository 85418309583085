import { CalculatorFormData, initFormData } from '../../../redux/slice/calculatorSlice';
import { InitCalculationDataApiResponse } from '../../../common/constant/interface/interfaces';

const mapInitialFormDataToSelected = (
  initData: InitCalculationDataApiResponse,
): CalculatorFormData => ({
  ...initFormData,
  gender: initData.userAccount.gender,
  age: initData.userAccount.age,
  height: initData.userAccount.height,
  heightUnit: initData.userAccount.heightUnit,
  weight: initData.userAccount.weight,
  weightUnit: initData.userAccount.weightUnit,
  activityLevel: initData.userAccount.activityLevel,
  formula: initData.formulas[0],
});

const calculatorMapper = {
  mapInitialFormDataToSelected,
};

export default calculatorMapper;
