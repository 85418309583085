import apiService from '../api/apiService';
import { getApiBaseUrl } from '../../config/config';
import {
  BACKEND_API_PATH,
  BACKEND_COMMON_PARAMS,
  BACKEND_INGREDIENTS_PARAMS,
  BACKEND_MEALS_PARAMS,
  BACKEND_MY_RATIONS_PARAMS,
  BACKEND_MY_RATIONS_PATH_PARAMS,
} from '../../common/constant/pathConstant';
import { MealsApiResponse, MyRation } from '../../common/constant/interface/interfaces';
import languageService from '../language/languageService';
import {
  AppliedRationsResponse,
  DeleteMyRationItemOnDay, IngredientsApiResponse, OccupationDaysApiResponse,
  PostMyRationItem,
  PutToggleFoodIsEaten,
} from '../../redux/slice/myRationSlice';

async function getMyRations(day: string): Promise<MyRation> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.MY_RATIONS}/${
      BACKEND_MY_RATIONS_PATH_PARAMS.DAYS}/${day}?language=${languageService.getCurrentLocale()}`,
  );
}

async function getAppliedRationsByStatuses(statuses: string[]): Promise<AppliedRationsResponse> {
  const statusesQuery = statuses.join('&status=');
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.MY_RATIONS}?status=${statusesQuery}&language=${languageService.getCurrentLocale()}`,
  );
}

async function cancelAppliedRation(id: string) {
  return apiService.deleted(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.MY_RATIONS}/${id}`,
  );
}

async function postMyRationItemsOnDay(day: string, items: PostMyRationItem)
    : Promise<MyRation> {
  return apiService.post(`${getApiBaseUrl()}/${BACKEND_API_PATH.MY_RATIONS}/${
    BACKEND_MY_RATIONS_PATH_PARAMS.DAYS}/${day}/${BACKEND_MY_RATIONS_PATH_PARAMS.ITEMS}`, items);
}

async function putMyRationFoodIsEaten(toggleFood: PutToggleFoodIsEaten): Promise<MyRation> {
  return apiService.put(`${getApiBaseUrl()}/${BACKEND_API_PATH.MY_RATIONS}/${
    BACKEND_MY_RATIONS_PATH_PARAMS.DAYS}/${toggleFood.day}/${BACKEND_MY_RATIONS_PATH_PARAMS.ITEMS}/${toggleFood.itemId}`, toggleFood);
}

async function deleteMyRationItem(deleteItem: DeleteMyRationItemOnDay): Promise<MyRation> {
  return apiService.deleted(`${getApiBaseUrl()}/${BACKEND_API_PATH.MY_RATIONS}/${
    BACKEND_MY_RATIONS_PATH_PARAMS.DAYS}/${deleteItem.day}/${BACKEND_MY_RATIONS_PATH_PARAMS.ITEMS}/${deleteItem.itemId}?${
    BACKEND_COMMON_PARAMS.LANGUAGE}=${
    languageService.getCurrentLocale()}`);
}

async function clearMyRationForDay(day: string): Promise<MyRation> {
  return apiService.deleted(`${getApiBaseUrl()}/${BACKEND_API_PATH.MY_RATIONS}/${BACKEND_MY_RATIONS_PATH_PARAMS.DAYS}/${day}`);
}

async function getMeals(cursorId: string, size: number): Promise<MealsApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.MEALS}/${BACKEND_API_PATH.SEARCH}?${
      BACKEND_MEALS_PARAMS.CURSOR_ID}=${cursorId}&${
      BACKEND_MEALS_PARAMS.SIZE}=${size}&${
      BACKEND_COMMON_PARAMS.LANGUAGE}=${languageService.getCurrentLocale()}`,
  );
}

async function searchMeal(
  searchParam: string,
  cursorId: string,
  size: number,
): Promise<MealsApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.MEALS}/${BACKEND_API_PATH.SEARCH}?${
      BACKEND_MEALS_PARAMS.CURSOR_ID}=${cursorId}&${
      BACKEND_COMMON_PARAMS.LANGUAGE}=${languageService.getCurrentLocale()}&${
      BACKEND_MEALS_PARAMS.SIZE}=${size}&${searchParam}`,
  );
}

async function getIngredients(
  cursorId: string,
  size: number,
): Promise<IngredientsApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.INGREDIENTS}/${BACKEND_API_PATH.SEARCH}?${
      BACKEND_INGREDIENTS_PARAMS.CURSOR_ID}=${cursorId}&${
      BACKEND_INGREDIENTS_PARAMS.SIZE}=${size}&${
      BACKEND_COMMON_PARAMS.LANGUAGE}=${languageService.getCurrentLocale()}`,
  );
}

async function searchIngredient(
  searchParam: string,
  cursorId: string,
  size: number,
): Promise<IngredientsApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.INGREDIENTS}/${BACKEND_API_PATH.SEARCH}?${
      BACKEND_INGREDIENTS_PARAMS.CURSOR_ID}=${cursorId}&${
      BACKEND_INGREDIENTS_PARAMS.SIZE}=${size}&${
      BACKEND_COMMON_PARAMS.LANGUAGE}=${languageService.getCurrentLocale()}&${searchParam}`,
  );
}

async function getOccupiedDaysInMyRation(
  month: number,
  year: number,
): Promise<OccupationDaysApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.MY_RATIONS}/${
      BACKEND_MY_RATIONS_PARAMS.DAYS}/${BACKEND_MY_RATIONS_PARAMS.OCCUPIED}?${
      BACKEND_MY_RATIONS_PARAMS.YEAR}=${year}&${BACKEND_MY_RATIONS_PARAMS.MONTH}=${month}`,
  );
}

const myRationService = {
  getMyRations,
  postMyRationItemsOnDay,
  putMyRationFoodIsEaten,
  deleteMyRationItem,
  getMeals,
  searchMeal,
  getIngredients,
  searchIngredient,
  getOccupiedDaysInMyRation,
  clearMyRationForDay,
  getAppliedRationsByStatuses,
  cancelAppliedRation,
};

export default myRationService;
