export const AwsConfigAuth = {
  region: process.env.REACT_APP_AUTH_REGION,
  userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  cookieStorage: {
    domain: process.env.REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN,
    path: '/',
    expires: 365,
    sameSite: 'strict',
    secure: false,
  },
  authenticationFlowType: 'USER_SRP_AUTH',
  oauth: {
    domain: process.env.REACT_APP_AUTH_OAUTH_DOMAIN,
    redirectSignIn: process.env.REACT_APP_AUTH_OAUTH_REDIRECT_SIGN_IN_URL,
    redirectSignOut: process.env.REACT_APP_AUTH_OAUTH_REDIRECT_SIGN_OUT_URL,
    responseType: 'code',
  },
};
