import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessage } from './messageSlice';
import { convertToErrorMessage } from '../../service/message/messageConverter';
import ingredientService from '../../service/ingredient/ingredientService';
import { Ingredient, IngredientGroup } from '../../common/constant/interface/interfaces';

export interface GetIngredientGroupApiRequest {
  cursorId: string;
  size: number;
  level: number;
}

export interface GetIngredientsApiRequest {
  cursorId: string;
  size: number;
  groupId: string;
}

export interface IngredientGroupApiResponse {
  content: IngredientGroup[];
  nextCursorId: string;
}

export interface IngredientsApiResponse {
  content: Ingredient[];
  nextCursorId: string;
  groupId: string;
}

export interface SearchIngredientsApiResponse {
  content: Ingredient[];
  nextCursorId: string;
}

export interface IngredientState {
  ingredientGroups: IngredientGroup[];
  nextGroupCursorId: string;
  nextIngredientCursorId: string;
  search: Search;
}

export interface Search {
  searchResults: Ingredient[];
  searchNextCursorId: string;
}

const initialState: IngredientState = {
  ingredientGroups: [],
  nextGroupCursorId: '',
  nextIngredientCursorId: '',
  search: {
    searchResults: [],
    searchNextCursorId: '',
  },
};

export const getIngredientGroups = createAsyncThunk<
  IngredientGroupApiResponse,
  GetIngredientGroupApiRequest,
  { rejectValue: string }
>(
  'ingredient/ingredientGroups',
  async ({ cursorId, size, level }, thunkAPI) => {
    try {
      return await ingredientService.getIngredientGroups(cursorId, size, level);
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getIngredients = createAsyncThunk<
  IngredientsApiResponse,
  GetIngredientsApiRequest,
  { rejectValue: string }
>(
  'ingredient/ingredients',
  async ({ cursorId, size, groupId }, thunkAPI) => {
    try {
      const res = await ingredientService.getIngredients(cursorId, size, groupId);
      return { ...res, groupId };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const searchIngredients = createAsyncThunk<
  SearchIngredientsApiResponse,
  { query: string; cursorId?: string; size: number },
  { rejectValue: string }
>(
  'ingredient/searchIngredients',
  async ({ query, cursorId = '', size }, thunkAPI) => {
    try {
      return await ingredientService.searchIngredients(query, cursorId, size);
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getIngredientById = createAsyncThunk<
  Ingredient,
  string,
  { rejectValue: string }
>(
  'ingredient/getIngredientById',
  async (id, thunkAPI) => {
    try {
      return await ingredientService.getIngredientById(id);
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

const ingredientSlice = createSlice({
  name: 'ingredient',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIngredientGroups.fulfilled, (state, action) => {
        state.ingredientGroups = [
          ...state.ingredientGroups,
          ...action.payload.content,
        ];
        state.nextGroupCursorId = action.payload.nextCursorId;
      })
      .addCase(getIngredients.fulfilled, (state, action) => {
        const groupIndex = state.ingredientGroups.findIndex(
          (el) => el.id === action.payload.groupId,
        );
        state.ingredientGroups[groupIndex] = {
          ...state.ingredientGroups[groupIndex],
          ingredients: action.payload.content,
        };
        state.nextIngredientCursorId = action.payload.nextCursorId;
      })
      .addCase(searchIngredients.fulfilled, (state, action) => {
        const { content, nextCursorId } = action.payload;
        if (action.meta.arg.cursorId) {
          state.search.searchResults = [...state.search.searchResults, ...content];
        } else {
          state.search.searchResults = content;
        }
        state.search.searchNextCursorId = nextCursorId;
      });
  },
});

const { reducer } = ingredientSlice;
export default reducer;
