import './RateSmileSlider.scss';
import Slider from 'rc-slider';
import { FormattedMessage } from 'react-intl';
import 'rc-slider/assets/index.css';
import { ReactComponent as BadIcon } from '../../../asset/icons/feedback/smiles/bad.svg';
import { ReactComponent as NotGoodIcon } from '../../../asset/icons/feedback/smiles/not-good.svg';
import { ReactComponent as FineIcon } from '../../../asset/icons/feedback/smiles/fine.svg';
import { ReactComponent as GoodIcon } from '../../../asset/icons/feedback/smiles/good.svg';
import { ReactComponent as VeryGoodIcon } from '../../../asset/icons/feedback/smiles/very-good.svg';
import { ReactComponent as DisableBadIcon } from '../../../asset/icons/feedback/disabled-smiles/bad.svg';
import { ReactComponent as DisabledNotGoodIcon } from '../../../asset/icons/feedback/disabled-smiles/not-good.svg';
import { ReactComponent as DisableFineIcon } from '../../../asset/icons/feedback/disabled-smiles/fine.svg';
import { ReactComponent as DisableGoodIcon } from '../../../asset/icons/feedback/disabled-smiles/good.svg';
import { ReactComponent as DisabledVeryGoodIcon } from '../../../asset/icons/feedback/disabled-smiles/very-good.svg';

const smilesData = [
  {
    id: '1',
    emoji: <BadIcon />,
    disabledEmoji: <DisableBadIcon />,
    textKey: 'feedback.form.bad',
  },
  {
    id: '2',
    emoji: <NotGoodIcon />,
    disabledEmoji: <DisabledNotGoodIcon />,
    textKey: 'feedback.form.notGood',
  },
  {
    id: '3',
    emoji: <FineIcon />,
    disabledEmoji: <DisableFineIcon />,
    textKey: 'feedback.form.fine',
  },
  {
    id: '4',
    emoji: <GoodIcon />,
    disabledEmoji: <DisableGoodIcon />,
    textKey: 'feedback.form.good',
  },
  {
    id: '5',
    emoji: <VeryGoodIcon />,
    disabledEmoji: <DisabledVeryGoodIcon />,
    textKey: 'feedback.form.veryGood',
  },
];

export const DEFAULT_MIN_RATING_VALUE = 1;
export const DEFAULT_MAX_RATING_VALUE = 5;

interface RateSmileSliderProps {
  rating: number;
  onChange: (value: number) => void;
}

const RateSmileSlider = ({
  rating,
  onChange,
}:RateSmileSliderProps) => {
  const handleSliderChange = (value: number | number[]) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    onChange(newValue);
  };

  const handleSmileClick = (id: string) => {
    const newRating = parseInt(id, 10);
    onChange(newRating);
  };

  const isActive = (id: string) => id === rating.toString();

  return (
    <div className='rate'>
      <label htmlFor='rating'>
        <FormattedMessage id="feedback.slider.label" />
      </label>
      <ul className='smiles'>
        {smilesData.map((smile) => (
          <li
            key={smile.id}
            className={`smiles-item ${isActive(smile.id) ? 'active' : ''}`}
          >
            <button type='button' onClick={() => handleSmileClick(smile.id)}>
              {isActive(smile.id) ? smile.emoji : smile.disabledEmoji}
            </button>
            <FormattedMessage id={smile.textKey} />
          </li>
        ))}
      </ul>
      <Slider
        min={DEFAULT_MIN_RATING_VALUE}
        max={DEFAULT_MAX_RATING_VALUE}
        value={rating}
        onChange={handleSliderChange}
      />
    </div>
  );
};

export default RateSmileSlider;
