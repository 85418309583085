import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slice/authSlice';
import messageReducer from './slice/messageSlice';
import { userReducer } from './slice/userSlice';
import loadingReducer from './slice/loadingSlice';
import mealReducer from './slice/mealSlice';
import rationReducer from './slice/rationSlice';
import myRationReducer from './slice/myRationSlice';
import ingredientReducer from './slice/ingredientSlice';
import calculateReducer from './slice/calculatorSlice';

const reducer = {
  auth: authReducer,
  user: userReducer,
  meal: mealReducer,
  ration: rationReducer,
  myRation: myRationReducer,
  ingredient: ingredientReducer,
  message: messageReducer,
  loading: loadingReducer,
  calculator: calculateReducer,
};

const index = configureStore({
  reducer,
  devTools: true,
});

export type RootState = ReturnType<typeof index.getState>
export type AppDispatch = typeof index.dispatch

export default index;
