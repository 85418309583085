import { Auth } from 'aws-amplify';
import apiService from '../api/apiService';
import { getApiBaseUrl } from '../../config/config';
import { BACKEND_API_PATH } from '../../common/constant/pathConstant';
import { RegistrationFormValues } from '../../container/registration/Registration';
import { FeedbackSentApiRequest } from '../../redux/slice/userSlice';

export interface UserProfile {
  id: string;
  identityProviderId: string;
  email: string;
  username: string;
  name: string;
  gender: string;
  countryCode: string;
  languageCode: string;
  birthdayDate: string;
  height: number;
  heightUnit: string;
  weight: number;
  weightGoal: number;
  weightUnit: string;
  activityLevel: string;
  imageUrl: string;
}

export interface WeightProgressApiResponse {
  firstWeight: number;
  currentWeight: number;
  weightGoal: number;
  weightUnit: string;
}

export interface DateWeightProgressLastUpdated {
  updatedAt: string;
}

export interface WeightUpdateApiRequest {
  weight: number;
  weightGoal: number;
  weightUnit: string;
}

export interface WeightUpdateApiResponse {
  id: string;
  userAccountId: string;
  updatedAt: string;
  units: {
    unit: string;
    value: number;
  }[];
}

export interface UserLogin {
  registered: boolean
}

export interface PutUserProfile {
  name: string;
  gender: string;
  countryCode: string;
  languageCode: string;
  height: number;
  heightUnit: string;
  weight: number;
  weightGoal: number;
  weightUnit: string;
  birthdayDate: string;
  activityLevel: string;
  imageUrl: string;
}

export interface NutritionGoalApiResponse {
  calorieGoal: number;
  proteinGoal: number;
  fatGoal: number;
  carboGoal: number;
}

export interface NutritionGoalUpdateApiRequest {
  calorieGoal: number;
  proteinGoal: number;
  fatGoal: number;
  carboGoal: number;
}

async function getUserProfile(userId: string): Promise<UserProfile> {
  return apiService.get(`${getApiBaseUrl()}/${BACKEND_API_PATH.USER_ACCOUNTS}/${userId}`);
}

async function updateUserProfile(
  userId: string,
  updatedProfile: PutUserProfile,
): Promise<UserProfile> {
  return apiService.put(`${getApiBaseUrl()}/${BACKEND_API_PATH.USER_ACCOUNTS}/${userId}`, updatedProfile);
}

async function createUser(registrationForm: RegistrationFormValues): Promise<UserProfile> {
  return apiService.post(`${getApiBaseUrl()}/${BACKEND_API_PATH.USER_ACCOUNTS}`, registrationForm);
}

async function validateUserRegistrationFormData(registrationForm: Partial<RegistrationFormValues>) {
  return apiService.post(`${getApiBaseUrl()}/${BACKEND_API_PATH.USER_ACCOUNTS}/${BACKEND_API_PATH.VALIDATE}`, registrationForm, false);
}

async function getUserLoginInfoWithCustomToken(accessToken: string): Promise<UserLogin> {
  return apiService.get(`${getApiBaseUrl()}/${BACKEND_API_PATH.USER_ACCOUNTS_LOGIN_INFO}`, accessToken);
}

async function getNutritionGoals(): Promise<NutritionGoalApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.USER_ACCOUNTS}/${BACKEND_API_PATH.CURRENT}/${BACKEND_API_PATH.NUTRITION_GOALS}`,
  );
}

async function updateNutritionGoals(
  nutritionData: NutritionGoalUpdateApiRequest,
): Promise<void> {
  await apiService.put(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.USER_ACCOUNTS}/${BACKEND_API_PATH.CURRENT}/${BACKEND_API_PATH.NUTRITION_GOALS}`,
    nutritionData,
  );
}

async function sendFeedback(
  feedbackData: FeedbackSentApiRequest,
): Promise<void> {
  await apiService.post(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.FEEDBACKS}`,
    feedbackData,
  );
}

async function getWeightProgress(): Promise<WeightProgressApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.USER_ACCOUNTS}/${BACKEND_API_PATH.CURRENT}/${BACKEND_API_PATH.WEIGHT_PROGRESS}`,
  );
}
async function getTimeWeightProgressLastUpdated(): Promise<DateWeightProgressLastUpdated> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.USER_ACCOUNTS}/${BACKEND_API_PATH.CURRENT}/${BACKEND_API_PATH.WEIGHT}`,
  );
}

async function updateWeightProgress(
  weightData: WeightUpdateApiRequest,
): Promise<WeightUpdateApiResponse> {
  const response = await apiService.put(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.USER_ACCOUNTS}/${BACKEND_API_PATH.CURRENT}/${BACKEND_API_PATH.WEIGHT}`,
    weightData,
  );
  return response.data;
}

const isWeightUpdatedInExpectedDays = (
  lastWeightUpdateDate: string,
  thresholdDays: number = 14,
): boolean => {
  const currentDate = new Date();
  const lastUpdateDate = new Date(lastWeightUpdateDate);
  const daysDifference = (currentDate.getTime() - lastUpdateDate.getTime()) / (1000 * 3600 * 24);
  return daysDifference > thresholdDays;
};

const deleteUserAccount = async () => {
  await apiService.post(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.USER_ACCOUNTS}/${BACKEND_API_PATH.CURRENT}/${BACKEND_API_PATH.DELETE}`,
    {},
  );
};

const confirmDeleteUserAccount = async (deleteAccountCode:string) => {
  await apiService.deleted(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.USER_ACCOUNTS}/${BACKEND_API_PATH.CURRENT}?code=${deleteAccountCode}`,
  );
};

export async function finishSessionForDeletedUser() {
  try {
    await Auth.signOut({ global: true });
  } catch (error) {
    console.error('Logout error:', error);
  } finally {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(';').forEach((c) => {
      document.cookie = c.trim().replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    window.location.reload();
  }
}

const userService = {
  getUserProfile,
  updateUserProfile,
  createUser,
  getUserLoginInfoWithCustomToken,
  getNutritionGoals,
  updateNutritionGoals,
  getWeightProgress,
  updateWeightProgress,
  getTimeWeightProgressLastUpdated,
  isWeightUpdatedInExpectedDays,
  validateUserRegistrationFormData,
  sendFeedback,
  deleteUserAccount,
  confirmDeleteUserAccount,
  finishSessionForDeletedUser,
};

export default userService;
