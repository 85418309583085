import React, { useMemo } from 'react';
/* eslint-disable */
import Select, { StylesConfig } from 'react-select';
/* eslint-enable */
import { FormattedMessage, useIntl } from 'react-intl';
import './TimeFilter.scss';

export const selectStyle: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    borderColor: '#dedede',
    fontWeight: '400',
    textAlign: 'center',
    width: '107px',
    color: '#77838f;',
    minHeight: '26px',
    height: '26px',
    fontSize: '14px',
    backgroundColor: '#f7f8f9',
    borderRadius: '8px',
    zIndex: state.isFocused ? 6 : 1,
    '&:hover': {
      borderColor: '#dedede',
      backgroundColor: '#f1f2f3',
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 6,
    paddingTop: '8px',
    width: '98%',
    marginLeft: '1px',
    marginTop: '3px',
    color: '#77838f;',
    borderRadius: '8px',
    overflow: 'hidden',
  }),
  menuList: (base) => ({
    ...base,
    height: '200px',
    padding: '0px 0 10px 0',
    margin: '0',
    '::-webkit-scrollbar': {
      width: '4px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#06c78c',
      borderRadius: 4,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '26px',
    padding: '0 6px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '26px',
    padding: '0px',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: 'center',
    // eslint-disable-next-line
    backgroundColor: state.isSelected ? '#06C78C' : state.isFocused ? '#f0f0f0' : 'white',
    color: state.isSelected ? 'white' : '#77838f',
    '&:hover': {
      backgroundColor: state.isSelected ? '#06C78C' : '#f0f0f0',
      color: state.isSelected ? 'white' : 'black',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#77838f',
  }),
};

// memo
const generateTimeOptions = (
  minTime: number,
  maxTime: number,
  timeStep: number,
  timeUnit: 'minutes' | 'hours' | 'days',
  intl: any,
) => {
  const options = [];

  const translateUnit = (unit: string) => intl.formatMessage({ id: `time.unit.${unit}` });

  if (timeUnit === 'days') {
    for (let day = minTime; day <= maxTime; day += timeStep) {
      options.push({ value: day, label: `${day}${translateUnit('d')}` });
    }
  } else {
    for (let time = minTime; time <= maxTime; time += timeStep) {
      let value;
      if (timeUnit === 'hours') {
        const hours = Math.floor(time / 60);
        const minutes = time % 60;
        value = `${hours}${translateUnit('h')} ${minutes}${translateUnit('m')}`.trim();
      } else {
        const hours = Math.floor(time / 60);
        const minutes = time % 60;
        value = hours > 0 ? `${hours}${translateUnit('h')} ${minutes}${translateUnit('m')}`.trim() : `${minutes}${translateUnit('m')}`.trim();
      }
      options.push({ value: time, label: value });
    }
  }

  return options;
};

export interface KeyValuePair {
  key: string;
  availableValue: number;
  selectedValue: number
}

interface FilterComponentProps {
  title: string;
  icon: React.ReactNode;
  min: KeyValuePair;
  max: KeyValuePair;
  timeStep: number;
  timeUnit: 'minutes' | 'hours' | 'days';
  onChange: (...params: { key: string; value: any }[]) => void;
}

const TimeFilter = ({
  title,
  icon,
  max,
  min,
  timeStep,
  timeUnit,
  onChange,
}: FilterComponentProps) => {
  const intl = useIntl();
  const timeOptions = useMemo(
    () => generateTimeOptions(min.availableValue, max.availableValue, timeStep, timeUnit, intl),
    [min.availableValue, max.availableValue, timeStep, timeUnit, intl],
  );

  const handleMinChange = (minValue: number) => {
    onChange({ key: min.key, value: minValue });
  };

  const handleMaxChange = (maxValue: number) => {
    onChange({ key: max.key, value: maxValue });
  };

  const translate = (id:string) => intl.formatMessage({ id });

  return (
    <div className="time-filter">
      <div className="time-filter__header">
        {icon}
        <span className="time-filter__title">
          {title}
        </span>
      </div>
      <div className="time-filter__inputs">
        <label htmlFor="from-time">
          <span>
            <FormattedMessage id="filters.time.from" />
          </span>
          <Select
            placeholder={translate('filters.select.placeholder')}
            id="from-time"
            data-testid="from-time"
            value={
              {
                label: timeOptions.find((el) => el.value === min.selectedValue)?.label,
                value: min.selectedValue,
              }
            }
            options={timeOptions}
            styles={selectStyle}
            className="time-filter__select"
            classNamePrefix="time-filter"
            // @ts-ignore
            onChange={(e) => handleMinChange(e.value)}
          />
        </label>
        <span />
        <label htmlFor="to-time">
          <span>
            <FormattedMessage id="filters.time.to" />
          </span>
          <Select
            placeholder={translate('filters.select.placeholder')}
            id="to-time"
            data-testid="to-time"
            value={
              {
                label: timeOptions.find((el) => el.value === max.selectedValue)?.label,
                value: max.selectedValue,
              }
            }
            options={timeOptions}
            styles={selectStyle}
            className="time-filter__select"
            classNamePrefix="time-filter"
            // @ts-ignore
            onChange={(e) => handleMaxChange(e.value)}
          />
        </label>
      </div>
    </div>
  );
};

export default TimeFilter;
