import { enUS, uk } from 'date-fns/locale';
import { Locale } from 'date-fns/locale/types';
import English from '../../lang/en.json';
import Ukraine from '../../lang/ua.json';

export const DEFAULT_LOCALE = 'uk';

const getSupportedLanguage = () => [
  { value: 'uk', label: 'form.label.language.uk' },
  { value: 'en', label: 'form.label.language.en' },
];

const getCurrentLocale = (): string => localStorage.getItem('locale') || DEFAULT_LOCALE;

const setCurrentLocale = (locale: string): void => {
  localStorage.setItem('locale', locale);
};

const getCurrentLanguage = (): any => {
  let lang = English;

  if (getCurrentLocale() === 'uk') {
    lang = Ukraine;
  }

  return lang;
};

const getDateFormatBasedOnLocale = (): Locale => {
  if (getCurrentLocale() === 'uk') {
    return uk;
  }
  return enUS;
};

const getTextValueForCurrentLocale = (key: string): string => getCurrentLanguage()[key];

const updateLocal = (local: any) => {
  window.location.reload();
  setCurrentLocale(local);
};

const languageService = {
  getCurrentLocale,
  setCurrentLocale,
  getCurrentLanguage,
  getTextValueForCurrentLocale,
  getDateFormatBasedOnLocale,
  updateLocal,
  getSupportedLanguage,
};

export default languageService;
