import React, { useEffect } from 'react';
import './Search.scss';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import IconSearch from '../../asset/icons/search.svg';

interface SearchProps {
  onSearch?: (query: string) => void;
  searchQueryKey?: string;
}

const Search = (
  {
    onSearch = () => {},
    searchQueryKey,
  }: SearchProps,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get(searchQueryKey ?? '') ?? '';

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setSearchParams((prev) => {
      prev.set(searchQueryKey!, newQuery);
      return prev;
    }, { replace: true });
    onSearch(newQuery);
  };
  const intl = useIntl();
  const placeholderText = intl.formatMessage({ id: 'search.placeholder' });

  return (
    <div className="search">
      <input
        className="search__input"
        type="search"
        placeholder={placeholderText}
        value={searchQuery}
        onChange={handleInputChange}
      />
      <img className="search__icon" src={IconSearch} alt="search" />
    </div>
  );
};

export default Search;
