import { useState } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import FormContainer from '../../../component/form/formContainer/FormContainer';
import AuthBackgroudLayout from '../../../component/layout/authBackgroudLayout/AuthBackgroudLayout';
import backBtnImage from '../../../asset/icons/back-arrow.svg';
import { APP_PATH } from '../../../common/constant/pathConstant';
import './ForgotPassword.scss';
import { forgotPasswordSchema } from '../../../service/validation/schema/login/forgotPasswordSchema';
import ForgotPasswordStep2 from './step/ForgotPasswordStep2';
import ForgotPasswordStep1 from './step/ForgotPasswordStep1';

export const TIMER_SHOW_MESSAGE = 4000;

export interface ForgotPasswordStepProps {
  formikProps: any
  handleNextStep: () => void
}

export interface ForgotPasswordFormType {
  username: string
  verificationCode: string
  password: string
  confirmNewPassword: string
}

const initialValues: ForgotPasswordFormType = {
  username: '',
  verificationCode: '',
  password: '',
  confirmNewPassword: '',
};

const ForgotPassword = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const navigate = useNavigate();

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const renderStep = (formikProps: any) => {
    switch (currentStep) {
      case 0:
        return <ForgotPasswordStep1 formikProps={formikProps} handleNextStep={handleNextStep} />;
      case 1:
        return <ForgotPasswordStep2 formikProps={formikProps} handleNextStep={handleNextStep} />;
      default:
        return null;
    }
  };

  return (
    <AuthBackgroudLayout>
      <FormContainer>
        <button
          data-testid="button-back"
          type="button"
          className="window__back-btn back-btn"
          onClick={() => navigate(APP_PATH.LOGIN)}
        >
          <img alt="back" className="back-btn__picture" src={backBtnImage} />
        </button>
        <Formik
          initialValues={initialValues}
          validationSchema={forgotPasswordSchema}
          onSubmit={() => {}}
          validateOnChange
        >
          {(formProps) => renderStep(formProps)}
        </Formik>
      </FormContainer>
    </AuthBackgroudLayout>
  );
};
export default ForgotPassword;
