import React, { useState, useEffect, useRef } from 'react';
import './FeedbackForm.scss';
import { useIntl, IntlShape, FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../hook/useAppSelector';
import RateSmileSlider from './component/RateSmileSlider';
import { ReactComponent as ButtonIcon } from '../../asset/icons/feedback/button-icon.svg';
import { Button, ButtonType } from '../../component/form/button/Button';
import FeedbackSent from './component/FeedbackSent';
import { useAppDispatch } from '../../hook/useAppDispatch';
import { sendFeedback } from '../../redux/slice/userSlice';
import languageService from '../../service/language/languageService';
import FieldItem from '../../component/form/formField/typesField/FieldItem';
import { MAX_CHARACTERS_TEXTAREA } from '../../common/constant/charactersMaxLength';

const DEFAULT_RATING_VALUE = 4;
const DEFAULT_TIME_CLOSE_FORM_DELAY = 5000; /* ms */

const DEFAULT_FORM_DATA = {
  name: '',
  email: '',
  rating: DEFAULT_RATING_VALUE,
  message: '',
};

export const getPlaceholders = (intl: IntlShape) => ({
  name: intl.formatMessage({ id: 'feedback.placeholder.name' }),
  email: intl.formatMessage({ id: 'feedback.placeholder.email' }),
  message: intl.formatMessage({ id: 'feedback.placeholder.message' }),
});

const FeedbackForm = () => {
  const { email, name } = useAppSelector((state) => state.user);
  const language = languageService.getCurrentLocale();
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);

  const intl = useIntl();
  const placeholders = getPlaceholders(intl);

  const getInitialFormData = () => ({
    ...DEFAULT_FORM_DATA,
    name,
    email,
  });

  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isFeedbackWindowVisible, setIsFeedbackWindowVisible] = useState(false);
  const [formData, setFormData] = useState(getInitialFormData);

  const resetForm = () => {
    setFormData(getInitialFormData);
  };

  useEffect(() => {
    resetForm();
  }, [name, email]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSliderChange = (value: number) => {
    setFormData((prevData) => ({ ...prevData, rating: value }));
  };

  const handleCloseFeedbackForm = () => {
    setIsFeedbackWindowVisible(false);
    setTimeout(() => {
      setIsFormVisible(true);
    }, 500);
    document.body.classList.remove('no-scroll');
  };

  const handleOpenFeedbackForm = () => {
    setIsFeedbackWindowVisible(true);
    resetForm();
    document.body.classList.add('no-scroll');
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        handleCloseFeedbackForm();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const feedbackData = {
      id: null,
      name: formData.name,
      email: formData.email,
      rating: formData.rating,
      text: formData.message,
      refererUrl: window.location.pathname,
      userAccountIdentityProviderId: null,
      language,
    };

    try {
      await dispatch(sendFeedback(feedbackData)).unwrap();
      setIsFormVisible(false);
      setTimeout(() => {
        handleCloseFeedbackForm();
      }, DEFAULT_TIME_CLOSE_FORM_DELAY);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <>
      <button onClick={handleOpenFeedbackForm} className='feedback-button'>
        <span>
          <FormattedMessage id='feedback.button.label' />
        </span>
        <ButtonIcon />
      </button>

      <div
        ref={modalRef}
        data-testid="feedback-form"
        className={`feedback-form ${isFeedbackWindowVisible ? 'visible' : ''}`}
      >
        {isFormVisible ? (
          <form onSubmit={handleSubmit} className='form-content'>
            <div className='feedback-form__top-inputs-container'>
              <FieldItem
                className='feedback-form__input'
                isEdit
                type='name'
                name='name'
                placeholder={placeholders.name}
                value={formData.name}
                label='form.label.name'
                onChangeInput={handleChange}
              />
              <FieldItem
                className='feedback-form__input'
                isEdit
                type='email'
                name='email'
                placeholder={placeholders.email}
                label='form.label.email'
                value={formData.email}
                onChangeInput={handleChange}
              />
            </div>
            <RateSmileSlider onChange={handleSliderChange} rating={formData.rating} />
            <div className='feedback-form__input input-message'>
              <textarea
                name='message'
                value={formData.message}
                onChange={handleChange}
                maxLength={MAX_CHARACTERS_TEXTAREA}
                required
                placeholder={placeholders.message}
              />
            </div>
            <Button type={ButtonType.SUBMIT} label='submit.button' />
          </form>
        ) : (
          <FeedbackSent className='active' />
        )}
        <button type='button' onClick={handleCloseFeedbackForm} className='close-button' />
      </div>
    </>
  );
};

export default FeedbackForm;
