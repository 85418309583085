import { useIntl } from 'react-intl';
import './ProgressBar.scss';

interface ProgressBarProps {
  currentWeight: number;
  targetWeight: number;
  startingWeight: number;
  weightUnit: string;
  formatWeightUnit: (unit: string) => string;
}

const ProgressBar = ({
  currentWeight,
  targetWeight,
  startingWeight,
  weightUnit,
  formatWeightUnit,
}:ProgressBarProps) => {
  const isGainingWeight = startingWeight < targetWeight;
  const gainedOrLostWeight = Math.abs(currentWeight - startingWeight);
  const remainingWeight = Math.abs(targetWeight - currentWeight);

  const displayUnit = formatWeightUnit(weightUnit);
  const intl = useIntl();

  const getProgress = () => {
    const progress = isGainingWeight
      ? ((currentWeight - startingWeight) / (targetWeight - startingWeight)) * 100
      : ((startingWeight - currentWeight) / (startingWeight - targetWeight)) * 100;

    return Math.max(progress, 0);
  };

  return (
    <div className="goal-progress">
      <div className="goal-progress__container">
        <div className="goal-progress__bar">
          <div className="goal-progress__fill" style={{ width: `${getProgress()}%`, maxWidth: '100%' }} />
        </div>
        <div className="goal-progress__value">
          <span>
            {startingWeight}
            {' '}
            {displayUnit}
          </span>
          <span>
            {currentWeight}
            {' '}
            {displayUnit}
          </span>
          <span>
            {targetWeight}
            {' '}
            {displayUnit}
          </span>
        </div>
      </div>
      <div className="goal-progress__info">
        <p>
          {isGainingWeight ? `${intl.formatMessage({ id: 'profile.weight.gained' })}` : `${intl.formatMessage({ id: 'profile.weight.lost' })}`}
          {' '}
          <span>
            {gainedOrLostWeight}
            {' '}
            {displayUnit}
          </span>
        </p>
        <hr />
        <p>
          {isGainingWeight ? `${intl.formatMessage({ id: 'profile.weight.need.gain' })}` : `${intl.formatMessage({ id: 'profile.weight.need.lost' })}`}
          {' '}
          <span>
            {remainingWeight}
            {' '}
            {displayUnit}
          </span>
        </p>
      </div>
    </div>
  );
};

export default ProgressBar;
