import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import statisticService from '../../../../service/statistic/statisticService';

const DEFAULT_HOLE_SIZE_PERCENTAGE = 75;
ChartJS.register(ArcElement, Tooltip, Legend);

interface AnalyticExpandProps {
  valueEaten: number;
  valueForDay: number;
  intlTitle: string;
  className: string;
  color: string;
}

const AnalyticExpand = ({
  valueEaten,
  valueForDay,
  intlTitle,
  color,
  className = '',
}: AnalyticExpandProps) => {
  const [fillWidth, setFillWidth] = useState(0);

  useEffect(() => {
    setFillWidth(statisticService.calculatePercentageFromEaten(valueEaten, valueForDay));
  }, [valueEaten, valueForDay]);
  const valueToFlill = Math.round(fillWidth);

  const intl = useIntl();
  const renderLocalizedKcalEaten = (value: number, messageId: string) => {
    const localizedText = intl.formatMessage({ id: messageId });
    return `${value} ${localizedText}`;
  };

  const renderLocalizedKcalDay = (
    value: number,
    messageId: string,
  ) => intl.formatMessage({ id: messageId }, { value });

  const data = {
    datasets: [
      {
        data: [valueEaten || 0, valueForDay - valueEaten || 0.0001],
        backgroundColor: [color, '#dfdfdf'],
        borderWidth: 0,
        borderRadius: [8],
      },
    ],
  };

  const options = {
    cutout: `${DEFAULT_HOLE_SIZE_PERCENTAGE}%`,
  };

  return (
    className === 'calories' ? (
      <div data-testid={className} className='chart'>
        <div className={`chart__wrapper chart__wrapper--${className}`}>
          <Doughnut
            data={data}
            options={options}
          />
          <div className='chart__analytic-info chart__analytic-info--big'>
            <p>{`${valueToFlill}%`}</p>
            <p>{renderLocalizedKcalEaten(valueEaten, 'dashboard.title.kcal')}</p>
            <p>{renderLocalizedKcalDay(valueForDay, 'dashboard.analytics.of_total_kcal')}</p>
          </div>
        </div>
        <p className='chart__title'>
          <FormattedMessage id={intlTitle} />
        </p>
      </div>
    ) : (
      <div data-testid={className} className='chart'>
        <div className={`chart__wrapper chart__wrapper--${className}`}>
          <Doughnut
            data={data}
            options={options}
          />
          <div className='chart__analytic-info chart__analytic-info--small'>
            <p>{`${valueToFlill}%`}</p>
            <p>{renderLocalizedKcalEaten(valueEaten, 'nutrition.unit.gram')}</p>
            <p>{renderLocalizedKcalDay(valueForDay, 'dashboard.analytics.of_total_gram')}</p>
          </div>
        </div>
        <p className='chart__title'>
          <FormattedMessage id={intlTitle} />
        </p>
      </div>
    )
  );
};

export default AnalyticExpand;
