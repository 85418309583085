import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './Ration.scss';
import { useParams } from 'react-router-dom';
import RationSlider from './component/RationSlider';
import RationBanner from './component/RationBanner/RationBanner';
import ExpandableBlock from '../../component/expand/ExpandableBlock';
import { useAppDispatch } from '../../hook/useAppDispatch';
import {
  clearRationById,
  getRationContentByDay,
  getRationContentById,
  initialRationOnDay,
  RationForDays,
} from '../../redux/slice/rationSlice';
import { useAppSelector } from '../../hook/useAppSelector';
import TagList from '../../component/tag/TagList';
import LoadingSpinner from '../../component/spinner/LoadingSpinner';
import CalendarDayPicker from './calendar/CalendarDayPicker';
import ModalWindow from '../../component/layout/modalWindow/ModalWindow';
import ApplyRationModal from './addRationModal/ApplyRationModal';
import RationReviews from './component/rationReviews/RationReviews';

const Ration = () => {
  const dispatch = useAppDispatch();
  const { rationId = '' } = useParams<{ rationId: string }>();
  const [selectedDay, setSelectedDay] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const ration = useAppSelector((store) => store.ration.rationById.ration);
  const rationOnDay: RationForDays = useAppSelector(
    (store) => store.ration.rationById.rationForDays.find((item) => item.day === selectedDay)
      || initialRationOnDay,
  );

  useEffect(() => {
    if (ration?.id !== rationId) {
      setIsLoading(true);
      dispatch(getRationContentById({ day: selectedDay, rationId }))
        .unwrap()
        .finally(() => setIsLoading(false));
    }
    return () => {
      dispatch(clearRationById());
    };
  }, [rationId]);

  useEffect(() => {
    if (rationOnDay?.day !== selectedDay) {
      setIsLoading(true);
      dispatch(getRationContentByDay({ day: selectedDay, rationId }))
        .unwrap()
        .finally(() => setIsLoading(false));
    }
  }, [selectedDay, setSelectedDay]);

  return (
    rationId && (
      <section className="profile-window ration-item">
        {isModalOpen && (
          <ModalWindow handleCloseModal={() => setIsModalOpen(false)}>
            <ApplyRationModal
              setIsModalOpen={setIsModalOpen}
              rationId={rationId}
              durationDays={ration.durationDays}
            />
          </ModalWindow>
        )}
        <div className="ration-item__left">
          <div className="ration-item__header ration-header">
            <div className="ration-header__left">
              <h2 className="ration-header__title title-side">{ration.name}</h2>
              {ration.tags && <TagList tags={ration.tags} />}
            </div>
            <div className="ration-header__right">
              <CalendarDayPicker
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                durationDays={ration.durationDays}
              />
            </div>
          </div>
          <div className="ration-item__slider">
            {isLoading ? (
              <div className="loading" data-testid="loading">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="ration-item__slider">
                <RationSlider foodTypes={rationOnDay.foodTypes} />
              </div>
            )}
          </div>
          <button
            className="ration-item__button animated-gradient-btn"
            type="button"
            data-testid="openModal-AddRationCalendar"
            onClick={() => setIsModalOpen(true)}
          >
            <FormattedMessage id="ration.apply.button" />
          </button>
        </div>
        <hr />
        <div className="ration-item__right">
          <RationBanner ration={ration} />
          <div className="ration-item__descr-wrapper">
            {ration.description ? (
              <ExpandableBlock
                title="meal.description.title"
                content={ration.description}
                initiallyExpanded
              />
            ) : null}
            {ration.considerations ? (
              <ExpandableBlock
                title="ration.title.pros-cons"
                content={(
                  <div className="pros-and-cons">
                    <h4 className="title">
                      <FormattedMessage id="ration.title.pros" />
                    </h4>
                    <ul className="pros-list">
                      {ration.considerations?.map((pros) => {
                        const isPros = pros.type === 'ADVANTAGE';
                        return isPros ? (
                          <li className="pros-list__item" key={pros.id}>
                            {pros.description}
                          </li>
                        ) : null;
                      })}
                    </ul>
                    <h4 className="title">
                      <FormattedMessage id="ration.title.cons" />
                    </h4>
                    <ul className="cons-list">
                      {ration.considerations?.map((cons) => {
                        const isPros = cons.type === 'ADVANTAGE';
                        return !isPros ? (
                          <li className="cons-list__item" key={cons.id}>
                            {cons.description}
                          </li>
                        ) : null;
                      })}
                    </ul>
                  </div>
                )}
              />
            ) : null}
            <ExpandableBlock
              title="ration.title.reviews"
              content={<RationReviews rationId={rationId} />}
            />
          </div>
        </div>
      </section>
    )
  );
};

export default Ration;
