import React, { useState, useEffect } from 'react';
import './ConfirmationModal.scss';
import { FormattedMessage } from 'react-intl';
import ModalWindow from '../layout/modalWindow/ModalWindow';
import userService from '../../service/user/userService';
import { useAppDispatch } from '../../hook/useAppDispatch';
import { convertToErrorMessage } from '../../service/message/messageConverter';
import { setMessage } from '../../redux/slice/messageSlice';
import VerificationCodeInput from '../../container/profile/setting/component/advancedSetting/VerificationCodeInput';

interface ConfirmationModalProps {
  handleClose: () => void;
  handleConfirm?: () => void;
  title: string;
  message?: string;
  showDeleteAccount?: boolean;
  email?: string;
}

const CONFIRM_CODE_INPUTS_DEFAULT_SIZE = 6;

const ConfirmationModal = ({
  handleClose,
  handleConfirm,
  title,
  message,
  showDeleteAccount = false,
  email,
}: ConfirmationModalProps) => {
  const [verificationCode, setVerificationCode] = useState(new Array(CONFIRM_CODE_INPUTS_DEFAULT_SIZE).fill(''));
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeComplete, setIsCodeComplete] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsCodeComplete(verificationCode.every((value) => value !== ''));
  }, [verificationCode]);

  useEffect(() => {
    if (isCodeComplete) {
      const applyButton = document.getElementById('apply-button') as HTMLButtonElement;
      applyButton?.focus();
    }
  }, [isCodeComplete]);

  const getCode = () => verificationCode.join('');

  const handleConfirmDeleteAcc = async () => {
    try {
      setIsLoading(true);
      await userService.confirmDeleteUserAccount(getCode());
      await userService.finishSessionForDeletedUser();
    } catch (error: any) {
      dispatch(setMessage(convertToErrorMessage(error)));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalWindow handleCloseModal={handleClose} customClassName='confirmation-modal'>
      <h2 id='confirmation-title' className='confirmation-modal__title title-confirm'>
        {title}
      </h2>
      {message && <p className='confirmation-modal__message'>{message}</p>}
      {showDeleteAccount && (
        <div className='confirmation-modal__verification'>
          <p>
            <FormattedMessage id='confirmation.delete.acc.paragraph' />
          </p>
          <p>
            <FormattedMessage id='confirmation.delete.acc.email' values={{ value: email }} />
          </p>
          <VerificationCodeInput
            verificationCode={verificationCode}
            setVerificationCode={setVerificationCode}
          />
        </div>
      )}
      <div className='confirmation-modal__buttons'>
        <button onClick={handleClose} className='cancel-button'>
          <FormattedMessage id='form.label.cancel' />
        </button>
        <button
          id='apply-button'
          onClick={handleConfirm || handleConfirmDeleteAcc}
          className='apply-button animated-gradient-btn'
          disabled={showDeleteAccount && !isCodeComplete}
        >
          {isLoading ? (
            <span data-testid="loading-spinner" className='spinner-border spinner-border-sm' />
          ) : (
            <FormattedMessage id='apply.button' />
          )}
        </button>
      </div>
    </ModalWindow>
  );
};

export default ConfirmationModal;
