import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from '../spinner/LoadingSpinner';
import { RootState } from '../../redux';
import { useAppDispatch } from '../../hook/useAppDispatch';
import { clearMessage } from '../../redux/slice/messageSlice';
import './ComponentLayout.scss';

interface ComponentLayoutProps {
    children: React.ReactNode
}

const TIMEOUT_TO_CLEAR_MESSAGE_MS = 5000;

const ComponentLayout = ({ children }: ComponentLayoutProps) => {
  const isLoading: boolean = useSelector((state: RootState) => state.loading.isLoading);
  const { message } = useSelector((state: RootState) => state.message);
  const dispatch = useAppDispatch();

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  useEffect(() => {
    if (message.text) {
      setIsAlertVisible(true);
      const timer = setTimeout(() => {
        dispatch(clearMessage());
        setIsAlertVisible(false);
      }, TIMEOUT_TO_CLEAR_MESSAGE_MS);
      return () => clearTimeout(timer);
    }
  }, [message, dispatch]);

  return (
    <div className="layout">
      <div className={`alert-popup ${isAlertVisible ? 'alert-popup--active' : ''}`}>
        <div className="alert-popup__content">
          {Array.isArray(message.text) ? (
            <>
              {message.text.map((error: any) => (
                <span key={error}>
                  <p className="alert-popup__content__type">{message.type}</p>
                  <p className="alert-popup__content__text">{error}</p>
                </span>
              ))}
            </>
          ) : (
            <span>
              <p className="alert-popup__content__type">{message.type}</p>
              <p className="alert-popup__content__text">{message.text}</p>
            </span>
          )}

          <button
            type="button"
            className="alert-popup__content__close"
            onClick={() => {
              dispatch(clearMessage());
              setIsAlertVisible(false);
            }}
          >
            <FormattedMessage id="popup.alert.close" />
          </button>
        </div>
      </div>
      {isLoading && <div className="loading-spiner"><LoadingSpinner /></div>}
      {children}
    </div>
  );
};

export default ComponentLayout;
