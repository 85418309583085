import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import rationService from '../../service/ration/rationService';
import { setMessage } from './messageSlice';
import { convertToErrorMessage } from '../../service/message/messageConverter';
import {
  ChangeReview,
  FoodType,
  Ingredient,
  Meal,
  MealsApiResponse,
  PageableResponseWrapper,
  Ration,
  RationSearchRequestDto,
  RationSearchRequestDtoOptional,
  Review,
  SendReview,
  Tag,
  TagsSearchApiResponse,
} from '../../common/constant/interface/interfaces';
import { initialNutrition } from './myRationSlice';
import { IngredientsApiResponse } from './ingredientSlice';
import { SearchItem } from '../../component/filter/search/SearchFilter';
import rationMapperService from '../../service/mapper/ration/rationMapperService';
import filterService from '../../service/filter/filterService';

interface GetRationApiRequest {
  cursorId: string;
  size: number;
}

interface GetRationByIdApiRequest {
  day: number;
  rationId: string;
}

export interface GetRationReviewApiResponse {
  rationId: string;
  REVIEWS_PAGINATION_COUNT_SIZE: number;
  page: number;
}

export interface RationReviewDataToPost {
  reviewItems: Review[];
}

export interface GetRationByDayResponse {
  foodTypes: FoodType[];
}

export interface CollectedDataToPostApplyRation {
  start: string;
  repeatCount: number;
  rationId: string;
}

export interface RationFilterApiResponse {
  calorieMin: number;
  calorieMax: number;
  proteinMin: number;
  proteinMax: number;
  carboMin: number;
  carboMax: number;
  fatsMin: number;
  fatsMax: number;
  durationDaysMin: number;
  durationDaysMax: number;
}

export interface RationSearchSelectedParam {
  name: string;
  calorieMin: number;
  calorieMax: number;
  proteinMin: number;
  proteinMax: number;
  carboMin: number;
  carboMax: number;
  fatMin: number;
  fatMax: number;
  durationDaysMin: number;
  durationDaysMax: number;
  ingredients: SearchItem[];
  tags: SearchItem[];
  meals: SearchItem[];
}

export interface RationApiResponse {
  content: Ration[];
  nextCursorId: string;
}

export interface RationForDays {
  day: number;
  foodTypes: FoodType[];
}

export interface RationReviewApiResponse {
  content: Review[];
}

export interface RationReviewDataToDelete {
  rationId: string;
  reviewId: string;
}

interface InitialState {
  rations: Ration[];
  nextCursorId: string;
  rationById: {
    ration: Ration;
    rationForDays: RationForDays[];
  };
  search: {
    searchResults: Ration[];
    searchNextCursorId: string;
    searchParam: string;
    appliedSearchParam: RationSearchRequestDto;
    selectedSearchParam: RationSearchSelectedParam;
    availableSearchParam: RationFilterApiResponse;
    ingredients: {
      availableIngredients: Ingredient[];
      cursorId: string;
      search: {
        name: string;
        cursorId: string;
        searchResult: Ingredient[];
      };
    };
    tags: {
      availableTags: Tag[];
      cursorId: string;
      search: {
        name: string;
        cursorId: string;
        searchResult: Tag[];
      };
    };
    meals: {
      availableMeals: Meal[];
      cursorId: string;
      search: {
        name: string;
        cursorId: string;
        searchResult: Meal[];
      };
    };
  };
  reviews: Review[];
}

export const initialRationOnDay: RationForDays = {
  day: 1,
  foodTypes: [],
};

const initialRationById = {
  id: '',
  name: '',
  description: '',
  language: '',
  durationDays: 0,
  considerations: [],
  foodTypes: [],
  images: [],
  nutrition: initialNutrition,
  userAccount: {
    identityProviderId: '',
    imageUrl: '',
    name: '',
  },
  tags: [],
};

export const initialAppliedSearchParamObject: RationSearchRequestDto = {
  calorieMax: 0,
  calorieMin: 0,
  carboMax: 0,
  carboMin: 0,
  durationDaysMax: 0,
  durationDaysMin: 0,
  fatMax: 0,
  fatMin: 0,
  ingredientIds: [],
  mealIds: [],
  name: '',
  proteinMax: 0,
  proteinMin: 0,
  tagIds: [],
};

export const initialAvailableFilter: RationFilterApiResponse = {
  calorieMax: 0,
  calorieMin: 0,
  carboMax: 0,
  carboMin: 0,
  durationDaysMax: 0,
  durationDaysMin: 0,
  fatsMax: 0,
  fatsMin: 0,
  proteinMax: 0,
  proteinMin: 0,
};

export const initialSelectedSearchObject: RationSearchSelectedParam = {
  calorieMax: 0,
  calorieMin: 0,
  carboMax: 0,
  carboMin: 0,
  durationDaysMax: 0,
  durationDaysMin: 0,
  fatMax: 0,
  fatMin: 0,
  ingredients: [],
  meals: [],
  name: '',
  proteinMax: 0,
  proteinMin: 0,
  tags: [],
};

export const initialSearchState = {
  searchResults: [],
  searchNextCursorId: '',
  searchParam: '',
  appliedSearchParam: initialAppliedSearchParamObject,
  selectedSearchParam: initialSelectedSearchObject,
  availableSearchParam: initialAvailableFilter,
  ingredients: {
    availableIngredients: [],
    cursorId: '',
    search: {
      name: '',
      cursorId: '',
      searchResult: [],
    },
  },
  tags: {
    availableTags: [],
    cursorId: '',
    search: {
      name: '',
      cursorId: '',
      searchResult: [],
    },
  },
  meals: {
    availableMeals: [],
    cursorId: '',
    search: {
      name: '',
      cursorId: '',
      searchResult: [],
    },
  },
};

export const initialState: InitialState = {
  rations: [],
  nextCursorId: '',
  rationById: {
    ration: initialRationById,
    rationForDays: [],
  },
  search: initialSearchState,
  reviews: [],
};

export const getRations = createAsyncThunk<
  RationApiResponse,
  GetRationApiRequest,
  { rejectValue: string }
>('ration/rations', async ({ cursorId, size }, thunkAPI) => {
  try {
    return await rationService.getRations(cursorId, size);
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

export const searchRations = createAsyncThunk<
  RationApiResponse,
  { selectedParam: RationSearchSelectedParam; availableParam: RationFilterApiResponse },
  { rejectValue: string }
>(
  'ration/searchRations',
  async ({ selectedParam: selectedSearchParam, availableParam }, thunkAPI) => {
    try {
      const searchRequestDto = rationMapperService.mapSelectedSearchObjToRationSearchRequestDto(
        selectedSearchParam,
        availableParam,
      );
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      thunkAPI.dispatch(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setAppliedSearchObject({
          selectedSearchParam,
          convertedToRequestSearchParam: searchRequestDto,
        }),
      );
      return await rationService.searchRations(searchRequestDto);
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getRationContentById = createAsyncThunk<
  { content: Ration; day: number },
  GetRationByIdApiRequest,
  { rejectValue: string }
>('ration/getRationContentById', async ({ day, rationId }, thunkAPI) => {
  try {
    const res = await rationService.getRationContentById(day, rationId);
    return { content: res, day };
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

export const getRationContentByDay = createAsyncThunk<
  { foodTypes: FoodType[]; day: number },
  GetRationByIdApiRequest,
  { rejectValue: string }
>('ration/getRationContentByDay', async ({ day, rationId }, thunkAPI) => {
  try {
    const res: GetRationByDayResponse = await rationService.getRationContentByDay(day, rationId);
    return { foodTypes: res.foodTypes, day };
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

export const getAvailableFiltersDataToSelect = createAsyncThunk<
  RationFilterApiResponse,
  void,
  { rejectValue: string }
>('ration/filterSearch', async (arg_, thunkAPI) => {
  try {
    return await rationService.getAvailableFilters();
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

export const getAvailableIngredientsToSelect = createAsyncThunk<
  IngredientsApiResponse,
  { cursorId: string; size: number },
  { rejectValue: string }
>('ration/filterIngredientGet', async ({ cursorId = '', size }, thunkAPI) => {
  try {
    return await rationService.getAvailableIngredients(cursorId, '', size);
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

export const searchAvailableIngredientsToSelect = createAsyncThunk<
  PageableResponseWrapper<IngredientsApiResponse>,
  { name: string; cursorId: string; size: number; overridePrev: boolean },
  { rejectValue: string }
>(
  'ration/filterIngredientSearch',
  async ({
    cursorId = '', size, name, overridePrev,
  }, thunkAPI) => {
    try {
      const response = await rationService.getAvailableIngredients(cursorId, name, size);

      return { response, overrideResult: overridePrev, name };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAvailableTagsToSelect = createAsyncThunk<
  TagsSearchApiResponse,
  { cursorId: string; size: number },
  { rejectValue: string }
>('ration/filterTagsGet', async ({ cursorId = '', size }, thunkAPI) => {
  try {
    return await rationService.getAvailableTags(cursorId, '', size);
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

export const searchAvailableTagsToSelect = createAsyncThunk<
  PageableResponseWrapper<TagsSearchApiResponse>,
  { cursorId: string; name: string; size: number; overridePrev: boolean },
  { rejectValue: string }
>('ration/filterTagsSearch', async ({
  cursorId = '', name = '', size, overridePrev,
}, thunkAPI) => {
  try {
    const response = await rationService.getAvailableTags(cursorId, name, size);
    return { response, overrideResult: overridePrev, name };
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

export const searchAvailableMealsToSelect = createAsyncThunk<
  PageableResponseWrapper<MealsApiResponse>,
  { cursorId: string; name: string; size: number; overridePrev: boolean },
  { rejectValue: string }
>(
  'ration/filterMealsSearch',
  async ({
    cursorId = '', name = '', size, overridePrev,
  }, thunkAPI) => {
    try {
      const response = await rationService.getAvailableMeals(cursorId, name, size);
      return { response, overrideResult: overridePrev, name };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const getAvailableMealsToSelect = createAsyncThunk<
  MealsApiResponse,
  { cursorId: string; size: number },
  { rejectValue: string }
>('ration/filterMealsGet', async ({ cursorId = '', size }, thunkAPI) => {
  try {
    return await rationService.getAvailableMeals(cursorId, '', size);
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

export const getAllRationReviews = createAsyncThunk<
  RationReviewApiResponse,
  GetRationReviewApiResponse,
  { rejectValue: string }
>(
  'ration/getRationReviews',
  async ({ rationId, page, REVIEWS_PAGINATION_COUNT_SIZE }, thunkAPI) => {
    try {
      return await rationService.getRationReviews(rationId, page, REVIEWS_PAGINATION_COUNT_SIZE);
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const deleteReviewFromRation = createAsyncThunk(
  'ration/deleteReviewFromRation',
  async ({ rationId, reviewId }: RationReviewDataToDelete, thunkAPI) => {
    try {
      await rationService.deleteRationReview(rationId, reviewId);
      return { reviewId };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  },
);

export const sendRationReview = createAsyncThunk<
  Review,
  { rationId: string; reviewItem: SendReview },
  { rejectValue: string }
>('ration/sendRationReview', async ({ rationId, reviewItem }, thunkAPI) => {
  try {
    return await rationService.sendRationReview(rationId, reviewItem);
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateRationReview = createAsyncThunk<
  Review,
  { rationId: string; reviewId: string; reviewItem: ChangeReview },
  { rejectValue: string }
>('ration/updateRationReview', async ({ rationId, reviewId, reviewItem }, thunkAPI) => {
  try {
    return await rationService.updateRationReview(rationId, reviewId, reviewItem);
  } catch (error: any) {
    const { message } = error;
    thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
    return thunkAPI.rejectWithValue(message);
  }
});

interface SearchRequestStoreUpdate {
  selectedSearchParam: RationSearchSelectedParam;
  convertedToRequestSearchParam: RationSearchRequestDtoOptional;
}

const rationSlice = createSlice({
  name: 'ration',
  initialState,
  reducers: {
    setAppliedSearchObject: (state, action: PayloadAction<SearchRequestStoreUpdate>) => {
      const searchObj = {
        ...state.search.appliedSearchParam,
        ...action.payload.selectedSearchParam,
      };
      const searchParam = filterService.mapObjectToSearchParams(
        action.payload.convertedToRequestSearchParam,
      );

      state.search.appliedSearchParam = searchObj;
      state.search.searchParam = searchParam;
    },
    clearSelectedSearchParam: (state) => {
      state.search.searchParam = '';
      state.search.selectedSearchParam = (
        rationMapperService.mapFilterApiResponseToRationSearchSelectedParam(
          state.search.availableSearchParam,
        ));
    },
    setSelectedSearchObject: (state, action: PayloadAction<RationSearchSelectedParam>) => {
      state.search.selectedSearchParam = action.payload;
    },
    clearRationById: (state) => {
      state.rationById.rationForDays = [];
      state.rationById.ration = initialRationById;
    },
  },
  extraReducers: (reducerBuilder) => {
    reducerBuilder
      .addCase(getRations.fulfilled, (state, action) => {
        state.rations = [...state.rations, ...action.payload.content];
        state.nextCursorId = action.payload.nextCursorId;
      })
      .addCase(searchRations.fulfilled, (state, action) => {
        state.search.searchResults = action.payload.content;
        state.search.searchNextCursorId = action.payload.nextCursorId;
      })
      .addCase(getRationContentById.fulfilled, (state, action) => {
        const { content, day } = action.payload;
        state.rationById.ration = content;
        const rationOnDay = { day, foodTypes: content.foodTypes };
        state.rationById.rationForDays.push(rationOnDay);
      })
      .addCase(getRationContentByDay.fulfilled, (state, action) => {
        const { foodTypes, day } = action.payload;
        const rationOnDay = { day, foodTypes };
        state.rationById.rationForDays.push(rationOnDay);
      })
      .addCase(getAvailableFiltersDataToSelect.fulfilled, (state, action) => {
        state.search.availableSearchParam = action.payload;
        state.search.appliedSearchParam = rationMapperService.mapToRationSearchRequestDto(
          action.payload,
        );
        // eslint-disable-next-line max-len
        state.search.selectedSearchParam = rationMapperService.mapFilterApiResponseToRationSearchSelectedParam(action.payload);
      })
      .addCase(getAvailableIngredientsToSelect.fulfilled, (state, action) => {
        state.search.ingredients.availableIngredients = [
          ...state.search.ingredients.availableIngredients,
          ...action.payload.content,
        ];
        state.search.ingredients.cursorId = action.payload.nextCursorId;
      })
      .addCase(searchAvailableIngredientsToSelect.fulfilled, (state, action) => {
        if (action.payload.overrideResult) {
          state.search.ingredients.search.searchResult = action.payload.response.content;
          state.search.ingredients.search.cursorId = action.payload.response.nextCursorId;
        } else {
          state.search.ingredients.search.searchResult = [
            ...state.search.ingredients.search.searchResult,
            ...action.payload.response.content,
          ];
          state.search.ingredients.search.cursorId = action.payload.response.nextCursorId;
        }
        state.search.ingredients.search.name = action.payload.name;
      })
      .addCase(searchAvailableMealsToSelect.fulfilled, (state, action) => {
        if (action.payload.overrideResult) {
          state.search.meals.search.searchResult = action.payload.response.content;
          state.search.meals.search.cursorId = action.payload.response.nextCursorId;
        } else {
          state.search.meals.search.searchResult = [
            ...state.search.meals.search.searchResult,
            ...action.payload.response.content,
          ];
          state.search.meals.search.cursorId = action.payload.response.nextCursorId;
        }
        state.search.meals.search.name = action.payload.name;
      })
      .addCase(getAvailableMealsToSelect.fulfilled, (state, action) => {
        state.search.meals.availableMeals = [
          ...state.search.meals.availableMeals,
          ...action.payload.content,
        ];
        state.search.meals.cursorId = action.payload.nextCursorId;
      })
      .addCase(getAvailableTagsToSelect.fulfilled, (state, action) => {
        state.search.tags.availableTags = [
          ...state.search.tags.availableTags,
          ...action.payload.content,
        ];
        state.search.tags.cursorId = action.payload.nextCursorId;
      })
      .addCase(searchAvailableTagsToSelect.fulfilled, (state, action) => {
        if (action.payload.overrideResult) {
          state.search.tags.search.searchResult = action.payload.response.content;
          state.search.tags.search.cursorId = action.payload.response.nextCursorId;
        } else {
          state.search.tags.search.searchResult = [
            ...state.search.tags.search.searchResult,
            ...action.payload.response.content,
          ];
          state.search.tags.search.cursorId = action.payload.response.nextCursorId;
        }
        state.search.tags.search.name = action.payload.name;
      })
      .addCase(getAllRationReviews.fulfilled, (state, action) => {
        if (action.meta.arg.page === 0) {
          state.reviews = action.payload.content;
        } else {
          state.reviews = [...state.reviews, ...action.payload.content];
        }
      })
      .addCase(deleteReviewFromRation.fulfilled, (state, action) => {
        state.reviews = state.reviews.filter((review) => review.id !== action.payload.reviewId);
      })
      .addCase(sendRationReview.fulfilled, (state, action) => {
        state.reviews = [action.payload, ...state.reviews];
      })
      .addCase(updateRationReview.fulfilled, (state, action) => {
        state.reviews = state.reviews.map((review) => {
          if (review.id === action.payload.id) {
            return action.payload;
          }
          return review;
        });
      });
  },
});

const { reducer, actions } = rationSlice;

export const {
  clearSelectedSearchParam,
  setAppliedSearchObject,
  setSelectedSearchObject,
  clearRationById,
} = actions;

export default reducer;
