import '../../container/calculate/Calculator.scss';
import { FormattedMessage } from 'react-intl';

type GradientButtonProps = {
  readonly messageId: string;
  readonly styleClass?: string;
  readonly type?: 'submit' | 'button' | 'reset';
};

function GradientButton({ messageId, styleClass = '', type = 'button' }: GradientButtonProps) {
  return (
    <button className={`animated-gradient-btn ${styleClass}`} type={type}>
      <FormattedMessage id={messageId} />
    </button>
  );
}

export default GradientButton;
