import React from 'react';
import { FormattedMessage } from 'react-intl';
import '../Ration.scss';
import { Link } from 'react-router-dom';
import { APP_PATH, BACKEND_INGREDIENTS_PARAMS } from '../../../common/constant/pathConstant';

interface DishItemProps {
  image: string;
  name: string;
  kcal: number;
  weight: number;
  mealId: string;
  ingredientId: string;
}

const DishItem = ({
  image, name, kcal, weight, mealId, ingredientId,
}: DishItemProps) => (
  <div className="dish-item">
    <div className="dish-item__descr">
      <div className="dish-item__pic">
        <img src={image} alt={name} />
      </div>
      <div className="dish-item__info dish-info">
        <Link
          to={
            mealId
              ? `${APP_PATH.APP_MEALS}/${mealId}`
              : `${APP_PATH.APP_INGREDIENTS}?${BACKEND_INGREDIENTS_PARAMS.INGREDIENT_ID}=${ingredientId}`
          }
          className="dish-info__name"
        >
          {name}
        </Link>
        <div className="dish-info__kcal">
          {kcal}
          <span>
            <FormattedMessage id="meal.item.kcal" />
          </span>
        </div>
      </div>
    </div>
    <div className="dish-item__options dish-options">
      <div className="dish-options__weight">
        {weight}
        <span>
          <FormattedMessage id="ration.unit.gram" />
        </span>
      </div>
    </div>
  </div>
);

export default DishItem;
