export const APP_PATH = {
  APP_PROFILE: '/app/profile',
  APP_SETTINGS: '/app/profile/setting',
  APP_RATIONS: '/app/rations',
  APP_MEALS: '/app/meals',
  APP_CALCULATE: '/app/calculate',
  APP_INGREDIENTS: '/app/ingredients',
  APP_APPLIED_RATIONS: '/app/rations/applied',
  PROFILE: 'profile',
  PROFILE_SETTING: 'profile/setting',
  REGISTRATION: '/registration',
  MEALS: 'meals',
  INGREDIENTS: 'ingredients',
  MEAL: 'meal',
  RATIONS: 'rations',
  APPLIED_RATIONS: 'rations/applied',
  DASHBOARD: '/app/dashboard',
  CALCULATE: 'calculate',
  SUCCESS_LOGIN_REDIRECT: '/successLogin',
  LOGIN: '/login',
  LOGIN_EMAIL_IS_NOT_CONFIRMED: '/login/emailNotConfirmed',
  LOGIN_USER_DATA_IS_NOT_FILLED: '/login/userDataIsNotFilled',
  LOGIN_FORGOT_PASSWORD: '/login/forgotPassword',
  TERMS_AND_CONDITIONS: '/terms',
  PRIVACY_POLICY: '/privacy',
  DEFAULT: '/',
  NOT_FOUND: '*',
};

export const BACKEND_API_PATH = {
  USER_ACCOUNTS: 'user-accounts',
  MEALS: 'meals',
  RATIONS: 'rations',
  MY_RATIONS: 'my-rations',
  INGREDIENTS: 'ingredients',
  CALCULATOR: 'nutritions/calculator',
  INGREDIENTS_GROUPS: 'ingredients/groups',
  USER_ACCOUNTS_LOGIN_INFO: 'user-accounts/current/login-info',
  CURRENT: 'current',
  WEIGHT: 'weight',
  WEIGHT_PROGRESS: 'weight-progress',
  NUTRITION_GOALS: 'nutrition-goals',
  STATUS: 'status',
  VALIDATE: 'validate',
  FEEDBACKS: 'feedbacks',
  SEARCH: 'search',
  FILTER: 'filter-data',
  DELETE: 'delete',
};

export const BACKEND_MEALS_PARAMS = {
  CURSOR_ID: 'cursorId',
  SIZE: 'size',
  NAME: 'name',
};

export const BACKEND_RATIONS_PARAMS = {
  CURSOR_ID: 'cursorId',
  SIZE: 'size',
  DAY_NUMBER: 'dayNumber',
  FOOD_TYPES: 'foodTypes',
  APPLY: 'apply',
  NAME: 'name',
};

export const BACKEND_MY_RATIONS_PARAMS = {
  DAY: 'day',
  DAYS: 'days',
  YEAR: 'year',
  MONTH: 'month',
  ITEMS: 'items',
  OCCUPIED: 'occupied',
};

export const BACKEND_MY_RATIONS_PATH_PARAMS = {
  DAYS: 'days',
  ITEMS: 'items',
};

export const BACKEND_INGREDIENTS_PARAMS = {
  CURSOR_ID: 'cursorId',
  SIZE: 'size',
  LEVEL: 'level',
  GROUP_ID: 'groupIds',
  NAME: 'name',
  INGREDIENT_ID: 'ingredientId',
};

export const BACKEND_COMMON_PARAMS = {
  LANGUAGE: 'language',
  INCLUDE: 'include',
};

export const BACKEND_CALCULATE_PARAMS = {
  INIT_DATA: 'init-data',
  CALCULATE: 'calculate',
};
