import { useEffect, useState } from 'react';
import './AppliedRationList.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../redux';
import {
  cancelAppliedRation, clearAppliedRations, getAppliedRationsByStatus,
} from '../../redux/slice/myRationSlice';
import { useAppDispatch } from '../../hook/useAppDispatch';
import { useAppSelector } from '../../hook/useAppSelector';
import PresentRationsTab from './component/tab/PresentRationsTab';
import PastRationsTab from './component/tab/PastRationsTab';
import FutureRationsTab from './component/tab/FutureRationsTab';
import ConfirmationModal from '../../component/confirmationModal/ConfirmationModal';
import LoadingSpinner from '../../component/spinner/LoadingSpinner';

export const PRESENT_APPLIED_RATION = 'PRESENT';
export const FUTURE_APPLIED_RATION = 'FUTURE';
export const PAST_APPLIED_RATION = 'PAST';

const AppliedRationList = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const confirmationModalMessage = intl.formatMessage({ id: 'confirmation.message' });
  const confirmationModalTitle = intl.formatMessage({ id: 'confirmation.title' });
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(PRESENT_APPLIED_RATION);
  const [rationIdToCancel, setRationIdToCancel] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);

  const presentTabContent = useAppSelector((state: RootState) => state.myRation
    .appliedRations.presentAppliedRation);
  const pastTabContent = useAppSelector((state: RootState) => state.myRation
    .appliedRations.pastAppliedRation);
  const futureTabContent = useAppSelector((state: RootState) => state.myRation
    .appliedRations.futureAppliedRation);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status');
    setActiveTab(status!);
  }, [location.search]);

  const hasRequiredDataForTab = (tab: string) => {
    if (tab === PRESENT_APPLIED_RATION) {
      return presentTabContent.length > 0;
    }
    if (tab === PAST_APPLIED_RATION) {
      return pastTabContent.length > 0;
    }
    if (tab === FUTURE_APPLIED_RATION) {
      return futureTabContent.length > 0;
    }

    return false;
  };

  useEffect(() => {
    if (!hasRequiredDataForTab(activeTab)) {
      setIsLoading(true);
      dispatch(getAppliedRationsByStatus(activeTab))
        .unwrap()
        .finally(() => setIsLoading(false));
    }
  }, [activeTab, dispatch, location.pathname]);

  useEffect(() => () => {
    dispatch(clearAppliedRations());
  }, [dispatch]);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    navigate(`?status=${tab}`);
  };

  const handleCancelRationConfirm = (id: string) => {
    setRationIdToCancel(id);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setRationIdToCancel(null);
  };

  const handleCancelRation = () => {
    if (rationIdToCancel) {
      dispatch(cancelAppliedRation({ id: rationIdToCancel, status: activeTab }));
    }
    setShowModal(false);
    setRationIdToCancel(null);
  };

  return (
    <div className="profile-window applied-rations">
      <div className="applied-rations__tabs">
        <button
          className={activeTab === PAST_APPLIED_RATION ? 'active' : ''}
          onClick={() => handleTabChange(PAST_APPLIED_RATION)}
        >
          <FormattedMessage id="applied.ration.past" />
        </button>
        <button
          className={activeTab === PRESENT_APPLIED_RATION ? 'active' : ''}
          onClick={() => handleTabChange(PRESENT_APPLIED_RATION)}
        >
          <FormattedMessage id="applied.ration.present" />
        </button>
        <button
          className={activeTab === FUTURE_APPLIED_RATION ? 'active' : ''}
          onClick={() => handleTabChange(FUTURE_APPLIED_RATION)}
        >
          <FormattedMessage id="applied.ration.future" />
        </button>
      </div>
      <div className="applied-rations__content">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {activeTab === PRESENT_APPLIED_RATION && (
              <PresentRationsTab
                rations={presentTabContent.map((el) => ({
                  ...el,
                  ration: {
                    ...el.ration,
                    name: 'Hardcoded name',
                    tags: [{
                      name: 'Hardcded tag', color: 'red', id: '1', userAccountIdentityProviderId: '1',
                    }],
                  },
                }))}
                onCancelRation={handleCancelRationConfirm}
              />
            )}
            {activeTab === PAST_APPLIED_RATION && (
              <PastRationsTab
                rations={pastTabContent.map((el) => ({
                  ...el,
                  ration: {
                    ...el.ration,
                    name: 'Hardcoded name',
                    tags: [{
                      name: 'Hardcded tag', color: 'red', id: '1', userAccountIdentityProviderId: '1',
                    }],
                  },
                }))}
                onCancelRation={handleCancelRationConfirm}
              />
            )}
            {activeTab === FUTURE_APPLIED_RATION && (
              <FutureRationsTab
                rations={futureTabContent.map((el) => ({
                  ...el,
                  ration: {
                    ...el.ration,
                    name: 'Hardcoded name',
                    tags: [{
                      name: 'Hardcded tag', color: 'red', id: '1', userAccountIdentityProviderId: '1',
                    }],
                  },
                }))}
                onCancelRation={handleCancelRationConfirm}
              />
            )}
          </>
        )}
      </div>
      {showModal && (
        <ConfirmationModal
          handleClose={handleModalClose}
          handleConfirm={handleCancelRation}
          title={confirmationModalTitle}
          message={confirmationModalMessage}
        />
      )}
    </div>
  );
};

export default AppliedRationList;
