import { FormattedMessage } from 'react-intl';
import './Button.scss';

export enum ButtonType {
  SUBMIT = 'submit',
  BUTTON = 'button',
  RESET = 'reset',
}

interface ButtonProps {
  type: ButtonType
  label: string
  loading?: boolean
  onClick?: () => void
}

export const Button = ({
  type = ButtonType.BUTTON, loading, label, onClick,
}: ButtonProps) => (
  <button type={type} className="button" disabled={loading} onClick={onClick}>
    {loading ? (
      <span className="spinner-border spinner-border-sm" />
    ) : (
      <FormattedMessage id={label} />
    )}
  </button>
);
