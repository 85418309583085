import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import mealService from '../../service/meal/mealService';
import { setMessage } from './messageSlice';
import { convertToErrorMessage } from '../../service/message/messageConverter';
import {
  Meal,
  MealSearchRequestDto, TagsSearchApiResponse,
  Tag, PageableResponseWrapper, MealSearchRequestDtoOptional, Ingredient, MealsApiResponse,
} from '../../common/constant/interface/interfaces';
import filterService from '../../service/filter/filterService';
import { IngredientsApiResponse } from './ingredientSlice';
import { SearchItem } from '../../component/filter/search/SearchFilter';
import mealMapperService from '../../service/mapper/meal/mealMapperService';

interface GetMealApiRequest {
  cursorId: string;
  size: number;
}

export interface IdToName {
  id: string;
  name: string;
}

interface SearchRequestStoreUpdate {
  selectedSearchParam: MealSearchSelectedParam,
  convertedToRequestSearchParam: MealSearchRequestDtoOptional,
}

export interface MealFilterApiResponse {
  prepareTimeMinutesMin: number;
  prepareTimeMinutesMax: number;
  cookTimeMinutesMin: number;
  cookTimeMinutesMax: number;
  difficultyMin: number;
  difficultyMax: number;
  calorieMin: number;
  calorieMax: number;
  proteinMin: number;
  proteinMax: number;
  carboMin: number;
  carboMax: number;
  fatMin: number;
  fatMax: number;
  foodTypes: IdToName[];
}

export interface MealSearchSelectedParam {
  name: string;
  prepareTimeMinutesMin: number;
  prepareTimeMinutesMax: number;
  cookTimeMinutesMin: number;
  cookTimeMinutesMax: number;
  difficultyMin: number;
  difficultyMax: number;
  calorieMin: number;
  calorieMax: number;
  proteinMin: number;
  proteinMax: number;
  carboMin: number;
  carboMax: number;
  fatMin: number;
  fatMax: number;
  ingredients: SearchItem[];
  tags: SearchItem[];
  foodTypeNames: string[];
}

export interface MealState {
  meals: Meal[];
  nextCursorId: string;
  meal?: Meal;
  search: {
    searchResults: Meal[],
    searchNextCursorId: string,
    searchParam: string,
    appliedSearchParam: MealSearchRequestDto,
    selectedSearchParam: MealSearchSelectedParam,
    availableSearchParam : MealFilterApiResponse,
    ingredients: {
      availableIngredients: Ingredient[],
      cursorId: string,
      search: {
        name: string,
        cursorId: string,
        searchResult: Ingredient[]
      },
    },
    tags: {
      availableTags: Tag[],
      cursorId: string,
      search: {
        name: string,
        cursorId: string,
        searchResult: Tag[]
      },
    }
  }
}

export const initialAppliedSearchParamObject: MealSearchRequestDto = {
  name: '',
  prepareTimeMinutesMin: 0,
  prepareTimeMinutesMax: 0,
  cookTimeMinutesMin: 0,
  cookTimeMinutesMax: 0,
  difficultyMin: 0,
  difficultyMax: 0,
  calorieMin: 0,
  calorieMax: 0,
  proteinMin: 0,
  proteinMax: 0,
  carboMin: 0,
  carboMax: 0,
  fatMin: 0,
  fatMax: 0,
  ingredientIds: [],
  tagIds: [],
  foodTypeNames: [],
};

export const initialAvailableFilter: MealFilterApiResponse = {
  calorieMax: 0,
  calorieMin: 0,
  carboMax: 0,
  carboMin: 0,
  cookTimeMinutesMax: 0,
  cookTimeMinutesMin: 0,
  difficultyMax: 0,
  difficultyMin: 0,
  fatMax: 0,
  fatMin: 0,
  foodTypes: [],
  prepareTimeMinutesMax: 0,
  prepareTimeMinutesMin: 0,
  proteinMax: 0,
  proteinMin: 0,
};

export const initialSelectedSearchObject: MealSearchSelectedParam = {
  name: '',
  prepareTimeMinutesMin: 0,
  prepareTimeMinutesMax: 0,
  cookTimeMinutesMin: 0,
  cookTimeMinutesMax: 0,
  difficultyMin: 0,
  difficultyMax: 0,
  calorieMin: 0,
  calorieMax: 0,
  proteinMin: 0,
  proteinMax: 0,
  carboMin: 0,
  carboMax: 0,
  fatMin: 0,
  fatMax: 0,
  ingredients: [],
  tags: [],
  foodTypeNames: [],
};

export const initialState: MealState = {
  meals: [],
  nextCursorId: '',
  search: {
    searchResults: [],
    searchNextCursorId: '',
    searchParam: '',
    appliedSearchParam: initialAppliedSearchParamObject,
    selectedSearchParam: initialSelectedSearchObject,
    availableSearchParam: initialAvailableFilter,
    ingredients: {
      availableIngredients: [],
      cursorId: '',
      search: {
        name: '',
        cursorId: '',
        searchResult: [],
      },
    },
    tags: {
      availableTags: [],
      cursorId: '',
      search: {
        name: '',
        cursorId: '',
        searchResult: [],
      },
    },
  },
};

export const getMeals = createAsyncThunk<MealsApiResponse,
    GetMealApiRequest,
    { rejectValue: string }>('meal/meals', async ({
      cursorId,
      size,
    }, thunkAPI) => {
      try {
        return await mealService.getMeals(cursorId, size);
      } catch (error: any) {
        const { message } = error;
        thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
        return thunkAPI.rejectWithValue(message);
      }
    });

export const getMeal = createAsyncThunk<Meal,
    string,
    { rejectValue: string }>('meal/meal', async (mealId, thunkAPI) => {
      try {
        return await mealService.getMeal(mealId);
      } catch (error: any) {
        const { message } = error;
        thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
        return thunkAPI.rejectWithValue(message);
      }
    });

export const searchMeals = createAsyncThunk<MealsApiResponse,
  {selectedParam: MealSearchSelectedParam, availableParam: MealFilterApiResponse},
  { rejectValue: string }>('meal/search', async ({ selectedParam: selectedSearchParam, availableParam }, thunkAPI) => {
    try {
      const searchRequestDto = mealMapperService
        .mapSelectedSearchObjToMealSearchRequestDto(selectedSearchParam, availableParam);
      // eslint-disable-next-line no-use-before-define,@typescript-eslint/no-use-before-define
      thunkAPI.dispatch(setAppliedSearchObject({
        selectedSearchParam,
        convertedToRequestSearchParam: searchRequestDto,
      }));
      return await mealService.searchMeals(searchRequestDto);
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  });

export const getAvailableFiltersDataToSelect = createAsyncThunk<MealFilterApiResponse,
  void,
  { rejectValue: string }>('meal/filterSearch', async (arg_, thunkAPI) => {
    try {
      return await mealService.getAvailableFilters();
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  });

export const getAvailableIngredientsToSelect = createAsyncThunk<IngredientsApiResponse,
  {cursorId: string, size: number},
  { rejectValue: string }>('meal/filterIngredientGet', async ({ cursorId = '', size }, thunkAPI) => {
    try {
      return await mealService.getAvailableIngredients(cursorId, '', size);
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  });

export const searchAvailableIngredientsToSelect = createAsyncThunk<PageableResponseWrapper<
  IngredientsApiResponse>,
  {name: string, cursorId: string, size: number, overridePrev: boolean},
  { rejectValue: string }>('meal/filterIngredientSearch', async ({
    cursorId = '', size, name, overridePrev,
  }, thunkAPI) => {
    try {
      const response = await mealService.getAvailableIngredients(cursorId, name, size);
      return { response, overrideResult: overridePrev, name };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  });

export const getAvailableTagsToSelect = createAsyncThunk<TagsSearchApiResponse,
  { cursorId: string, size: number},
  { rejectValue: string }>('meal/filterTagsGet', async ({ cursorId = '', size }, thunkAPI) => {
    try {
      return await mealService.getAvailableTags(cursorId, '', size);
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  });

export const searchAvailableTagsToSelect = createAsyncThunk<PageableResponseWrapper<
  TagsSearchApiResponse>,
  { cursorId: string, name:string, size: number, overridePrev: boolean},
  { rejectValue: string }>('meal/filterTagsSearch', async ({
    cursorId = '', name = '', size, overridePrev,
  }, thunkAPI) => {
    try {
      const response = await mealService.getAvailableTags(cursorId, name, size);
      return { response, overrideResult: overridePrev, name };
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(convertToErrorMessage(error)));
      return thunkAPI.rejectWithValue(message);
    }
  });

const mealSlice = createSlice({
  name: 'meal',
  initialState,
  reducers: {
    setAppliedSearchObject: (state, action: PayloadAction<SearchRequestStoreUpdate>) => {
      const searchObj = {
        ...state.search.appliedSearchParam,
        ...action.payload.selectedSearchParam,
      };
      const searchParam = filterService
        .mapObjectToSearchParams(action.payload.convertedToRequestSearchParam);

      state.search.appliedSearchParam = searchObj;
      state.search.searchParam = searchParam;
    },
    clearSelectedSearchParam: (state) => {
      state.search.searchParam = '';
      state.search
        .selectedSearchParam = mealMapperService.mapFilterApiResponseToMealSearchSelectedParam(
          state.search.availableSearchParam,
        );
    },
    setSelectedSearchObject: (state, action: PayloadAction<MealSearchSelectedParam>) => {
      state.search.selectedSearchParam = action.payload;
    },
  },
  extraReducers: (reducerBuilder) => {
    reducerBuilder
      .addCase(getMeals.fulfilled, (state, action) => {
        state.meals = [...state.meals, ...action.payload.content];
        state.nextCursorId = action.payload.nextCursorId;
      })
      .addCase(searchMeals.fulfilled, (state, action) => {
        state.search.searchResults = action.payload.content;
        state.search.searchNextCursorId = action.payload.nextCursorId;
      })
      .addCase(getMeal.fulfilled, (state, action) => {
        state.meal = action.payload;
      })
      .addCase(getAvailableFiltersDataToSelect.fulfilled, (state, action) => {
        state.search.availableSearchParam = action.payload;
        state.search
          .appliedSearchParam = mealMapperService.mapToMealSearchRequestDto(action.payload);
        state.search
          .selectedSearchParam = mealMapperService.mapFilterApiResponseToMealSearchSelectedParam(
            action.payload,
          );
      })
      .addCase(getAvailableIngredientsToSelect.fulfilled, (state, action) => {
        state.search.ingredients.availableIngredients = [
          ...state.search.ingredients.availableIngredients,
          ...action.payload.content,
        ];
        state.search.ingredients.cursorId = action.payload.nextCursorId;
      })
      .addCase(searchAvailableIngredientsToSelect.fulfilled, (state, action) => {
        if (action.payload.overrideResult) {
          state.search.ingredients.search.searchResult = action.payload.response.content;
          // @ts-ignore
          state.search.ingredients.search.cursorId = undefined;
        } else {
          state.search.ingredients.search.searchResult = [
            ...state.search.ingredients.search.searchResult,
            ...action.payload.response.content,
          ];
          state.search.ingredients.search.cursorId = action.payload.response.nextCursorId;
        }
        state.search.ingredients.search.name = action.payload.name;
      })
      .addCase(getAvailableTagsToSelect.fulfilled, (state, action) => {
        state.search.tags.availableTags = [
          ...state.search.tags.availableTags,
          ...action.payload.content,
        ];
        state.search.tags.cursorId = action.payload.nextCursorId;
      })
      .addCase(searchAvailableTagsToSelect.fulfilled, (state, action) => {
        if (action.payload.overrideResult) {
          state.search.tags.search.searchResult = action.payload.response.content;
          state.search.tags.search.cursorId = action.payload.response.nextCursorId;
        } else {
          state.search.tags.search.searchResult = [
            ...state.search.tags.search.searchResult,
            ...action.payload.response.content,
          ];
          state.search.tags.search.cursorId = action.payload.response.nextCursorId;
        }
        state.search.tags.search.name = action.payload.name;
      });
  },
});

const {
  reducer,
  actions,
} = mealSlice;

export const {
  clearSelectedSearchParam,
  setAppliedSearchObject,
  setSelectedSearchObject,
} = actions;

export default reducer;
