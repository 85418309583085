import * as Yup from 'yup';
import languageService from '../../language/languageService';
import { VALIDATION_REGEX } from '../../../common/constant/validationConstant';

export const intlMessage = (key: string) => languageService.getTextValueForCurrentLocale(key);

export const passwordValidationRules = Yup.string()
  .required(intlMessage('validate.error.field.required'))
  .matches(VALIDATION_REGEX.ONE_NUMBER, intlMessage('validate.error.pass.oneNumber'))
  .matches(VALIDATION_REGEX.NO_SPACES_ALLOWED, intlMessage('validate.error.notAllowedInputChar'))
  .matches(VALIDATION_REGEX.NO_DOTS_ALLOWED, intlMessage('validate.error.notAllowedInputChar'))
  .matches(VALIDATION_REGEX.LEAST_ONE_LOWERCASE_EN, intlMessage('validate.error.pass.lowercase'))
  .matches(VALIDATION_REGEX.ONLY_ENGLISH_LETTERS_AND_ALLOWED_SYMBOLS, intlMessage('validate.error.onlyEnglishLetters'))
  .min(8, intlMessage('validate.error.pass.minLength8'));

export const confirmChangePasswordValidRules = Yup.string()
  .required(intlMessage('validate.error.field.required'))
  .oneOf([Yup.ref('newPassword')], intlMessage('validate.error.pass.NotMatchNewAsOld'));

export const confirmPasswordValidRules = Yup.string()
  .required(intlMessage('validate.error.field.required'))
  .oneOf([Yup.ref('password')], intlMessage('validate.error.pass.NotMatchNewAsOld'));

export const userNameValidRules = Yup.string()
  .required(intlMessage('validate.error.field.required'))
  .matches(VALIDATION_REGEX.ONLY_LETTERS_EN_OR_NUMBER, intlMessage('validate.error.field.nameOnlyLetter'))
  .matches(VALIDATION_REGEX.NO_SPACE_INSIDE_TEXT, intlMessage('validate.error.notAllowedSpaceInsideText'))
  .min(3, intlMessage('validate.error.field.nameMinLength3'));

export const notEmptyStringValidRule = Yup.string()
  .required(intlMessage('validate.error.field.required'));
