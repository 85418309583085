import React from 'react';
import './ModalTypeButton.scss';
import { FormattedMessage } from 'react-intl';

interface ModalTypeButtonProps {
  name: string;
  className: string;
  onClick: () => void;
}

const ModalTypeButton: React.FC<ModalTypeButtonProps> = ({ name, className, onClick }) => (
  <button className={`meal-or-product-btn ${className}`} type="button" onClick={onClick}>
    <FormattedMessage id={name} />
  </button>
);

export default ModalTypeButton;
