import { FormattedMessage } from 'react-intl';
import Footer from '../../../component/footer/Footer';
import Header from '../../../component/infoSectionHeader/InfoSectionHeader';
import '../Legal.scss';

const Privacy = () => {
  return (
    <>
      <div className="legal">
        <Header />

        <main className="legal__content">
          <h1>
            <FormattedMessage id="legal.privacy.title" />
          </h1>

          <section className="legal__content_intro">
            <p>
              <FormattedMessage id="legal.privacy.intro" />
              &nbsp;
              <a href="mailto:privacy@mealmate.com">
                <u>privacy@mealmate.com</u>.
              </a>
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.privacy.introduction" />
            </h2>
            <p>
              <FormattedMessage id="legal.privacy.introduction.text1" />
              <strong>
                <FormattedMessage id="legal.privacy.introduction.strong1" />
              </strong>
              <strong>
                <FormattedMessage id="legal.privacy.introduction.strong2" />
              </strong>
              <FormattedMessage id="legal.privacy.introduction.text2" />
              <strong>
                <FormattedMessage id="legal.privacy.introduction.strong3" />
              </strong>
              <FormattedMessage id="legal.privacy.introduction.text3" />
              <strong>
                <FormattedMessage id="legal.privacy.introduction.strong4" />
              </strong>
              <FormattedMessage id="legal.privacy.introduction.text4" />
              <strong>
                <FormattedMessage id="legal.privacy.introduction.strong5" />
              </strong>
              <FormattedMessage id="legal.privacy.introduction.text5" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.introduction.text6" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.introduction.text7" />
            </p>
            <p>
              <strong>
                <FormattedMessage id="legal.privacy.introduction.strong6" />
              </strong>
              <FormattedMessage id="legal.privacy.introduction.text8" />
              <strong>
                <FormattedMessage id="legal.privacy.introduction.strong6" />
              </strong>
              <FormattedMessage id="legal.privacy.introduction.text9" />
              <strong>GDPR</strong>
              <FormattedMessage id="legal.privacy.introduction.text10" />
            </p>
            <p>
              <strong>
                <FormattedMessage id="legal.privacy.introduction.strong7" />
              </strong>
              <FormattedMessage id="legal.privacy.introduction.text11" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.introduction.text12" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.privacy.collect" />
            </h2>
            <p>
              <FormattedMessage id="legal.privacy.collect.text1" />
            </p>

            <ul>
              <li>
                <FormattedMessage id="legal.privacy.collect.li1" />
              </li>
              <li>
                <FormattedMessage id="legal.privacy.collect.li2" />
              </li>
              <li>
                <FormattedMessage id="legal.privacy.collect.li3" />
              </li>
            </ul>
            <p>
              <FormattedMessage id="legal.privacy.collect.text2" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.collect.text3" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.collect.text4" />
              <strong>
                <FormattedMessage id="legal.privacy.collect.strong1" />
              </strong>
              <FormattedMessage id="legal.privacy.collect.text5" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.collect.text6" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.collect.text7" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.collect.text8" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.collect.text9" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.privacy.process" />
            </h2>
            <p>
              <FormattedMessage id="legal.privacy.process.text1" />
            </p>
            <p>
              <strong>
                <FormattedMessage id="legal.privacy.process.strong1" />
              </strong>
              <FormattedMessage id="legal.privacy.process.text2" />
            </p>
            <p>
              <strong>
                <FormattedMessage id="legal.privacy.process.strong2" />
              </strong>
              <FormattedMessage id="legal.privacy.process.text3" />
            </p>
            <p>
              <strong>
                <FormattedMessage id="legal.privacy.process.strong3" />
              </strong>
              <FormattedMessage id="legal.privacy.process.text4" />
            </p>

            <p>
              <strong>
                <FormattedMessage id="legal.privacy.process.strong4" />
              </strong>
              <FormattedMessage id="legal.privacy.process.text5" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.privacy.disclose" />
            </h2>
            <p>
              <FormattedMessage id="legal.privacy.disclose.text1" />
            </p>
            <ul>
              <li>
                <FormattedMessage id="legal.privacy.disclose.li1" />
              </li>
              <li>
                <FormattedMessage id="legal.privacy.disclose.li2" />
              </li>
              <li>
                <FormattedMessage id="legal.privacy.disclose.li3" />
              </li>
            </ul>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.privacy.disclose2" />
            </h2>
            <p>
              <FormattedMessage id="legal.privacy.disclose2.text1" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.disclose2.text2" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.disclose2.text3" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.disclose2.text4" />
            </p>
            <ul>
              <li>
                <FormattedMessage id="legal.privacy.disclose2.li1" />
              </li>
              <li>
                <FormattedMessage id="legal.privacy.disclose2.li2" />
              </li>
              <li>
                <FormattedMessage id="legal.privacy.disclose2.li3" />
              </li>
            </ul>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.privacy.retain" />
            </h2>
            <p>
              <FormattedMessage id="legal.privacy.retain.text1" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.retain.text2" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.retain.text3" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.privacy.rights" />
            </h2>
            <p>
              <FormattedMessage id="legal.privacy.rights.text1" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.rights.text2" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.rights.text3" />
            </p>
            <ul>
              <li>
                <FormattedMessage id="legal.privacy.rights.li1" />
              </li>
              <li>
                <FormattedMessage id="legal.privacy.rights.li2" />
              </li>
              <li>
                <FormattedMessage id="legal.privacy.rights.li3" />
              </li>
              <li>
                <FormattedMessage id="legal.privacy.rights.li4" />
              </li>
              <li>
                <FormattedMessage id="legal.privacy.rights.li5" />
              </li>
              <li>
                <FormattedMessage id="legal.privacy.rights.li6" />
              </li>
              <li>
                <FormattedMessage id="legal.privacy.rights.li7" />
              </li>
              <li>
                <FormattedMessage id="legal.privacy.rights.li8" />
              </li>
            </ul>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.privacy.security" />
            </h2>
            <p>
              <FormattedMessage id="legal.privacy.security.text1" />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.security.text2" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.privacy.children" />
            </h2>
            <p>
              <FormattedMessage id="legal.privacy.children.text1" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.privacy.links" />
            </h2>
            <p>
              <FormattedMessage id="legal.privacy.links.text1" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.privacy.changes" />
            </h2>
            <p>
              <FormattedMessage id="legal.privacy.changes.text1" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.privacy.contact" />
            </h2>
            <p>
              <FormattedMessage id="legal.privacy.contact.text1" />
              <br />
              <FormattedMessage id="legal.privacy.contact.email" />
              <a href="mailto:privacy@mealmate.com">
                <u>privacy@mealmate.com </u>
              </a>
              <br />
              <FormattedMessage id="legal.privacy.contact.name" />
              BID Production (MealMate)
              <br />
              <br />
            </p>
            <p>
              <FormattedMessage id="legal.privacy.contact.text2" />
            </p>
          </section>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
