import React, { useCallback, useEffect, useState } from 'react';
import '../../AddMealsModal.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from '../../../../../../component/spinner/LoadingSpinner';
import { useAppSelector } from '../../../../../../hook/useAppSelector';
import { useAppDispatch } from '../../../../../../hook/useAppDispatch';
import {
  Meal,
  MealTypeEnum,
  Nutrition,
  Unit,
} from '../../../../../../common/constant/interface/interfaces';
import {
  getMeals,
  MyRationItemContent,
  searchMeal,
} from '../../../../../../redux/slice/myRationSlice';
import languageService from '../../../../../../service/language/languageService';
import { calculateNutrition } from '../../../../../../service/myRation/calculateNutrition';
import AddMealItem from './AddMealItem';

export type SelectedMealItemType = Meal & { type: 'MEAL' };

const MEALS_PAGINATION_COUNT_SIZE = 10;

const mapMealToSelectedItem = (
  day: string,
  food: string,
  meal: Meal,
  unit: Unit,
  nutrition: Nutrition,
): MyRationItemContent => ({
  day,
  language: languageService.getCurrentLocale(),
  foodType: food,
  item: {
    mealId: meal.id,
    name: meal.name,
    language: languageService.getCurrentLocale(),
    isEaten: false,
    nutrition,
    unit: {
      id: unit.id,
      name: unit.name,
      value: unit.value,
    },
    type: MealTypeEnum.Meal,
  },
});

interface AddMealItemsProps {
  selectedItems: MyRationItemContent[];
  setSelectedItems: (items: MyRationItemContent[]) => void;
  day: string;
  foodType: string;
}

const AddMealItemList = ({
  selectedItems, setSelectedItems, day, foodType,
}: AddMealItemsProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValueByName, setSearchValueByName] = useState('');

  const allMeals = useAppSelector((state) => state.myRation.meal.allMeal.content);
  const nextCursorId = useAppSelector((state) => state.myRation.meal.allMeal.nextCursorId);
  const searchResultMeal = useAppSelector((state) => state.myRation.meal.searchResult.content);
  const searchNextCursorId = useAppSelector(
    (state) => state.myRation.meal.searchResult.nextCursorId,
  );
  const mealsToDisplay = searchValueByName ? searchResultMeal : allMeals;

  useEffect(() => {
    if (allMeals.length === 0) {
      setIsLoading(true);
      dispatch(
        getMeals({
          cursorId: nextCursorId,
          size: MEALS_PAGINATION_COUNT_SIZE,
        }),
      ).finally(() => setIsLoading(false));
    }
  }, [dispatch, allMeals.length]);

  const debouncedSearch = useCallback(
    debounce(async (searchValueByName: string) => {
      if (searchValueByName === '') return;
      setIsLoading(true);
      try {
        await dispatch(
          searchMeal({
            cursorId: searchNextCursorId,
            size: MEALS_PAGINATION_COUNT_SIZE,
            searchParam: `name=${searchValueByName}`,
          }),
        );
      } finally {
        setIsLoading(false);
      }
    }, 500),
    [dispatch],
  );

  useEffect(() => {
    debouncedSearch(searchValueByName);
  }, [searchValueByName, debouncedSearch]);

  const loadMore = () => {
    if (searchValueByName) {
      dispatch(
        searchMeal({
          cursorId: searchNextCursorId,
          size: MEALS_PAGINATION_COUNT_SIZE,
          searchParam: `name=${searchValueByName}`,
        }),
      );
    } else {
      dispatch(
        getMeals({
          cursorId: nextCursorId,
          size: MEALS_PAGINATION_COUNT_SIZE,
        }),
      );
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValueByName(e.target.value);
  };

  const handleSelectItem = (params: {
    meal: Meal;
    selectedUnitName: string;
    selectedValue: number;
  }) => {
    const { meal, selectedUnitName, selectedValue } = params;
    const { unit } = meal.units.find((el) => el.unit.name === selectedUnitName)!;
    const nutrition: Nutrition = calculateNutrition(
      meal.units[0].nutrition,
      selectedUnitName,
      meal.units[0].unit.value,
      selectedValue,
    );
    const selectedItem = mapMealToSelectedItem(
      day,
      foodType,
      meal,
      {
        ...unit,
        value: Number(selectedValue),
        name: selectedUnitName,
      },
      nutrition,
    );
    const isAlreadySelected = selectedItems.find(
      (el) => el.item.mealId === selectedItem.item.mealId,
    );

    setSelectedItems(
      isAlreadySelected
        ? selectedItems.filter((item) => item.item.mealId !== meal.id)
        : [...selectedItems, selectedItem],
    );
  };

  const updateSelectedItem = (params: {
    meal: Meal;
    selectedUnitName: string;
    selectedValue: number;
  }) => {
    const { meal, selectedUnitName, selectedValue } = params;
    const nutrition: Nutrition = calculateNutrition(
      meal.units[0].nutrition,
      selectedUnitName,
      meal.units[0].unit.value,
      selectedValue,
    );

    const itemIndex = selectedItems.findIndex((item) => item.item.mealId === meal.id);
    if (itemIndex === -1) return;

    const updatedItems = [...selectedItems];
    updatedItems[itemIndex] = {
      ...selectedItems[itemIndex],
      item: {
        ...selectedItems[itemIndex].item,
        unit: {
          ...selectedItems[itemIndex].item.unit,
          name: selectedUnitName,
          value: selectedValue,
        },
        nutrition,
      },
    };

    setSelectedItems(updatedItems);
  };

  const renderedContent = (meals: Meal[]) => {
    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (meals.length === 0) {
      return (
        <div className="meals-not-found">
          <FormattedMessage id="dashboard.modal.button.noItemsFound" />
        </div>
      );
    }

    return meals.map((meal) => {
      const selected = selectedItems.some((item) => item.item.mealId === meal.id);

      return (
        <AddMealItem
          key={meal.id}
          meal={meal}
          selected={selected}
          value={meal.units[0].unit.value}
          unitName={meal.units[0].unit.name}
          onValueChange={updateSelectedItem}
          onUnitChange={updateSelectedItem}
          onSelect={handleSelectItem}
        />
      );
    });
  };

  return (
    <>
      <input
        type="search"
        className="modal-meal-search"
        placeholder="Search"
        name="modal-meal-search"
        id="modal-meal-search"
        autoComplete="off"
        autoCorrect="off"
        value={searchValueByName}
        onChange={handleSearchChange}
      />
      <div className="tab-items">
        <InfiniteScroll
          dataLength={mealsToDisplay.length}
          next={loadMore}
          hasMore={!!(searchValueByName ? searchNextCursorId : nextCursorId)}
          height="350px"
          className="tab-items-infiniteScroll"
          loader={<LoadingSpinner />}
        >
          {renderedContent(mealsToDisplay)}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default AddMealItemList;
