import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line
import { Scrollbar, A11y, Mousewheel, FreeMode } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import './RationsSlider.scss';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { BREAKPOINTS } from '../../../../common/constant/breakpoint/breakpoint';
import { useAppDispatch } from '../../../../hook/useAppDispatch';
import { getRations } from '../../../../redux/slice/rationSlice';
import { useAppSelector } from '../../../../hook/useAppSelector';
import { APP_PATH } from '../../../../common/constant/pathConstant';

const RATIONS_SEARCH_COUNT_SIZE = 10;

const RationsSlider = () => {
  const dispatch = useAppDispatch();
  const rations = useAppSelector((state) => state.ration.rations);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (rations.length) {
      setIsLoading(false);
      return;
    }

    dispatch(getRations({ cursorId: '', size: RATIONS_SEARCH_COUNT_SIZE }))
      .unwrap()
      .then(() => setIsLoading(false))
      .catch((error: any) => {
        console.error('Error get fetching rations:', error);
      });
  }, [dispatch, rations.length]);

  return (
    <div className="r-side__rations rations">
      <div className="rations__titles">
        <h3 className="title-side">
          <FormattedMessage id="dashboard.title.rations" />
        </h3>
        <button type="button">
          <Link to={APP_PATH.APP_RATIONS}>
            <FormattedMessage id="dashboard.button.seeAll" />
          </Link>
        </button>
      </div>
      <div className="rations__slider">
        {isLoading ? (
          <div style={{ width: '100%', height: '150px' }} data-testid="loading-skeleton">
            <div className="skilleton" />
          </div>
        ) : (
          <Swiper
            modules={[Scrollbar, A11y, Mousewheel, FreeMode]}
            spaceBetween={15}
            freeMode
            scrollbar={{ draggable: true }}
            mousewheel
            breakpoints={BREAKPOINTS.RATIONS_SLIDER}
          >
            {rations.map((item) => (
              <SwiperSlide key={item.id}>
                <Link to={`${APP_PATH.APP_RATIONS}/${item.id}`}>
                  <div className="rations__item item-ration">
                    <div className="item-ration__pic">
                      <img src={item.images[0]?.url} alt="ration" />
                    </div>
                    <div className="item-ration__descr ration-descr">
                      <h4 className="ration-descr__name" title={item.name}>{item.name}</h4>
                      <div className="ration-descr__calories">
                        <span>
                          <FormattedMessage id="meal.item.kcal" />
                        </span>
                        <span>{item.nutrition.calorie}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};
export default RationsSlider;
