import React from 'react';
import './ContentWrapper.scss';

interface ContentWrapperProps {
  children: React.ReactNode
}

const ContentWrapper = ({ children }: ContentWrapperProps) => (
  <div className="wrapper" data-testid="content-wrapper">
    <div className="wrapper__content">{children}</div>
  </div>
);
export default ContentWrapper;
