import React, { useEffect, useState } from 'react';
import './UnitCounter.scss';

interface UnitCounterProps {
    initialValue: number | string;
    min: number;
    max: number;
    onChange: (selectedValue: number | string) => void;
}

const UnitCounter: React.FC<UnitCounterProps> = ({
  initialValue, min, max, onChange,
}) => {
  const [value, setValue] = useState<number | string>(initialValue);

  useEffect(() => {
    onChange(value);
  }, [value, setValue]);

  const handleIncrement = () => {
    if (typeof value === 'number' && value < max) {
      setValue(value + 1);
    } else if (value === '') {
      setValue(min);
    }
  };

  const handleDecrement = () => {
    if (typeof value === 'number' && value > min) {
      setValue(value - 1);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === '' ? '' : Number(e.target.value);
    if (newValue === '' || (newValue >= min && newValue <= max)) {
      setValue(newValue);
    }
  };

  return (
    <div className="counter">
      <button
        className="counter-btn"
        onClick={handleDecrement}
      >
        <span className="counter-btn__icon">-</span>
      </button>
      <input
        type="number"
        value={value}
        onChange={handleChange}
        className="counter-input"
      />
      <button className="counter-btn" onClick={handleIncrement}>
        <span className="counter-btn__icon">+</span>
      </button>
    </div>
  );
};

export default UnitCounter;
