import React, { useState } from 'react';
import '../Setting.scss';
import { FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import EditLogo from '../../../../asset/icons/profile/edit-photo.svg';
import UserAvatar from '../../../../component/userAvatar/UserAvatar';
import { MessageType, setMessage } from '../../../../redux/slice/messageSlice';
import { useAppDispatch } from '../../../../hook/useAppDispatch';

interface SettingAvatarProps {
    formikProps: FormikProps<any>
    setSelectedFile: (event: any) => void
}

const imageMimeTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/webp',
  'image/svg+xml',
  'image/tiff',
  'image/x-icon',
  'image/heic',
];

const imageMimeTypesString = imageMimeTypes.join(',');

const SettingAvatar = ({ formikProps, setSelectedFile }: SettingAvatarProps) => {
  const [imagePreview, setImagePreview] = useState<string | null>(formikProps.values.imageUrl);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      const validImageTypes = imageMimeTypes;
      if (!validImageTypes.includes(file.type)) {
        dispatch(setMessage({
          type: MessageType.ERROR,
          text: `${intl.messages['upload-picture.error']}`,
        }));
        return;
      }

      setSelectedFile(file);
      formikProps.setValues({ ...formikProps.values, imageUrl: file });

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  return (
    <div className="profile-block">
      <UserAvatar
        name={formikProps.values.name}
        srcImage={imagePreview || formikProps.values.imageUrl}
        className="profile-image"
      />
      <div className="profile-edit">
        <label htmlFor="fileInput">
          <img src={EditLogo} alt="Edit" />
          <input
            data-testid="fileInput"
            type="file"
            id="fileInput"
            name="fileInput"
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept={imageMimeTypesString}
          />
        </label>
      </div>
    </div>
  );
};

export default SettingAvatar;
