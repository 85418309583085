import apiService from '../api/apiService';
import { getApiBaseUrl } from '../../config/config';
import {
  BACKEND_API_PATH,
  BACKEND_COMMON_PARAMS,
  BACKEND_RATIONS_PARAMS,
} from '../../common/constant/pathConstant';
import {
  CollectedDataToPostApplyRation,
  GetRationByDayResponse,
  RationApiResponse,
  RationFilterApiResponse,
} from '../../redux/slice/rationSlice';
import languageService from '../language/languageService';
import {
  ChangeReview,
  MealsApiResponse,
  Ration,
  RationSearchRequestDtoOptional,
  Review,
  SendReview,
  TagsSearchApiResponse,
} from '../../common/constant/interface/interfaces';
import { IngredientsApiResponse } from '../../redux/slice/ingredientSlice';
import ingredientService from '../ingredient/ingredientService';
import filterService from '../filter/filterService';
import mealService from '../meal/mealService';

async function getRations(cursorId: string, size: number): Promise<RationApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.RATIONS}/${BACKEND_API_PATH.SEARCH}?${
      BACKEND_RATIONS_PARAMS.CURSOR_ID
    }=${cursorId}&${BACKEND_RATIONS_PARAMS.SIZE}=${size}&${
      BACKEND_COMMON_PARAMS.LANGUAGE
    }=${languageService.getCurrentLocale()}`,
  );
}

async function searchRations(
  searchRequest: RationSearchRequestDtoOptional,
): Promise<RationApiResponse> {
  const searchParams = filterService.mapObjectToSearchParams(searchRequest);
  const baseUrl = `${getApiBaseUrl()}/${BACKEND_API_PATH.RATIONS}/${BACKEND_API_PATH.SEARCH}`;
  const languageParam = `language=${languageService.getCurrentLocale()}`;

  const url = searchParams
    ? `${baseUrl}?${searchParams}&${languageParam}`
    : `${baseUrl}?${languageParam}`;

  return apiService.get(url);
}

async function getRationContentById(day: number, rationId: string): Promise<Ration> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.RATIONS}/${rationId}?${
      BACKEND_RATIONS_PARAMS.DAY_NUMBER
    }=${day}&${BACKEND_COMMON_PARAMS.LANGUAGE}=${languageService.getCurrentLocale()}`,
  );
}

async function getRationContentByDay(
  day: number,
  rationId: string,
): Promise<GetRationByDayResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.RATIONS}/${rationId}?${
      BACKEND_RATIONS_PARAMS.DAY_NUMBER
    }=${day}&${BACKEND_COMMON_PARAMS.LANGUAGE}=${languageService.getCurrentLocale()}&${
      BACKEND_COMMON_PARAMS.INCLUDE
    }=${BACKEND_RATIONS_PARAMS.FOOD_TYPES}`,
  );
}

async function applyRation(
  rationId: string,
  collectedDataToPost: CollectedDataToPostApplyRation,
): Promise<string> {
  return apiService.post(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.RATIONS}/${rationId}/${BACKEND_RATIONS_PARAMS.APPLY}`,
    collectedDataToPost,
  );
}

async function getAvailableFilters(): Promise<RationFilterApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.RATIONS}/${BACKEND_API_PATH.SEARCH}/filter-data`,
  );
}

async function getAvailableTags(
  cursorId: string,
  name: string,
  size: number,
): Promise<TagsSearchApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.RATIONS}/tags/${
      BACKEND_API_PATH.SEARCH
    }?cursorId=${cursorId}&name=${name}&size=${size}&language=${languageService.getCurrentLocale()}`,
  );
}

async function getAvailableIngredients(
  cursorId: string,
  name: string,
  size: number,
): Promise<IngredientsApiResponse> {
  return ingredientService.searchIngredients(name, cursorId, size);
}

async function getAvailableMeals(
  cursorId: string,
  name: string,
  size: number,
): Promise<MealsApiResponse> {
  return mealService.searchMeals({ name }, cursorId, size);
}

async function getRationReviews(rationId: string, page: number, size: number) {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.RATIONS}/${rationId}/reviews?page=${page}&size=${size}`,
  );
}

async function sendRationReview(rationId: string, reviewItem: SendReview): Promise<Review> {
  return apiService.post(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.RATIONS}/${rationId}/reviews`,
    reviewItem,
  );
}

async function deleteRationReview(rationId: string, reviewId: string) {
  return apiService.deleted(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.RATIONS}/${rationId}/reviews/${reviewId}`,
  );
}

async function updateRationReview(
  rationId: string,
  reviewId: string,
  reviewItem: ChangeReview,
): Promise<Review> {
  return apiService.put(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.RATIONS}/${rationId}/reviews/${reviewId}`,
    reviewItem,
  );
}

const rationService = {
  getRations,
  searchRations,
  getRationContentById,
  getRationContentByDay,
  applyRation,
  getAvailableMeals,
  getAvailableTags,
  getAvailableIngredients,
  getAvailableFilters,
  getRationReviews,
  sendRationReview,
  deleteRationReview,
  updateRationReview,
};

export default rationService;
