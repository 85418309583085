import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import ProteinIcon from '../../asset/icons/protein.svg';
import CaloriesIcon from '../../asset/icons/calories.svg';
import FatIcon from '../../asset/icons/fat.svg';
import CarboIcon from '../../asset/icons/carbo.svg';
import ModalWindow from '../../component/layout/modalWindow/ModalWindow';
import AddMealItemsToMyRation from '../../component/addMealItemsToMyRation/AddMealItemsToMyRation';
import { Ingredient, MealTypeEnum } from '../../common/constant/interface/interfaces';

export interface IngredientInfoProps {
    ingredient: Ingredient;
}

const IngredientInfo = ({
  ingredient,
}: IngredientInfoProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      {isModalOpen && (
        <ModalWindow handleCloseModal={() => setIsModalOpen(false)}>
          <AddMealItemsToMyRation
            item={ingredient}
            itemType={MealTypeEnum.Ingredient}
          />
        </ModalWindow>
      )}
      <div className="ingredient__info">
        <h2 className="ingredient__title">{ingredient.name}</h2>
        <div className="ingredient__content">
          <img className="ingredient__image" src={ingredient.images?.[0]?.url} alt={ingredient.name} />
          <div className="ingredient__nutrition-content">
            <div className="ingredient__nutrition-container">
              <div className="ingredient__nutrition-item">
                <span className="ingredient__nutrition-name"><FormattedMessage id="protein" /></span>
                <div className="ingredient__nutrition-value">
                  <img
                    className="ingredient__nutrition-icon"
                    src={ProteinIcon}
                    alt="chicken"
                  />
                  <span>{ingredient.units?.[0]?.nutrition?.protein}</span>
                </div>
              </div>
              <div className="ingredient__nutrition-item">
                <span className="ingredient__nutrition-name"><FormattedMessage id="calories" /></span>
                <div className="ingredient__nutrition-value">
                  <img
                    className="ingredient__nutrition-icon"
                    src={CaloriesIcon}
                    alt="fire"
                  />
                  <span>{ingredient.units?.[0]?.nutrition?.calorie}</span>
                </div>
              </div>
              <div className="ingredient__nutrition-item">
                <span className="ingredient__nutrition-name"><FormattedMessage id="fats" /></span>
                <div className="ingredient__nutrition-value">
                  <img
                    className="ingredient__nutrition-icon"
                    src={FatIcon}
                    alt="meat"
                  />
                  <span>{ingredient.units?.[0]?.nutrition?.fat}</span>
                </div>
              </div>
              <div className="ingredient__nutrition-item">
                <span className="ingredient__nutrition-name"><FormattedMessage id="carbo" /></span>
                <div className="ingredient__nutrition-value">
                  <img
                    className="ingredient__nutrition-icon"
                    src={CarboIcon}
                    alt="rice"
                  />
                  <span>{ingredient.units?.[0]?.nutrition?.carbo}</span>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="ingredient__button add-meal animated-gradient-btn add-meal--pc"
              onClick={() => setIsModalOpen(true)}
            >
              <FormattedMessage id="ingredient.add.button" />
            </button>
          </div>
        </div>
        <p className="ingredient__description">{ingredient.description || ''}</p>
        <button
          type="button"
          className="ingredient__button-mobile add-meal animated-gradient-btn add-meal--pc"
        >
          <FormattedMessage id="ingredient.add.button" />
        </button>
      </div>
    </>
  );
};

export default IngredientInfo;
