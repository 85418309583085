import apiService from '../api/apiService';
import { FILE_TYPE } from '../../common/enum/enum';
import { getApiBaseUrl } from '../../config/config';

const IMAGE_VERSION_HEADER_KEY = 'x-amz-version-id';

export async function uploadImage(url: string, file: File): Promise<string> {
  const res = await apiService.putFile(url, file);

  return res.headers[IMAGE_VERSION_HEADER_KEY];
}

export async function generatePreSignedUrl(fileType: FILE_TYPE): Promise<string> {
  const response = await apiService.post(`${getApiBaseUrl()}/storage/upload/pre-signed-url`, {
    fileType,
  });
  return response.url;
}

export async function buildS3ImageUrlWithVersion(presignedUrl: string, imageVersion: string) {
  const url: URL = new URL(presignedUrl.split('?')[0]);
  url.searchParams.append('versionId', imageVersion);

  return url.toString();
}

const s3Service = {
  uploadImage,
  generatePreSignedUrl,
  buildS3ImageUrlWithVersion,
};

export default s3Service;
