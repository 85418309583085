import React, { useEffect, useRef, useState } from 'react';
import './MyRationOptions.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import ConfirmationModal from '../../../../../component/confirmationModal/ConfirmationModal';
import { useAppDispatch } from '../../../../../hook/useAppDispatch';
import { clearMyRationForDay } from '../../../../../redux/slice/myRationSlice';

interface MyRationOptionsProps {
  selectedDate: Date;
}

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const MyRationOptions = ({ selectedDate }: MyRationOptionsProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const confirmationModalTitle = intl.formatMessage({ id: 'confirmation.title' });
  const confirmationModaMessage = intl.formatMessage({ id: 'confirmation.message' });

  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  const handleClearRationDay = () => {
    setShowDropdown(false);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleApply = () => {
    const day = formatDate(selectedDate);
    dispatch(clearMyRationForDay(day));
    setShowModal(false);
  };

  return (
    <div className="options-wrapper" ref={dropdownRef}>
      <button className="dropdown-button" onClick={() => setShowDropdown(!showDropdown)}>
        {[...Array(3)].map((_, index) => (
          <span key={index} className="dot" />
        ))}
      </button>
      {showDropdown && (
        <ul className="dropdown-menu">
          <li className="dropdown-menu__item">
            <button className="dropdown-menu__ration-clear-btn" onClick={handleClearRationDay}>
              <FormattedMessage id="options.clear.RationDay" />
            </button>
          </li>
        </ul>
      )}
      {showModal && (
        <ConfirmationModal
          handleClose={handleModalClose}
          handleConfirm={handleApply}
          title={confirmationModalTitle}
          message={confirmationModaMessage}
        />
      )}
    </div>
  );
};

export default MyRationOptions;
