import React, { ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import './VerificationCodeInput.scss';

interface VerificationCodeInputProps {
  verificationCode: string[];
  setVerificationCode: (code: string[]) => void;
}

const SINGLE_DIGIT_REGEX = /^\d?$/;

const VerificationCodeInput = ({
  verificationCode,
  setVerificationCode,
}: VerificationCodeInputProps) => {
  const handleInputChange = (
    index: number,
    { target: { value } }: ChangeEvent<HTMLInputElement>,
  ) => {
    if (SINGLE_DIGIT_REGEX.test(value)) {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);

      const nextInput = document.getElementById(`input-${index + 1}`) as HTMLInputElement;
      if (nextInput && value) {
        nextInput.focus();
      }
    }
  };

  const handleInputKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !verificationCode[index]) {
      const prevInput = document.getElementById(`input-${index - 1}`) as HTMLInputElement;
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handleInputFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  return (
    <div className='verification-inputs'>
      {verificationCode.map((value, index) => (
        <input
          key={index} // NOSONAR
          type='text'
          data-testid={`input-${index}`}
          id={`input-${index}`}
          value={value}
          onChange={(e) => handleInputChange(index, e)}
          onKeyDown={(e) => handleInputKeyDown(index, e)}
          onFocus={handleInputFocus}
          maxLength={1}
          className='verification-input'
        />
      ))}
    </div>
  );
};

export default VerificationCodeInput;
