import * as Yup from 'yup';
import { intlMessage } from '../../rule/validationRules';

export const updateUserInfoSchema = Yup.object().shape({
  name: Yup.string()
    .required(intlMessage('validate.error.field.required'))
    .min(3, intlMessage('validate.error.field.nameMinLength3')),
  birthdayDate: Yup.date()
    .required(intlMessage('validate.error.field.required'))
    .min(
      new Date(new Date().getFullYear() - 100, 0, 1),
      intlMessage('validate.error.birthday.realistic'),
    )
    .max(
      new Date(new Date().getFullYear() - 6, 11, 31),
      intlMessage('validate.error.birthday.realistic'),
    ),
  height: Yup.number()
    .min(50, intlMessage('validate.error.height.least50cm'))
    .max(300, intlMessage('validate.error.height.notExceed300cm'))
    .required(intlMessage('validate.error.field.required')),
  weight: Yup.number()
    .min(10, intlMessage('validate.error.weight.least10kg'))
    .max(645, intlMessage('validate.error.weight.notExceed645kg'))
    .required(intlMessage('validate.error.field.required')),
  weightGoal: Yup.number()
    .min(10, intlMessage('validate.error.weight.least10kg'))
    .max(645, intlMessage('validate.error.weight.notExceed645kg'))
    .required(intlMessage('validate.error.field.required')),
});
