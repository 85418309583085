import apiService from '../api/apiService';
import { getApiBaseUrl } from '../../config/config';
import { BACKEND_API_PATH, BACKEND_CALCULATE_PARAMS } from '../../common/constant/pathConstant';
import { CalculatorFormData } from '../../redux/slice/calculatorSlice';
import {
  CalculationResult,
  InitCalculationDataApiResponse,
} from '../../common/constant/interface/interfaces';

async function getInitData(): Promise<InitCalculationDataApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.CALCULATOR}/${BACKEND_CALCULATE_PARAMS.INIT_DATA}`,
  );
}

async function getCalculatedData(formData: CalculatorFormData): Promise<CalculationResult> {
  return apiService.post(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.CALCULATOR}/${BACKEND_CALCULATE_PARAMS.CALCULATE}`,
    formData,
  );
}

const calculatorService = {
  getInitData,
  getCalculatedData,
};

export default calculatorService;
