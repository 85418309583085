import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { format, isValid, parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import languageService from '../../../../service/language/languageService';
import './AddRationCalendar.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { generateDates } from './generateDates';

interface AddRationCalendarProps {
    setSelectedDatesStrings: (day: string[]) => void;
    occupiedDates: string[];
    durationDay: number;
    setSelectedMonth: (month: number) => void;
    setRepeatCount: (count: number) => void;
    repeatCount: number;
    isCurrentDateLessThanTarget: boolean
}

export const DATE_FORMAT_OPTIONS = languageService.getDateFormatBasedOnLocale();
export const DATE_PICKER_FORMAT = 'yyyy-MM-dd';

const AddRationCalendar: React.FC<AddRationCalendarProps> = ({
  occupiedDates,
  durationDay,
  setSelectedDatesStrings,
  setSelectedMonth,
  repeatCount,
  setRepeatCount,
  isCurrentDateLessThanTarget,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [hoveredDates, setHoveredDates] = useState<Date[]>([]);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const isDateOccupied = (date: Date) => occupiedDates.some((occupiedDateString) => {
    const occupiedDate = parseISO(occupiedDateString);
    return (
      occupiedDate.getFullYear() === date.getFullYear()
            && occupiedDate.getMonth() === date.getMonth()
            && occupiedDate.getDate() === date.getDate()
    );
  });

  const isDateSelected = (date: Date) => selectedDates
    .some((selectedDate) => selectedDate.getFullYear() === date.getFullYear()
            && selectedDate.getMonth() === date.getMonth()
            && selectedDate.getDate() === date.getDate());

  const isDateHovered = (date: Date) => hoveredDates
    .some((hoveredDate) => hoveredDate.getFullYear() === date.getFullYear()
            && hoveredDate.getMonth() === date.getMonth()
            && hoveredDate.getDate() === date.getDate());

  const updateSelectedDates = (startDate: Date, days: number, repeat: number) => {
    const generatedDates = generateDates(startDate, days, repeat);
    setSelectedDates(generatedDates);
    setSelectedDatesStrings(generatedDates.map((date) => format(date, DATE_PICKER_FORMAT)));

    const overlap = generatedDates.some(isDateOccupied);
    setShowWarning(overlap);
  };

  const handleMouseEnter = (date: Date) => {
    const generatedHoveredDates = generateDates(date, durationDay, repeatCount);
    setHoveredDates(generatedHoveredDates);
  };

  const handleRepeatChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newRepeatCount = Number(event.target.value);
    setRepeatCount(newRepeatCount);
    if (selectedDate) {
      updateSelectedDates(selectedDate, durationDay, newRepeatCount);
    }
  };

  const handleDateChange = (date: Date) => {
    if (isValid(date)) {
      setSelectedDate(date);
      updateSelectedDates(date, durationDay, repeatCount);
    }
  };

  const handleMonthChange = (date: Date) => {
    setSelectedMonth(date.getMonth() + 1);
  };

  const renderDayContents = (day: number, date: Date) => {
    const isOccupied = isDateOccupied(date);
    const isSelected = isDateSelected(date);
    const isHovered = isDateHovered(date);
    const isStart = date.getTime() === selectedDates[0]?.getTime();
    const isEnd = date.getTime() === selectedDates[selectedDates.length - 1]?.getTime();
    const isFirstHovered = isHovered && date.getTime() === hoveredDates[0]?.getTime();
    const isLastHovered = isHovered
            && date.getTime() === hoveredDates[hoveredDates.length - 1]?.getTime();

    return (
      <div
        data-testid={`day-${day}`}
        className={`day-container ${isStart ? 'selected-start' : ''} ${
          isEnd ? 'selected-end' : ''} ${isFirstHovered ? 'hovered-start' : ''} ${
          isLastHovered ? 'hovered-end' : ''}`}
        onMouseEnter={() => handleMouseEnter(date)}
        onMouseLeave={() => setHoveredDates([])}
      >
        {isOccupied && <div className="occupied">{day}</div>}
        {(isSelected || isHovered) && (
        <div className={`selected ${
          isHovered ? 'hovered' : ''}`}
        >
          {day}
        </div>
        )}
        <div className="day-item">{day}</div>
      </div>
    );
  };

  useEffect(() => {
    if (selectedDate) {
      updateSelectedDates(selectedDate, durationDay, repeatCount);
    }
  }, [selectedDate, durationDay, repeatCount]);

  useEffect(() => {
    setSelectedMonth(new Date().getMonth() + 1);
  }, [setSelectedMonth]);

  return (
    <div className="calendar" data-testid="datepicker">
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        onMonthChange={handleMonthChange}
        renderDayContents={renderDayContents}
        inline
        id="RationDay"
        placeholderText={`${format(new Date(), 'dd MMM yyyy')}`}
        locale={DATE_FORMAT_OPTIONS}
        dateFormat={DATE_PICKER_FORMAT}
      />
      {showWarning && (
        <div className="warning">
          <FormattedMessage id="ration.modal.warning" />
        </div>
      )}
      {isCurrentDateLessThanTarget && (
        <div className="warning">
          <FormattedMessage id="ration.modal.error.cannotSelect" />
        </div>
      )}
      <div className="add-ration-window__repeat-select">
        <p>
          <FormattedMessage id="ration.modal.repeatDiet" />
          {' '}
        </p>
        <select onChange={handleRepeatChange} value={repeatCount}>
          <option value={1}><FormattedMessage id="ration.modal.1-time" /></option>
          <option value={2}><FormattedMessage id="ration.modal.2-times" /></option>
          <option value={3}><FormattedMessage id="ration.modal.3-times" /></option>
          <option value={5}><FormattedMessage id="ration.modal.5-times" /></option>
        </select>
      </div>
    </div>
  );
};

export default AddRationCalendar;
