import { FormikProps } from 'formik';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../hook/useAppSelector';
import { UserProfile } from '../../../../../service/user/userService';
import FieldItem from '../../../../../component/form/formField/typesField/FieldItem';

interface ProfileFormProps {
  formikProps: FormikProps<any>
}

const ProfileForm = ({ formikProps }: ProfileFormProps) => {
  const user: UserProfile = useAppSelector((state) => state.user);
  const currentUser = formikProps.values;
  const profileFields = [
    {
      label: 'form.label.name',
      type: 'text',
      value: formikProps.values.name,
      onChange: (value: string) => formikProps.setValues({ ...currentUser, name: value }),
      isEdit: true,
      title: formikProps.values.name,
    },
    {
      label: 'form.label.username',
      value: formikProps.values.username,
      isEdit: false,
      title: formikProps.values.username,
    },
    {
      label: 'form.label.email',
      value: formikProps.values.email,
      isEdit: false,
      title: formikProps.values.email,
    },
  ];

  useEffect(() => {
    formikProps.setValues(user);
  }, [user, formikProps.setValues]);

  return (
    <div className="profile-form">
      <h2 className="form-title">
        <FormattedMessage id="profile.header" />
      </h2>
      {profileFields.map((field) => (
        <FieldItem
          data-testid={field.label}
          className="form-group"
          key={field.label}
          type={field.type}
          label={field.label}
          value={field.value}
          onChange={field.onChange}
          isEdit={field.isEdit}
          title={field.title}
        />
      ))}
    </div>
  );
};

export default ProfileForm;
