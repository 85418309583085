import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import ProteinIcon from '../../asset/icons/protein.svg';
import CaloriesIcon from '../../asset/icons/calories.svg';
import FatsIcon from '../../asset/icons/fat.svg';
import CarboIcon from '../../asset/icons/carbo.svg';
import './Calculator.scss';
import CalculatorForm from './CalculatorForm';
import GradientButton from '../../component/button/GradientButton';
import {
  CalculatorFormData,
  calulateSuggestedNutrition,
} from '../../redux/slice/calculatorSlice';
import { RootState } from '../../redux';
import { useAppDispatch } from '../../hook/useAppDispatch';

const Calculator = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const resultRef = useRef<HTMLDivElement>(null);

  const calculationResult = useSelector((state: RootState) => state.calculator.calculationResult);

  const scrollTo = (element: HTMLElement) => {
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = async (formData: CalculatorFormData) => {
    try {
      await dispatch(calulateSuggestedNutrition(formData)).unwrap();
    } catch (error) {
      console.error('Error calculating data:', error);
    } finally {
      if (resultRef.current) {
        scrollTo(resultRef.current);
      }
    }
  };

  const formatMacroData = (macroType: 'protein' | 'fat' | 'carbo') => {
    const distribution = calculationResult?.distribution?.[macroType];
    if (!distribution) return '-';

    const {
      calories, ratio, macroWeight, macroWeightPerBodyWeightRatio,
    } = distribution;
    const percentage = `${ratio}%`;
    const macroWeightGrams = `${macroWeight}${intl.formatMessage({ id: 'calculate.unit.gram' })}.`;
    const macroWeightPerBodyWeight = `${macroWeightPerBodyWeightRatio} ${intl.formatMessage({ id: 'calculate.unit.gram_per_kg' })}`;
    const caloriesUnit = `${calories} ${intl.formatMessage({ id: 'calculate.unit.kcal' })}`;

    return `${caloriesUnit} (${percentage}) = ${macroWeightGrams} (${macroWeightPerBodyWeight})`;
  };

  return (
    <div className="profile-window calculate">
      <div className="calculate__top">
        <h2 className="calculate__title">
          <FormattedMessage id="calculate.button" />
        </h2>
        <CalculatorForm onSubmit={handleSubmit} />
      </div>
      {calculationResult && (
        <>
          <hr />
          <div className="calculate__bottom result" id="result" ref={resultRef}>
            <h2 className="calculate__title calculate__title--result">
              <FormattedMessage id="calculate.result.title" />
            </h2>
            <div className="result__info info-result">
              <div className="info-result__calories">
                <img src={CaloriesIcon} alt="Calories" />
                <span id="calories-result">{calculationResult.caloriesGoal}</span>
                <FormattedMessage id="calculate.unit.kcal" />
              </div>
              <div className="nutritions">
                <div className="nutritions__protein">
                  <span className="nutritions__grey">
                    <img src={ProteinIcon} alt="Proteins" />
                    <FormattedMessage id="meal.item.proteins" />
                    <span>: </span>
                  </span>
                  <span className="nutritions__result" id="protein-result">{formatMacroData('protein')}</span>
                </div>
                <div className="nutritions__fats">
                  <span className="nutritions__grey">
                    <img src={FatsIcon} alt="Fats" />
                    <FormattedMessage id="meal.item.fats" />
                    <span>: </span>
                  </span>
                  <span className="nutritions__result" id="fat-result">{formatMacroData('fat')}</span>
                </div>
                <div className="nutritions__carbo">
                  <span className="nutritions__grey">
                    <img src={CarboIcon} alt="Carbohydrates" />
                    <FormattedMessage id="calculate.placeholder.carbs" />
                    <span>: </span>
                  </span>
                  <span className="nutritions__result" id="carbo-result">{formatMacroData('carbo')}</span>
                </div>
              </div>
            </div>
            <div className="result__buttons">
              <GradientButton messageId="calculate.button.search" styleClass="animated-gradient-btn--search" />
              <GradientButton messageId="calculate.button.apply" styleClass="animated-gradient-btn--gray" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Calculator;
