import React, { useState } from 'react';
import './ExpandableBlock.scss';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ArrowUp } from '../../asset/icons/dashboard/arrow-up.svg';

interface ExpandableBlockProps {
  title: string;
  content: React.ReactNode;
  initiallyExpanded?: boolean;
}

const ExpandableBlock: React.FC<ExpandableBlockProps> = ({
  title,
  content,
  initiallyExpanded = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="expandable-block">
      <div className="expandable-block__header">
        <h4 className="expandable-block__title">
          <FormattedMessage id={title} />
        </h4>
        <button
          type="button"
          onClick={toggleExpand}
          className={`expand-btn ${isExpanded ? 'expanded' : ''}`}
        >
          <ArrowUp />
        </button>
      </div>
      <div className={`content ${isExpanded ? 'expanded' : ''}`}>{content}</div>
    </div>
  );
};

export default ExpandableBlock;
