import '../../../style/scss/style.scss';
import '../Registration.scss';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RegistrationStepProps } from '../Registration';
import { useAppDispatch } from '../../../hook/useAppDispatch';
import { setMessage } from '../../../redux/slice/messageSlice';
import { confirmUser } from '../../../redux/slice/authSlice';
import { convertToErrorMessage } from '../../../service/message/messageConverter';
import validationService from '../../../service/validation/validationService';

function RegistrationStep2({
  onNextStep,
  formikProps,
  currentStep,
}: Readonly<RegistrationStepProps>) {
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmationCode, setConfirmationCode] = useState<string>('');
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  const dispatch = useAppDispatch();
  const intl = useIntl();
  let timer: NodeJS.Timeout;

  useEffect(() => {
    if (showConfirmationMessage) {
      timer = setTimeout(() => setShowConfirmationMessage(false), 3000);
    }

    return () => clearTimeout(timer);
  }, [showConfirmationMessage]);

  const handleValidateAndSubmit = async () => {
    formikProps.values.confirmationCode = confirmationCode;
    try {
      await validationService.validateRegistrationForm(formikProps, currentStep, []);
      setLoading(true);
      dispatch(confirmUser(formikProps.values))
        .unwrap()
        .then(() => {
          setLoading(false);
          setShowConfirmationMessage(true);
          timer = setTimeout(() => onNextStep(), 1500);
        })
        .catch((error: any) => {
          dispatch(setMessage(convertToErrorMessage(error)));
          setLoading(false);
        });
    } catch (error: any) {
      dispatch(setMessage(convertToErrorMessage(error)));
      setLoading(false);
    }
  };

  return (
    <div className="window registration-window registration-window--confirm">
      <h2 className="window__title registration-window__title">
        <FormattedMessage id="form.label.sent" />
      </h2>
      <p className="window__subtitle">
        <FormattedMessage id="form.label.check.desc" />
      </p>
      <div className="window__form form-window form-window--registartion">
        {showConfirmationMessage ? (
          <p className="window__subtitle--confirm">
            <FormattedMessage id="email.confirm" />
          </p>
        ) : (
          <input
            className="form-window__username"
            type="text"
            disabled={!!showConfirmationMessage}
            placeholder={`${intl.messages['form.label.confirmationCode']}`}
            onChange={(e) => setConfirmationCode(e.target.value)}
            value={confirmationCode}
          />
        )}
        <button
          type="button"
          onClick={handleValidateAndSubmit}
          className="form-window__btn window-btn"
          disabled={loading}
        >
          {loading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            <FormattedMessage id="confirm.button" />
          )}
        </button>
      </div>
    </div>
  );
}

export default RegistrationStep2;
