// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Gilroy-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Gilroy-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Gilroy-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Gilroy-Bold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Gilroy", sans-serif;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy", sans-serif;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/asset/font/fonts.scss"],"names":[],"mappings":"AAAA;EACG,iCAAA;EACA,oHACG;EAEH,kBAAA;EACA,gBAAA;EACA,kBAAA;AADH;AAIA;EACG,iCAAA;EACA,oHACG;EAEH,kBAAA;EACA,gBAAA;EACA,kBAAA;AAJH","sourcesContent":["@font-face {\n   font-family: \"Gilroy\", sans-serif;\n   src:\n      url('./Gilroy-Regular.woff2') format('woff2'),\n      url('./Gilroy-Regular.woff') format('woff');\n   font-display: swap;\n   font-weight: 400;\n   font-style: normal;\n}\n\n@font-face {\n   font-family: \"Gilroy\", sans-serif;\n   src:\n      url('./Gilroy-Bold.woff2') format('woff2'),\n      url('./Gilroy-Bold.woff') format('woff');\n   font-display: swap;\n   font-weight: 700;\n   font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
