import * as Yup from 'yup';
import {
  confirmPasswordValidRules,
  intlMessage, notEmptyStringValidRule,
  passwordValidationRules,
  userNameValidRules,
} from '../../rule/validationRules';

const commonValidationSchema = {
  username: userNameValidRules,
  email: Yup.string()
    .required(intlMessage('validate.error.field.required'))
    .email(intlMessage('validate.error.email.invalid')),
  password: passwordValidationRules,
  confirmPassword: confirmPasswordValidRules,
};

const stepSchema = [
  {
    // Step 1
    ...commonValidationSchema,
  },
  {
    // Step 2
    confirmationCode: Yup.string().required(intlMessage('validate.error.field.required')),
  },
  {
    // Step 3
    name: Yup.string()
      .required(intlMessage('validate.error.field.required'))
      .min(2, intlMessage('validate.error.field.nameMinLength2')),
  },
  {
    // Step 4
    birthdayDate: Yup.date()
      .required(intlMessage('validate.error.field.required'))
      .min(
        new Date(new Date().getFullYear() - 100, 0, 1),
        intlMessage('validate.error.birthday.realistic'),
      )
      .max(
        new Date(new Date().getFullYear() - 6, 11, 31),
        intlMessage('validate.error.birthday.realistic'),
      ),
    heightUnit: Yup.string().required(intlMessage('validate.error.field.required')),
    height: Yup.number()
      .min(50, intlMessage('validate.error.height.least50cm'))
      .max(300, intlMessage('validate.error.height.notExceed300cm'))
      .required(intlMessage('validate.error.field.required')),
  },
  {
    // Step 5
    weightUnit: Yup.string().required(intlMessage('validate.error.field.required')),
    weight: Yup.number()
      .min(10, intlMessage('validate.error.weight.least10kg'))
      .max(645, intlMessage('validate.error.weight.notExceed645kg'))
      .required(intlMessage('validate.error.field.required')),
    weightGoal: Yup.number()
      .min(10, intlMessage('validate.error.weight.least10kg'))
      .max(645, intlMessage('validate.error.weight.notExceed645kg'))
      .required(intlMessage('validate.error.field.required')),
  },
  {
    // Step 6
    activityLevel: notEmptyStringValidRule,
  },
];

export const REGISTRATION_FORM_CONF = { abortEarly: false };
// @ts-ignore
export const registrationValidationSchemas = stepSchema.map((schema) => Yup.object().shape(schema));
