import { Nutrition } from '../../common/constant/interface/interfaces';

export const calculateNutrition = (
  initialNutrition: Nutrition,
  selectedUnitName: string,
  initialValue: number,
  selectedValue: number,
): Nutrition => {
  let conversionFactor = 1;

  switch (selectedUnitName) {
    case 'GRAM':
      conversionFactor = selectedValue / initialValue;
      break;
    case 'MILLILITER':
      conversionFactor = selectedValue / initialValue;
      break;
    case 'TEASPOON':
      conversionFactor = (selectedValue * 5) / initialValue;
      break;
    case 'POUND':
      conversionFactor = (selectedValue * 453.592) / initialValue;
      break;
    case 'PIECE':
      conversionFactor = (selectedValue * initialValue) / initialValue;
      break;
    default:
      break;
  }

  return {
    calorie: initialNutrition.calorie * conversionFactor,
    carbo: initialNutrition.carbo * conversionFactor,
    fat: initialNutrition.fat * conversionFactor,
    protein: initialNutrition.protein * conversionFactor,
  };
};
