import apiService from '../api/apiService';
import { getApiBaseUrl } from '../../config/config';
import {
  BACKEND_API_PATH,
  BACKEND_COMMON_PARAMS,
  BACKEND_MEALS_PARAMS,
} from '../../common/constant/pathConstant';
import {
  MealFilterApiResponse,
} from '../../redux/slice/mealSlice';
import {
  Meal, MealsApiResponse, MealSearchRequestDtoOptional,
  TagsSearchApiResponse,
} from '../../common/constant/interface/interfaces';
import languageService from '../language/languageService';
import filterService from '../filter/filterService';
import ingredientService from '../ingredient/ingredientService';
import { IngredientsApiResponse } from '../../redux/slice/ingredientSlice';

async function getMeals(cursorId: string, size: number): Promise<MealsApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.MEALS}/${BACKEND_API_PATH.SEARCH}?${
      BACKEND_MEALS_PARAMS.CURSOR_ID
    }=${cursorId}&${BACKEND_MEALS_PARAMS.SIZE}=${size}&${
      BACKEND_COMMON_PARAMS.LANGUAGE}=${languageService.getCurrentLocale()}`,
  );
}

async function getMeal(mealId: string): Promise<Meal> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.MEALS}/${mealId}?${
      BACKEND_COMMON_PARAMS.LANGUAGE}=${languageService.getCurrentLocale()}`,
  );
}

async function searchMeals(
  searchRequest: MealSearchRequestDtoOptional,
  cursorId: string = '',
  size: number = 20,
): Promise<MealsApiResponse> {
  const searchParams = filterService.mapObjectToSearchParams(searchRequest);
  const baseUrl = `${getApiBaseUrl()}/${BACKEND_API_PATH.MEALS}/${BACKEND_API_PATH.SEARCH}`;
  const languageParam = `${BACKEND_COMMON_PARAMS.LANGUAGE}=${languageService.getCurrentLocale()}`;
  const cursorParam = `cursorId=${cursorId}`;
  const sizeParam = `size=${size}`;

  const url = searchParams
    ? `${baseUrl}?${searchParams}&${languageParam}&${cursorParam}&${sizeParam}`
    : `${baseUrl}?${languageParam}&${cursorParam}&${sizeParam}`;

  return apiService.get(url);
}

async function getAvailableFilters(): Promise<MealFilterApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.MEALS}/${
      BACKEND_API_PATH.SEARCH}/${BACKEND_API_PATH.FILTER}`,
  );
}

async function getAvailableTags(
  cursorId: string,
  name: string,
  size: number,
): Promise<TagsSearchApiResponse> {
  return apiService.get(
    `${getApiBaseUrl()}/${BACKEND_API_PATH.MEALS}/tags/${
      BACKEND_API_PATH.SEARCH}?cursorId=${cursorId}&name=${name}&size=${size}&language=${languageService.getCurrentLocale()}`,
  );
}

async function getAvailableIngredients(
  cursorId: string,
  name: string,
  size: number,
): Promise<IngredientsApiResponse> {
  return ingredientService.searchIngredients(name, cursorId, size);
}

const mealService = {
  getMeals,
  getMeal,
  searchMeals,
  getAvailableFilters,
  getAvailableTags,
  getAvailableIngredients,
};

export default mealService;
