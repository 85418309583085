import { FormattedMessage } from 'react-intl';
import Footer from '../../../component/footer/Footer';
import Header from '../../../component/infoSectionHeader/InfoSectionHeader';
import '../Legal.scss';

const Terms = () => {
  return (
    <>
      <div className="legal">
        <Header />

        <main className="legal__content">
          <h1>
            <FormattedMessage id="legal.terms.title" />
          </h1>

          <section>
            <p>
              <FormattedMessage id="legal.terms.text1" />
            </p>
            <p>
              <FormattedMessage id="legal.terms.text2" />
            </p>
            <p>
              <FormattedMessage id="legal.terms.text3" />
              <a href="mailto:contact@mealmate.com">
                <strong>contact@mealmate.com</strong>
              </a>
              <FormattedMessage id="legal.terms.text4" />
            </p>
            <p>
              <FormattedMessage id="legal.terms.text5" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.eligibility" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.eligibility.text1" />
            </p>
            <p>
              <FormattedMessage id="legal.terms.eligibility.text2" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.terms" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.terms.text1" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.fees" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.fees.text1" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.password" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.password.text1" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.members" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.members.text1" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.rights" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.rights.text1" />
            </p>
            <p>
              <FormattedMessage id="legal.terms.rights.text2" />
            </p>
            <p>
              <FormattedMessage id="legal.terms.rights.text3" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.content" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.content.text1" />
            </p>
            <p>
              <FormattedMessage id="legal.terms.content.text2" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.permission" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.permission.text1" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.prohibited" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.prohibited.text1" />
            </p>
            <ul>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li1" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li2" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li3" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li4" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li5" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li6" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li7" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li8" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li9" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li10" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li11" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li12" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li13" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li14" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li15" />
              </li>
            </ul>
            <p>
              <FormattedMessage id="legal.terms.prohibited.text2" />
            </p>
            <ul>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li16" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li17" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li18" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li19" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li20" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li21" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li22" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li23" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li24" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li25" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li26" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li27" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li28" />
              </li>
              <li>
                <FormattedMessage id="legal.terms.prohibited.li29" />
              </li>
            </ul>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.copyright" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.copyright.text1" />
              <a href="mailto:copyrightagent@mealmate.com">copyrightagent@mealmate.com</a>.
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.dispute" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.dispute.text1" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.privacy" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.privacy.text1" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.health" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.health.text1" />
            </p>
            <p>
              <FormattedMessage id="legal.terms.health.text2" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.3rdparty" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.3rdparty.text1" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.limit" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.limit.text1" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.law" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.law.text1" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.indemnity" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.indemnity.text1" />
            </p>
          </section>

          <section>
            <h2>
              <FormattedMessage id="legal.terms.other" />
            </h2>
            <p>
              <FormattedMessage id="legal.terms.other.text1" />
            </p>
            <p>
              <FormattedMessage id="legal.terms.other.text2" />
              <a href="mailto:contact@mealmate.com">
                <strong>contact@mealmate.com</strong>
              </a>
              <FormattedMessage id="legal.terms.other.text3" />
              <br />
              <FormattedMessage id="legal.terms.other.text4" />
            </p>
          </section>
        </main>
      </div>

      <Footer />
    </>
  );
};

export default Terms;
