import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import ProteinIcon from '../../../../asset/icons/protein.svg';
import CaloriesIcon from '../../../../asset/icons/calories.svg';
import FatIcon from '../../../../asset/icons/fat.svg';
import CarboIcon from '../../../../asset/icons/carbo.svg';
import Tags from '../../../../component/tag/TagList';
import { Tag } from '../../../../common/constant/interface/interfaces';

interface RationItemProps {
  image: string;
  title: string;
  duration: number;
  tags: Tag[];
  kcal: number;
  proteins: number;
  fats: number;
  carbohydrates: number;
  rationId: string
}

const mockedTags: Tag[] = [
  {
    id: '1', name: 'Fruits', color: '#61DAFB', userAccountIdentityProviderId: 'provider1',
  },
  {
    id: '2', name: 'Vegetables', color: '#007ACC', userAccountIdentityProviderId: 'provider2',
  },
];

const RationItem = ({
  rationId,
  image,
  title,
  duration,
  tags,
  kcal,
  proteins,
  fats,
  carbohydrates,
}: RationItemProps) => {
  const navigate = useNavigate();

  const handleReadMore = () => {
    navigate(`${rationId}`);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleReadMore();
    }
  };

  return (
    <button
      key={rationId}
      className="ration__item"
      data-testid="ration-item"
      onClick={handleReadMore}
      onKeyPress={handleKeyPress}
      tabIndex={0}
    >
      <div className="ration__item-image">
        <img src={image} alt="ration" />
      </div>
      <div className="ration__item-container">
        <div className="ration__item-header">
          <div className="ration__item-title">
            <h3>{title}</h3>
            <Tags tags={mockedTags} />
          </div>
          <div className="ration__item-duration duration">
            <p className='duration__value'>
              {duration}
              <FormattedMessage id='ration.duration.days' />
            </p>
            <p>
              <FormattedMessage id='ration.duration.title' />
            </p>
          </div>
        </div>
        <div className="ration__item-content">
          <div className="ration__item-value-block">
            <span className="ration__item-text-small">
              <FormattedMessage id="meal.item.kcal" />
            </span>
            <span className="ration__item-text-bold">
              <img src={CaloriesIcon} alt="Calories" />
              {kcal}
            </span>
          </div>
          <div className="ration__item-value-block">
            <span className="ration__item-text-small">
              <FormattedMessage id="meal.item.proteins" />
            </span>
            <span className="ration__item-text-bold">
              <img src={ProteinIcon} alt="Proteins" />
              {proteins}
            </span>
          </div>
          <div className="ration__item-value-block">
            <span className="ration__item-text-small">
              <FormattedMessage id="meal.item.fats" />
            </span>
            <span className="ration__item-text-bold">
              <img src={FatIcon} alt="Fats" />
              {fats}
            </span>
          </div>
          <div className="ration__item-value-block">
            <span className="ration__item-text-small">
              <FormattedMessage id="calculate.placeholder.carbs" />
            </span>
            <span className="ration__item-text-bold">
              <img src={CarboIcon} alt="Carbohydrates" />
              {carbohydrates}
            </span>
          </div>
        </div>
      </div>
    </button>
  );
};

export default RationItem;
