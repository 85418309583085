import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum MessageType {
  INFO = 'INFO',
  WARN = 'WARNING',
  ERROR = 'ERROR',
}

export interface Message {
  type: MessageType | null
  text: any
}

export interface MessageState {
  message: Message
}

const initialState: MessageState = {
  message: { type: null, text: '' },
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<Message>) => ({ message: action.payload }),
    clearMessage: () => initialState,
  },
});

const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage } = actions;

export default reducer;
