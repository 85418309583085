import React, { useState, useEffect, useRef } from 'react';
import './CalendarDatePicker.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, addDays, subDays } from 'date-fns';
import { ReactComponent as DatePrev } from '../../../../asset/icons/dashboard/arrow-left.svg';
import { ReactComponent as DateNext } from '../../../../asset/icons/dashboard/arrow-right.svg';
import languageService from '../../../../service/language/languageService';

export const DATE_FORMAT = 'd MMMM, yyyy';
export const DATE_FORMAT_OPTIONS = { locale: languageService.getDateFormatBasedOnLocale() };
export const DATE_PICKER_FORMAT = 'yyyy-MM-dd';

interface CalendarDatePickerProps {
  selectedDate: Date
  setSelectedDate: (day: Date) => void
  getMyRationOnDay: (day: Date) => void
}

const CalendarDatePicker = ({
  getMyRationOnDay,
  setSelectedDate,
  selectedDate,
}: CalendarDatePickerProps) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const dateContainerRef = useRef<HTMLDivElement>(null);

  const handlePrevDay = () => {
    setSelectedDate(subDays(selectedDate, 1));
  };

  const handleNextDay = () => {
    setSelectedDate(addDays(selectedDate, 1));
  };

  const handleChangeDatePicker = (date: Date | null) => {
    if (date) {
      setSelectedDate(new Date(format(date, DATE_PICKER_FORMAT)));
      setIsDatePickerOpen(false);
    }
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen((prev) => !prev);
  };

  useEffect(() => {
    getMyRationOnDay(selectedDate);
  }, [selectedDate, setSelectedDate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dateContainerRef.current && !dateContainerRef.current.contains(event.target as Node)) {
        setIsDatePickerOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="date" ref={dateContainerRef}>
      <button className="date__prev date-arrows" type="button" data-testid="btn-date-prev" onClick={handlePrevDay}>
        <DatePrev />
      </button>
      <div className="date__data">
        <button data-testid="btnDateData" type="button" className="date-button" onClick={toggleDatePicker}>
          {format(selectedDate, DATE_FORMAT, DATE_FORMAT_OPTIONS)}
        </button>
        {isDatePickerOpen && (
          <DatePicker
            selected={selectedDate}
            onChange={(date) => handleChangeDatePicker(date)}
            dateFormat={DATE_PICKER_FORMAT}
            id="RationDay"
            placeholderText={`${format(new Date(), 'dd MMM yyyy')}`}
            inline
            locale={DATE_FORMAT_OPTIONS.locale}
          />
        )}
      </div>
      <button className="date__next date-arrows" type="button" data-testid="btn-date-next" onClick={handleNextDay}>
        <DateNext />
      </button>
    </div>
  );
};

export default CalendarDatePicker;
