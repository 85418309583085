import React, { useEffect, useRef, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage } from 'react-intl';
import Search from '../../../component/search/Search';
import MealItem from './component/MealItem';

import './MealList.scss';
import Filter from '../../../asset/icons/filter.svg';

import { useAppDispatch } from '../../../hook/useAppDispatch';
import {
  getAvailableFiltersDataToSelect,
  getMeals,
  initialAvailableFilter as initialMealFilters,
} from '../../../redux/slice/mealSlice';
import { useAppSelector } from '../../../hook/useAppSelector';

import LoadingSpinner from '../../../component/spinner/LoadingSpinner';
import { BACKEND_MEALS_PARAMS } from '../../../common/constant/pathConstant';
import MealListFilter from './filter/MealListFilter';
import { searchMeal } from '../../../redux/slice/myRationSlice';

const MEALS_SEARCH_COUNT_SIZE = 10;

const MealList = () => {
  const dispatch = useAppDispatch();
  const filterRef = useRef<typeof MealListFilter | null>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>(BACKEND_MEALS_PARAMS.NAME || '');

  const meals = useAppSelector((state) => state.meal.meals);
  const nextCursorId = useAppSelector((state) => state.meal.nextCursorId);
  const searchMealsResult = useAppSelector((state) => state.meal.search.searchResults);
  const nextSearchMealsCursorId = useAppSelector((state) => state.meal.search.searchNextCursorId);
  const availableSearchObj = useAppSelector((state) => state.meal.search.availableSearchParam);

  useEffect(() => {
    if (meals.length) return;
    dispatch(
      getMeals({
        cursorId: '',
        size: MEALS_SEARCH_COUNT_SIZE,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (availableSearchObj === initialMealFilters) {
      dispatch(getAvailableFiltersDataToSelect());
    }
  }, []);

  const loadMore = () => {
    if (showSearchResult) {
      if (!nextSearchMealsCursorId) return;
      dispatch(
        searchMeal({
          cursorId: nextSearchMealsCursorId,
          size: MEALS_SEARCH_COUNT_SIZE,
          searchParam: `name=${searchQuery}`,
        }),
      );
    } else {
      if (!nextCursorId) return;
      dispatch(
        getMeals({
          cursorId: nextCursorId,
          size: MEALS_SEARCH_COUNT_SIZE,
        }),
      );
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    // @ts-ignore
    filterRef.current?.handleNameSearch(query);
  };

  return (
    <section className="meal">
      <div className={`meal__left-container ${isFilterOpen ? 'with-filter-open' : ''}`}>
        <div className="meal__search">
          <Search
            searchQueryKey={BACKEND_MEALS_PARAMS.NAME}
            onSearch={handleSearch}
          />
          <button
            type="button"
            className="meal__filters-button animated-gradient-btn"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            aria-label="meal-filter-button"
            data-testid="meal-filter-button"
          >
            <img src={Filter} alt="filter" />
            <FormattedMessage id="meal.list.filters" />
          </button>
        </div>
        <div className="meal__list" data-testid="meal-list">
          <InfiniteScroll
            className='meal__infinite-scroll'
            dataLength={showSearchResult ? searchMealsResult.length : meals.length}
            hasMore={
              showSearchResult ? nextSearchMealsCursorId !== undefined : nextCursorId !== undefined
            }
            next={loadMore}
            loader={<LoadingSpinner />}
          >
            {(showSearchResult ? searchMealsResult : meals).map((meal) => (
              <MealItem
                prepTime={meal.prepareTimeMinutes}
                cookTime={meal.cookTimeMinutes}
                weight={meal.units[0].unit.value}
                key={meal.id}
                image={meal.images[0]?.url}
                title={meal.name}
                tags={meal.tags}
                description={meal.description}
                kcal={meal.units[0].nutrition!.calorie}
                proteins={meal.units[0].nutrition!.protein}
                fats={meal.units[0].nutrition!.fat}
                carbohydrates={meal.units[0].nutrition!.carbo}
                mealId={meal.id}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
      <div className={`meal__right-container ${isFilterOpen && 'open'}`}>
        <div
          className={`meal__right-top-container ${isFilterOpen && 'open'}`}
          data-testid="meal-top-container"
        >
          <MealListFilter
            setShowSearchResult={setShowSearchResult}
            setIsFilterOpen={setIsFilterOpen}
            onFilterClick={() => setIsFilterOpen(!isFilterOpen)}
            ref={filterRef}
          />
        </div>
      </div>
    </section>
  );
};

export default MealList;
