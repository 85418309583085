import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Registration, { STEP_FOR_USER_EMAIL_CONFIRM } from '../../registration/Registration';
import authService from '../../../service/auth/authService';

function LoginEmailNotConfirmed() {
  const location = useLocation();

  useEffect(() => {
    authService.resendConfirmationCode(location.state.username);
  }, [location.state.username]);

  return (
    <Registration
      formValues={{ username: location.state.username, password: location.state.password }}
      startRegistrationStep={STEP_FOR_USER_EMAIL_CONFIRM}
    />
  );
}

export default LoginEmailNotConfirmed;
