import { FormattedMessage } from 'react-intl';
import './ScaleBar.scss';

interface ScaleBarProps {
  readonly difficultyLevel: number;
}

const MIN_SCALE_VALUE = 1;
const MAX_SCALE_VALUE = 5;

function ScaleBar({ difficultyLevel }: ScaleBarProps) {
  const spans = [];
  for (let i = MIN_SCALE_VALUE; i <= MAX_SCALE_VALUE; i += 1) {
    spans.push(<span key={i} className={`difficulty__bar-span ${i <= difficultyLevel ? 'active' : ''}`} />);
  }

  return (
    <div className="difficulty">
      <h4 className="difficulty__title">
        <FormattedMessage id="meal.difficulty.level" />
      </h4>
      <div className="difficulty__bar">
        {spans}
      </div>
      <div className="difficulty__value">{difficultyLevel}</div>
    </div>
  );
}

export default ScaleBar;
