export interface Meal {
    cookTimeMinutes: number
    createdAt: string
    description: string
    difficulty: number
    foodType: Types[]
    id: string
    images: Image[]
    ingredients: Ingredients[]
    isApproved: boolean
    isPrivate: boolean
    language: string
    name: string
    prepareTimeMinutes: number
    tags: Tag[]
    units: Units[]
    userAccount: UserAccount
}

export interface MealsApiResponse {
  content: Meal[];
  nextCursorId: string;
}

export interface MealSearchRequestDto {
  name: string;
  prepareTimeMinutesMin: number;
  prepareTimeMinutesMax: number;
  cookTimeMinutesMin: number;
  cookTimeMinutesMax: number;
  difficultyMin: number;
  difficultyMax: number;
  calorieMin: number;
  calorieMax: number;
  proteinMin: number;
  proteinMax: number;
  carboMin: number;
  carboMax: number;
  fatMin: number;
  fatMax: number;
  ingredientIds: string[];
  tagIds: string[];
  foodTypeNames: string[];
}

export interface MealSearchRequestDtoOptional {
  name?: string;
  prepareTimeMinutesMin?: number;
  prepareTimeMinutesMax?: number;
  cookTimeMinutesMin?: number;
  cookTimeMinutesMax?: number;
  difficultyMin?: number;
  difficultyMax?: number;
  calorieMin?: number;
  calorieMax?: number;
  proteinMin?: number;
  proteinMax?: number;
  carboMin?: number;
  carboMax?: number;
  fatMin?: number;
  fatMax?: number;
  ingredientIds?: string[];
  tagIds?: string[];
  foodTypeNames?: string[];
  size?: number
}

export interface RationSearchRequestDto {
  name: string;
  calorieMin: number;
  calorieMax: number;
  proteinMin: number;
  proteinMax: number;
  carboMin: number;
  carboMax: number;
  fatMin: number;
  fatMax: number;
  durationDaysMin: number;
  durationDaysMax: number;
  ingredientIds: string[];
  tagIds: string[];
  mealIds: string[]
}

export interface RationSearchRequestDtoOptional {
  name?: string;
  calorieMin?: number;
  calorieMax?: number;
  proteinMin?: number;
  proteinMax?: number;
  carboMin?: number;
  carboMax?: number;
  fatMin?: number;
  fatMax?: number;
  durationDaysMin?: number;
  durationDaysMax?: number;
  ingredientIds?: string[];
  tagIds?: string[];
  mealIds?: string[]
}

export interface Ration {
    id: string,
    name: string,
    description: string,
    language: string,
    durationDays: number,
    images: Image[],
    considerations: Considerations[],
    foodTypes: FoodType[],
    nutrition: Nutrition,
    userAccount: UserAccount,
    tags: Tag[]
}

export interface Considerations {
    id: string,
    type: string,
    description: string,
    language: string,
    sequenceNumber: number,
}

export interface MyRation {
    foodTypes: FoodType[]
    id: string
    nutrition: Nutrition
    nutritionEaten: Nutrition
}

export interface Image {
    id: string
    url: string
    sequenceNumber: number
}

export interface Nutrition {
    calorie: number
    protein: number
    carbo: number
    fat: number
}

export interface FoodType {
    items: ItemFoodType[]
    name: FoodTypeEnum
    nutrition: Nutrition
    nutritionEaten: Nutrition
}

export interface ItemFoodType {
    id: string
    images: Image[]
    ingredientId?: string
    mealId?: string
    isEaten: boolean
    language: string
    name: string
    nutrition: Nutrition
    type: string
    unit: Unit
}

export interface Units {
    nutrition: Nutrition;
    unit: Unit
}

export interface Unit {
  id: string
  name: string
  value: number
  nutrition?: Nutrition
}

export interface UserAccount {
    identityProviderId: string
    name: string
    imageUrl: string
}

export interface Ingredients {
    id: string
    ingredient: {
        id: string
        name: string
        group: {
            id: string
            parentId: string
            name: string
            level: number
        }
        images: Image[]
    }
    unit: Unit
    nutrition: Nutrition
}

export interface Ingredient {
    id: string;
    userAccountIdentityProviderId: string;
    name: string;
    description: string;
    language: string;
    group: IngredientGroup;
    isApproved: boolean;
    isPrivate: boolean;
    isExcluded: boolean;
    images: Image[];
    units: {
        unit: IngredientUnit;
        nutrition: Nutrition;
    }[];
}

export interface IngredientGroup {
    id: string;
    parentId: string;
    name: string;
    language: string;
    level: number;
    ingredients?: Ingredient[];
}

export interface Types {
    id: string
    name: FoodTypeEnum
}

export interface Tag {
    id: string
    userAccountIdentityProviderId: string
    name: string
    color: string
}

export interface TagsSearchApiResponse {
  nextCursorId: string;
  content: Tag[];
}

export interface PageableResponseWrapper<T> {
  response: T;
  overrideResult: boolean;
  name:string;
}

export interface CalculatorNutrition {
  proteinRatio: number;
  carboRatio: number;
  fatRatio: number;
}

export interface Goal {
  caloriesAdjustment: number;
}

export interface CalculatorUserAccount {
  gender: Gender;
  age: number;
  height: number;
  heightUnit: HeightUnits;
  weight: number;
  weightUnit: WeightUnits;
  activityLevel: string;
}

export interface InitCalculationDataApiResponse {
  formulas: string[];
  activityLevels: {
    NOT_ACTIVE: CalculatorNutrition;
    LOW_ACTIVE: CalculatorNutrition;
    MODERATE_ACTIVE: CalculatorNutrition;
    VERY_ACTIVE: CalculatorNutrition;
    EXTREMELY_ACTIVE: CalculatorNutrition;
  };
  goals: {
    WEIGHT_LOSS: Goal;
    WEIGHT_GAIN: Goal;
    MAINTAIN_WEIGHT: Goal;
  };
  userAccount: CalculatorUserAccount;
}

export interface MacroDistribution {
  calories: number;
  ratio: number;
  macroWeight: number;
  macroWeightUnit: string;
  macroWeightPerBodyWeightRatio: number;
  bodyWeightUnit: string;
}

export interface Distribution {
  protein: MacroDistribution;
  carbo: MacroDistribution;
  fat: MacroDistribution;
}

export interface CalculationResult {
  formula: string;
  basalMetabolicRateCalories: number;
  caloriesGoal: number;
  distribution: Distribution;
}

export interface Review {
  id: string;
  text: string;
  rating: number;
  language: string;
  rationId: string;
  userAccountId: string;
  createdAt: string;
}

export interface SendReview {
  rationId: string;
  text: string;
  language: string;
  rating: number;
}

export interface ChangeReview {
  rationId: string;
  reviewId: string;
  text: string;
  language: string;
  rating: number;
}

export type Gender = 'MALE' | 'FEMALE';
export type HeightUnits = 'CENTIMETER' | 'INCH';
export type WeightUnits = 'KILOGRAM' | 'POUND';

export interface IngredientUnit {
    id: string;
    name: 'GRAM' | 'MILLILITER' | 'TEASPOON' | 'POUND' | 'PIECE';
    value: number;
}

export enum MealTypeEnum {
    Meal = 'MEAL',
    Ingredient = 'INGREDIENT',
}

export enum FoodTypeEnum {
    Breakfast = 'BREAKFAST',
    Lunch = 'LUNCH',
    Dinner = 'DINNER',
    Snacks = 'SNACKS'
}
