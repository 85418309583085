import { FormattedMessage } from 'react-intl';
import MealGenerate from '../../../../asset/icons/dashboard/meal-generate.svg';

interface GenerateRationProps {
    setShowGenerateRation: (toggle: boolean) => void
}

const GenerateRation = ({ setShowGenerateRation }: GenerateRationProps) => (
  <section className="ration-meals-generate">
    <p className="ration-meals-generate__descr">
      <FormattedMessage id="dashboard.title.generateRation" />
    </p>
    <picture className="ration-meals-generate__pic">
      <img src={MealGenerate} alt="Meals" />
    </picture>
    <div className='ration-meals-generate__buttons'>
      <button type="button" className="ration-meals-generate__button animated-gradient">
        <FormattedMessage id="dashboard.button.generateRation" />
      </button>
      <button
        type="button"
        className="ration-meals-generate__button btn-create animated-gradient"
        onClick={() => setShowGenerateRation(true)}
      >
        <FormattedMessage id="dashboard.button.createRation" />
      </button>
    </div>
  </section>
);

export default GenerateRation;
