import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import '../../Dashboard.scss';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import MyRationModalItem from './MyRationModalItem';
import { ReactComponent as ArrowUp } from '../../../../asset/icons/dashboard/arrow-down.svg';
import { ReactComponent as AddMeal } from '../../../../asset/icons/dashboard/plus.svg';
import {
  FoodType,
  ItemFoodType,
  MealTypeEnum,
  Nutrition,
} from '../../../../common/constant/interface/interfaces';
import ModalWindow from '../../../../component/layout/modalWindow/ModalWindow';
import AddMealsModal from '../addMealsModal/AddMealsModal';
import { useAppSelector } from '../../../../hook/useAppSelector';
import { DATE_PICKER_FORMAT } from '../calendar/CalendarDatePicker';
import { MyRationResponse } from '../../../../redux/slice/myRationSlice';

interface MyRationModalMealsProps {
  name: string;
  time: string;
  nutrition: Nutrition;
  items: ItemFoodType[];
  selectedDate: string;
  type: string;
  addMealToRation?: (foodType?: string) => void | null;
  itemId?: string;
}

const findFoodTypeInMyRation = (
  myRations: MyRationResponse[],
  formatSelectedDate: string,
  type: string,
) => {
  const findRationOnDay = myRations.find((item) => item.day === formatSelectedDate);
  return findRationOnDay?.myRation.foodTypes.find((item) => item.name === type);
};

export const checkItemIdExists = (
  currentFoodTypesItems: FoodType | undefined,
  itemId: string | undefined,
) => currentFoodTypesItems?.items.some((item) => {
  if (item.type === MealTypeEnum.Ingredient) {
    return item.ingredientId === itemId;
  }
  if (item.type === MealTypeEnum.Meal) {
    return item.mealId === itemId;
  }
  return false;
});

const MyRationModalMeals = ({
  name,
  time,
  nutrition,
  items,
  selectedDate,
  type,
  addMealToRation,
  itemId,
}: MyRationModalMealsProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dishesRef = useRef<HTMLDivElement>(null);
  const formatSelectedDate = useMemo(
    () => format(new Date(selectedDate), DATE_PICKER_FORMAT),
    [selectedDate],
  );
  const myRations = useAppSelector((store) => store.myRation.myRations);

  const currentFoodTypesItems = useMemo(
    () => findFoodTypeInMyRation(myRations, formatSelectedDate, type),
    [myRations, formatSelectedDate, type],
  );
  const itemIdExists = useMemo(
    () => checkItemIdExists(currentFoodTypesItems, itemId),
    [currentFoodTypesItems, itemId],
  );

  useEffect(() => {
    if (!isExpanded && dishesRef.current) {
      dishesRef.current.style.maxHeight = '0';
    } else if (dishesRef.current) {
      dishesRef.current.style.maxHeight = `${dishesRef.current.scrollHeight}px`;
    }
  }, [isExpanded, items]);

  const onClickAddBtn = () => {
    if (addMealToRation) {
      addMealToRation(type);
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {isModalOpen && (
        <ModalWindow handleCloseModal={() => setIsModalOpen(false)}>
          <AddMealsModal
            selectedDate={selectedDate}
            foodType={type}
            handleCloseModal={() => setIsModalOpen(false)}
          />
        </ModalWindow>
      )}
      <div className="ration-meals-item">
        <div className="ration-meals-item__header">
          <div className="ration-meals-nameAndTime">
            <div className="ration-meals-nameAndTime__name">
              <FormattedMessage id={name} />
            </div>
            <div className="ration-meals-nameAndTime__time">{time}</div>
          </div>
          <div className="button-group">
            {addMealToRation && !isExpanded && !itemIdExists ? (
              <button
                data-testid="btnAddDish"
                type="button"
                className={`btnAddDish animated-gradient ${isExpanded ? 'expanded' : ''}`}
                onClick={onClickAddBtn}
              >
                <AddMeal />
              </button>
            ) : null}
            <button
              type="button"
              data-testid="btnArrowUp"
              onClick={() => setIsExpanded(!isExpanded)}
              className={`expand-btn ${isExpanded ? 'expanded' : ''}`}
            >
              <ArrowUp />
            </button>
          </div>
        </div>
        <div ref={dishesRef} className={`dishes ${isExpanded ? 'expanded' : ''}`}>
          {items.map((meal) => (
            <MyRationModalItem key={meal.id} meal={meal} selectedDate={selectedDate} />
          ))}
          {!itemIdExists && (
            <button
              data-testid="btnAddDish"
              type="button"
              className="add-dish-btn animated-gradient"
              onClick={addMealToRation ? () => addMealToRation(type) : () => setIsModalOpen(true)}
            >
              <AddMeal />
            </button>
          )}
          <div className="macronutrients">
            <ul className="macronutrients__list">
              <li className="macronutrients__item">
                <span className="macronutrients__name">
                  <FormattedMessage id="dashboard.title.kcal" />
                </span>
                <span className="macronutrients__value" data-testid="Kcal">
                  {nutrition.calorie}
                </span>
              </li>
              <li className="macronutrients__item">
                <span className="macronutrients__name">
                  <FormattedMessage id="dashboard.title.proteins" />
                </span>
                <span className="macronutrients__value" data-testid="Proteins">
                  {nutrition.protein}
                </span>
              </li>
              <li className="macronutrients__item">
                <span className="macronutrients__name">
                  <FormattedMessage id="dashboard.title.fats" />
                </span>
                <span className="macronutrients__value" data-testid="Fats">
                  {nutrition.fat}
                </span>
              </li>
              <li className="macronutrients__item">
                <span className="macronutrients__name">
                  <FormattedMessage id="dashboard.title.carbohydrates" />
                </span>
                <span className="macronutrients__value" data-testid="Carbohydrates">
                  {nutrition.carbo}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyRationModalMeals;
