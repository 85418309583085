import * as Yup from 'yup';
import {
  confirmChangePasswordValidRules,
  notEmptyStringValidRule,
  passwordValidationRules,
} from '../../rule/validationRules';

export const changePasswordSchema = Yup.object().shape({
  oldPassword: notEmptyStringValidRule,
  newPassword: passwordValidationRules,
  confirmPassword: confirmChangePasswordValidRules,
});
