import axios, { AxiosRequestConfig } from 'axios';
import authService from '../auth/authService';
import { HTTP_REQUEST_TYPE } from '../../common/enum/enum';

const getHeaders = (
  requestType: HTTP_REQUEST_TYPE,
  accessToken?: string,
  isTokenRequired: boolean = true,
): AxiosRequestConfig['headers'] => {
  const headers: AxiosRequestConfig['headers'] = {
    'Content-Type': requestType,
  };
  if (isTokenRequired) {
    headers.Authorization = `Bearer ${accessToken ?? authService.getUserToken()}`;
  }

  return headers;
};

export const getImgHeaders = (): AxiosRequestConfig['headers'] => ({
  'Content-Type': 'image/jpg',
  'x-amz-acl': 'public-read',
});

export function get(
  path: string,
  accessToken?: string,
  requestType: HTTP_REQUEST_TYPE = HTTP_REQUEST_TYPE.JSON,
): Promise<any> {
  return axios
    .get(path, { headers: getHeaders(requestType, accessToken) })
    .then((res) => res.data)
    .catch((error) => {
      const errorMessage = `Error occurred on get request: Error: ${error}, path:${path}, requestType:${requestType}`;
      console.log(errorMessage);
      throw error;
    });
}

export function post(
  path: string,
  body: any,
  isTokenRequired: boolean = true,
  requestType: HTTP_REQUEST_TYPE = HTTP_REQUEST_TYPE.JSON,
): Promise<any> {
  return axios
    .post(path, body, { headers: getHeaders(requestType, undefined, isTokenRequired) })
    .then((res) => res.data)
    .catch((error) => {
      const errorMessage = `Error occurred on post request: Error: ${error}, path:${path}, body:${body?.name}`;
      console.log(errorMessage);
      throw error;
    });
}

export function put(
  path: string,
  body: any,
  requestType: HTTP_REQUEST_TYPE = HTTP_REQUEST_TYPE.JSON,
): Promise<any> {
  return axios
    .put(path, body, { headers: getHeaders(requestType) })
    .then((res) => res.data)
    .catch((error) => {
      const errorMessage = `Error occurred on put request: Error: ${error}, path:${path}, body:${body?.name}`;
      console.log(errorMessage);
      throw error;
    });
}

export function deleted(
  path: string,
  requestType: HTTP_REQUEST_TYPE = HTTP_REQUEST_TYPE.JSON,
): Promise<any> {
  return axios
    .delete(path, { headers: getHeaders(requestType) })
    .then((res) => res.data)
    .catch((error) => {
      const errorMessage = `Error occurred on put request: Error: ${error}, path:${path}, requestType:${requestType}`;
      console.log(errorMessage);
      throw error;
    });
}

export function putFile(path: string, file: File) {
  return axios.put(path, file, { headers: getImgHeaders() })
    .then((res) => res)
    .catch((error) => {
      const errorMessage = `Error occurred on put file request: Error: ${error}, path:${path}, file:${file?.name}`;
      console.log(errorMessage);
      throw error;
    });
}

const handleAuthError = async (originalRequest: any) => {
  // eslint-disable-next-line no-underscore-dangle
  if (originalRequest._retry) {
    await authService.logout();
    window.location.reload();

    return;
  }
  // eslint-disable-next-line no-underscore-dangle
  originalRequest._retry = true;
  if (authService.getCurrentUser()) {
    try {
      await authService.refreshToken();
      const updatedToken = authService.getCurrentUser().accessToken;
      originalRequest.headers.Authorization = `Bearer ${updatedToken}`;

      return axios(originalRequest);
    } catch (error) {
      await authService.logout();
      window.location.reload();
    }
  }
};

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      return handleAuthError(originalRequest);
    }
    console.error(error);
    return Promise.reject(error);
  },
);

const apiService = {
  get,
  post,
  put,
  deleted,
  putFile,
  handleAuthError,
};

export default apiService;
