import './RationBanner.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, EffectFade } from 'swiper/modules';
import { FormattedMessage } from 'react-intl';
import Muscle from '../../../../asset/icons/ration/muscle.svg';
import BannerShare from '../../../../asset/icons/profile/share.svg';
import KcalPic from '../../../../asset/icons/calories.svg';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import { Ration } from '../../../../common/constant/interface/interfaces';

const BANNER_COLOR = '#06c78c';
const GOAL_IMAGE = Muscle;

interface RationBannerProps {
    ration: Ration;
}

const RationBanner = ({ ration }: RationBannerProps) => {
  const {
    nutrition, images, name,
  } = ration;

  // TODO: consider default image
  return images?.length ? (
    <div className="swiper-container">
      <div className="banner">
        <Swiper
          modules={[Autoplay, Pagination, EffectFade]}
          spaceBetween={30}
          slidesPerView={1}
          preventInteractionOnTransition
          effect="fade"
          loop
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
          }}
          autoplay={{
            delay: 5500,
            disableOnInteraction: true,
          }}
          className="banner__swiper"
        >
          {images?.map((image) => (
            <SwiperSlide key={image.id}>
              <img className="banner__picture" src={image.url} alt="ration" />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="banner__circle"
          style={{ backgroundColor: `${BANNER_COLOR}` }}
        />
        <div className="banner__content">
          <div className="banner__descr">
            <div className="banner__goal goal">
              <h3 className="goal__title">{name}</h3>
              <img className="goal__img" src={GOAL_IMAGE} alt="Muscle" />
            </div>
            <div className="banner__kcal">
              {nutrition.calorie}
              <span>
                <FormattedMessage id="meal.item.kcal" />
              </span>
              <img src={KcalPic} alt="calories" />
            </div>
            <div className="banner__macronutrients macronutrients">
              <div className="macronutrients__proteins">
                <span>{nutrition.protein}</span>
                <span>
                  <FormattedMessage id="meal.item.proteins" />
                </span>
              </div>
              <div className="macronutrients__fats">
                <span>{nutrition.fat}</span>
                <span>
                  <FormattedMessage id="meal.item.fats" />
                </span>
              </div>
              <div className="macronutrients__carbo">
                <span>{nutrition.carbo}</span>
                <span>
                  <FormattedMessage id="calculate.placeholder.carbs" />
                </span>
              </div>
            </div>
          </div>
          <button className="banner__share-btn share-btn" type="button">
            <img src={BannerShare} alt="Share" />
          </button>
        </div>
      </div>
      <div className="swiper-pagination swiper-pagination" />
    </div>
  ) : null;
};

export default RationBanner;
