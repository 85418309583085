import { useEffect } from 'react';
import './NutritionGoal.scss';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Button, ButtonType } from '../../form/button/Button';
import { RootState } from '../../../redux';
import {
  fetchNutritionGoals, setSelectedGoals, updateNutritionGoals,
} from '../../../redux/slice/userSlice';
import { useAppDispatch } from '../../../hook/useAppDispatch';
import { useAppSelector } from '../../../hook/useAppSelector';

interface NutritionGoalProps {
  handleClose: () => void;
}

const MAX_DEFAULT_VALUE_PROTEINS = 500;
const MAX_DEFAULT_VALUE_FATS = 150;
const MAX_DEFAULT_VALUE_CARBS = 1000;
const MAX_DEFAULT_VALUE_CALORIES = 7000;

const formatMessageByNutrient = (intl: IntlShape, nutrient: 'kcal' | 'protein' | 'fat' | 'carbs'): string => {
  const nutrientMap: { [key in 'kcal' | 'protein' | 'fat' | 'carbs']: string } = {
    kcal: 'calories',
    protein: 'meal.item.proteins',
    fat: 'meal.item.fats',
    carbs: 'meal.item.carbs',
  };

  return intl.formatMessage({ id: nutrientMap[nutrient] });
};

const NutritionGoal = ({ handleClose }: NutritionGoalProps) => {
  const dispatch = useAppDispatch();
  const selectedGoals = useAppSelector((state: RootState) => state.user.selectedGoals);
  const intl = useIntl();

  useEffect(() => {
    dispatch(fetchNutritionGoals());
  }, [dispatch]);

  const handleSliderChange = (key: keyof typeof selectedGoals, value: number) => {
    dispatch(setSelectedGoals({ ...selectedGoals, [key]: value }));
  };

  const handleInputChange = (key: keyof typeof selectedGoals, value: number) => {
    handleSliderChange(key, value);
  };

  const handleUpdate = () => {
    dispatch(updateNutritionGoals(selectedGoals));
    handleClose();
  };

  const renderSlider = (
    label: string,
    key: keyof typeof selectedGoals,
    min: number,
    max: number,
  ) => (
    <div className="nutrition-goal__slider">
      <label htmlFor={key}>{label}</label>
      <div className="nutrition-goal__inputs">
        <div className="nutrition-goal__top-inputs">
          <input
            type="number"
            id={key}
            min={min}
            max={max}
            value={selectedGoals[key]}
            onChange={(e) => handleInputChange(key, Number(e.target.value))}
          />
          <span>
            {label === formatMessageByNutrient(intl, 'kcal')
              ? `${intl.formatMessage({ id: 'meal.item.kcal' })}` : `${intl.formatMessage({ id: 'nutrition.unit.gram' })}`}
          </span>
        </div>
        <Slider
          min={min}
          max={max}
          value={selectedGoals[key]}
          onChange={(value) => {
            if (typeof value === 'number') {
              handleSliderChange(key, value);
            }
          }}
        />
      </div>
    </div>
  );

  return (
    <form className="nutrition-goal">
      <h4 className="nutrition-goal__title">
        <FormattedMessage id="nutrition.title.targets" />
      </h4>
      <button type="button" onClick={handleClose} className="nutrition-goal__close" />
      <div className="nutrition-goal__slider-group">
        {renderSlider(formatMessageByNutrient(intl, 'kcal'), 'calorieGoal', 0, MAX_DEFAULT_VALUE_CALORIES)}
        {renderSlider(formatMessageByNutrient(intl, 'fat'), 'fatGoal', 0, MAX_DEFAULT_VALUE_FATS)}
        {renderSlider(formatMessageByNutrient(intl, 'protein'), 'proteinGoal', 0, MAX_DEFAULT_VALUE_PROTEINS)}
        {renderSlider(formatMessageByNutrient(intl, 'carbs'), 'carboGoal', 0, MAX_DEFAULT_VALUE_CARBS)}
      </div>
      <Button onClick={handleUpdate} type={ButtonType.SUBMIT} label={`${intl.formatMessage({ id: 'weight.button.update' })}`} />
    </form>
  );
};

export default NutritionGoal;
