import React, { useState } from 'react';
import { ErrorMessage, Field, FieldAttributes } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import './FormField.scss';
import seeIcon from '../../../../asset/icons/see-eye.svg';
import unSeeIcon from '../../../../asset/icons/unsee-eye.svg';

interface FormFieldProps {
  name: string
  type?: string
  placeholder?: string
  label?: string
  as?: string
  options?: FieldAttributes<any>['props'],
}

const FormField = ({
  name,
  type = 'text',
  placeholder,
  label,
  options = {},
  ...rest
}: FormFieldProps) => {
  const [watchPassword, setWatchPassword] = useState<boolean>(false);
  const intl = useIntl();

  const renderedPlaceholder = intl.messages[`${placeholder}`];
  const renderedError = (
    <ErrorMessage name={name} render={(message) => <p className="error-message">{message}</p>} />
  );

  const renderInput = () => {
    switch (type) {
      case 'password':
        return (
          <div className="input-field-pass">
            <Field
              name={name}
              type={watchPassword ? 'text' : 'password'}
              autoComplete="off"
              placeholder={renderedPlaceholder}
            />
            <button
              type="button"
              className="input-field-icon"
              onClick={() => setWatchPassword((prev) => !prev)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setWatchPassword((prev) => !prev);
                }
              }}
            >
              <img src={watchPassword ? unSeeIcon : seeIcon} alt="View" />
            </button>
          </div>
        );

      default:
        return (
          <>
            {label && (
              <label htmlFor={name} aria-labelledby={name}>
                <FormattedMessage id={label} />
              </label>
            )}
            <Field
              id={name}
              name={name}
              className="input-field"
              type={type}
              placeholder={renderedPlaceholder}
              {...options}
              {...rest}
            />
          </>
        );
    }
  };

  return (
    <div className="input-container">
      {renderInput()}
      {renderedError}
    </div>
  );
};

export default FormField;
