import { FormattedMessage } from 'react-intl';
import FoodTypeItem from './FoodTypeItem';
import { FoodType, FoodTypeEnum } from '../../../common/constant/interface/interfaces';
import { setMealTimes } from '../../dashboard/component/myRation/MyRationsList';

enum MealType {
  Breakfast = 'BREAKFAST',
  Lunch = 'LUNCH',
  Dinner = 'DINNER',
  Snacks = 'SNACKS'
}

interface RationSliderProps {
  foodTypes: FoodType[];
}

const RationSlider = ({ foodTypes }: RationSliderProps) => (
  <section className="meals-slider">
    <ul className="meals-slider__list">
      {foodTypes ? (
        <>
          {
            foodTypes.map((ration) => (
              <li key={ration.name} className="meals-slider__item">
                <FoodTypeItem
                  titleMealType={setMealTimes(ration.name as FoodTypeEnum)}
                  foodType={ration}
                />
              </li>
            ))
          }
        </>
      ) : (
        <p className="not-found">
          <FormattedMessage id="dashboard.modal.button.noItemsFound" />
        </p>
      )}
    </ul>
  </section>
);
export default RationSlider;
