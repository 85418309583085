import { forwardRef, useImperativeHandle } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { useAppSelector } from '../../../../hook/useAppSelector';
import {
  clearSelectedSearchParam,
  getAvailableIngredientsToSelect,
  getAvailableTagsToSelect,
  MealSearchSelectedParam,
  searchAvailableIngredientsToSelect,
  searchAvailableTagsToSelect,
  searchMeals,
  setSelectedSearchObject,
} from '../../../../redux/slice/mealSlice';
import { ReactComponent as Reset } from '../../../../asset/icons/filter/reset.svg';
import TimeFiler from '../../../../component/filter/time/TimeFilter';
import ClockPrep from '../../../../asset/icons/MealItem/prep.svg';
import ClockCook from '../../../../asset/icons/MealItem/cook.svg';
import MealTimeFilter from '../../../../component/filter/mealTime/MealTimeFilter';
import Difficulty from '../../../../component/filter/difficulty/DifficultyFilter';
import NutritionFilter from '../../../../component/filter/nutrition/NutritionFilter';
import { useAppDispatch } from '../../../../hook/useAppDispatch';
import mealMapperService from '../../../../service/mapper/meal/mealMapperService';
import DynamicSearchFilterWrapper from '../../../../component/filter/search/wrapper/DynamicSearchFilterWrapper';

const INGREDIENTS_FILTERS_SEARCH_COUNT_SIZE = 10;
const TAGS_FILTERS_SEARCH_COUNT_SIZE = 10;

interface MealListFilterProps {
  setShowSearchResult: (showSearchResult: boolean) => void;
  onFilterClick: () => void;
  setIsFilterOpen: (isFilterOpen: boolean) => void;
}

const MealListFilter = forwardRef<any, MealListFilterProps>((props, ref) => {
  const {
    setShowSearchResult,
    onFilterClick,
    setIsFilterOpen,
  } = props;
  const dispatch = useAppDispatch();

  const availableSearchObj = useAppSelector((state) => state.meal.search.availableSearchParam);
  const appliedSearchObj = useAppSelector((state) => state.meal.search.appliedSearchParam);
  const selectedSearchObj = useAppSelector((state) => state.meal.search.selectedSearchParam);
  const ingredients = useAppSelector((state) => state.meal.search.ingredients);
  const tags = useAppSelector((state) => state.meal.search.tags);
  const intl = useIntl();

  const isAnyFiltersApplied = () => {
    const searchObj = mealMapperService.mapSelectedSearchObjToMealSearchRequestDto(
      selectedSearchObj,
      availableSearchObj,
    );
    return Object.keys(searchObj).length > 0;
  };

  const handleNameSearch = (query: string) => {
    const appliedFilters = mealMapperService.mapToMealSearchSelectedParam(appliedSearchObj);
    const updatedSearchObj = {
      ...appliedFilters,
      name: query,
    };

    dispatch(setSelectedSearchObject({
      ...selectedSearchObj,
      name: query,
    }));

    dispatch(searchMeals({
      selectedParam: updatedSearchObj,
      availableParam: availableSearchObj,
    }));
    if (isAnyFiltersApplied()) {
      setShowSearchResult(true);
    } else {
      setShowSearchResult(false);
    }
  };

  useImperativeHandle(ref, () => ({
    handleNameSearch(query: string) {
      handleNameSearch(query);
    },
  }));

  const handleSearchObjUpdate = (
    ...params: { key: string; value: any }[]
  ) => {
    const updatedSearchObj: MealSearchSelectedParam = { ...selectedSearchObj };

    params.forEach(({
      key,
      value,
    }) => {
      // @ts-ignore
      updatedSearchObj[key] = value;
    });

    dispatch(setSelectedSearchObject(updatedSearchObj));
  };

  const handleSearch = () => {
    if (isMobile) {
      setIsFilterOpen(false);
    }
    dispatch(searchMeals({
      selectedParam: selectedSearchObj,
      availableParam: availableSearchObj,
    }));
    setShowSearchResult(true);
  };

  const translate = (id:string) => intl.formatMessage({ id });

  return (
    <>
      <div className="meal__header">
        <button
          type="button"
          className="meal__filters-button meal__filters-button--close-cross"
          onClick={onFilterClick}
          aria-label="meal-filter-button"
          data-testid="meal-mobile-filter-button"
        >
          <span className="cross-icon" />
        </button>
        <h2 className="meal__filters-title">
          <FormattedMessage id="meal.list.filters" />
        </h2>
        <button
          type="button"
          className="meal__reset-button"
          onClick={() => dispatch(clearSelectedSearchParam())}
        >
          <FormattedMessage id="filters.reset" />
          <Reset />
        </button>
      </div>
      <div className="meal__time-filters">
        <TimeFiler
          title={translate('meal.time.prep')}
          min={{
            key: 'prepareTimeMinutesMin',
            availableValue: availableSearchObj.prepareTimeMinutesMin,
            selectedValue: selectedSearchObj.prepareTimeMinutesMin,
          }}
          max={{
            key: 'prepareTimeMinutesMax',
            availableValue: availableSearchObj.prepareTimeMinutesMax,
            selectedValue: selectedSearchObj.prepareTimeMinutesMax,
          }}
          timeStep={5}
          icon={<img src={ClockPrep} alt="Time" />}
          onChange={handleSearchObjUpdate}
          timeUnit="minutes"
        />
        <TimeFiler
          title={translate('meal.time.cook')}
          min={{
            key: 'cookTimeMinutesMin',
            availableValue: availableSearchObj.cookTimeMinutesMin,
            selectedValue: selectedSearchObj.cookTimeMinutesMin,
          }}
          max={{
            key: 'cookTimeMinutesMax',
            availableValue: availableSearchObj.cookTimeMinutesMax,
            selectedValue: selectedSearchObj.cookTimeMinutesMax,
          }}
          timeStep={5}
          icon={<img src={ClockCook} alt="Time" />}
          onChange={handleSearchObjUpdate}
          timeUnit="minutes"
        />
      </div>
      <div className='meal__time-and-level'>
        <MealTimeFilter
          availableMealTimes={availableSearchObj.foodTypes}
          selectedMealTime={{
            key: 'foodTypeNames',
            value: selectedSearchObj.foodTypeNames,
          }}
          onChange={handleSearchObjUpdate}
        />
        <Difficulty
          max={{
            key: 'difficultyMax',
            selectedValue: selectedSearchObj.difficultyMax,
            availableValue: availableSearchObj.difficultyMax,
          }}
          min={1}
          onChange={handleSearchObjUpdate}
        />
      </div>
      <div className="meal__nutrition-filters">
        <NutritionFilter
          min={{
            key: 'calorieMin',
            selectedValue: selectedSearchObj.calorieMin,
            availableValue: availableSearchObj.calorieMin,
          }}
          max={{
            key: 'calorieMax',
            selectedValue: selectedSearchObj.calorieMax,
            availableValue: availableSearchObj.calorieMax,
          }}
          onChange={handleSearchObjUpdate}
          title={translate('dashboard.title.kcal')}
        />
        <NutritionFilter
          min={{
            key: 'proteinMin',
            selectedValue: selectedSearchObj.proteinMin,
            availableValue: availableSearchObj.proteinMin,
          }}
          max={{
            key: 'proteinMax',
            selectedValue: selectedSearchObj.proteinMax,
            availableValue: availableSearchObj.proteinMax,
          }}
          onChange={handleSearchObjUpdate}
          title={translate('meal.item.proteins')}
        />
        <NutritionFilter
          min={{
            key: 'fatMin',
            selectedValue: selectedSearchObj.fatMin,
            availableValue: availableSearchObj.fatMin,
          }}
          max={{
            key: 'fatMax',
            selectedValue: selectedSearchObj.fatMax,
            availableValue: availableSearchObj.fatMax,
          }}
          onChange={handleSearchObjUpdate}
          title={translate('dashboard.title.fats')}
        />
        <NutritionFilter
          min={{
            key: 'carboMin',
            selectedValue: selectedSearchObj.carboMin,
            availableValue: availableSearchObj.carboMin,
          }}
          max={{
            key: 'carboMax',
            selectedValue: selectedSearchObj.carboMax,
            availableValue: availableSearchObj.carboMax,
          }}
          onChange={handleSearchObjUpdate}
          title={translate('meal.item.carbs')}
        />
      </div>
      <div className="meal__search-filters">
        <DynamicSearchFilterWrapper
          filterType="ingredients"
          searchObj={ingredients.search}
          availableObj={{
            content: ingredients.availableIngredients,
            cursorId: ingredients.cursorId,
          }}
          selectedObj={selectedSearchObj}
          countSize={INGREDIENTS_FILTERS_SEARCH_COUNT_SIZE}
          handleSearchObjUpdate={handleSearchObjUpdate}
          searchItems={(params) => dispatch(searchAvailableIngredientsToSelect(params))}
          getItems={(params) => dispatch(getAvailableIngredientsToSelect(params))}
        />
        <DynamicSearchFilterWrapper
          filterType="tags"
          searchObj={tags.search}
          availableObj={{ content: tags.availableTags, cursorId: tags.cursorId }}
          selectedObj={selectedSearchObj}
          countSize={TAGS_FILTERS_SEARCH_COUNT_SIZE}
          handleSearchObjUpdate={handleSearchObjUpdate}
          searchItems={(params) => dispatch(searchAvailableTagsToSelect(params))}
          getItems={(params) => dispatch(getAvailableTagsToSelect(params))}
        />
      </div>
      <button
        type="button"
        className="meal__button"
        aria-label="meal__button"
        onClick={handleSearch}
      >
        <FormattedMessage id="apply.button" />
      </button>
    </>
  );
});

export default MealListFilter;
