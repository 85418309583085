function calculatePercentageFromEaten(valueEaten:number, valueForDay:number) {
  const percentage = valueForDay > 0 ? (valueEaten / valueForDay) * 100 : 0;
  return Math.min(percentage, 100);
}

const statisticService = {
  calculatePercentageFromEaten,
};

export default statisticService;
