import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './Husky.scss';

interface HuskyProps {
  state: 'sad' | 'waiting' | 'happy';
}

const huskyStates = {
  sad: {
    lipsClassName: 'lips-sad',
    earClassName: 'ear-sad',
    tearLeft: 'tear-left',
    tearRight: 'tear-right',
    tailClassName: 'tail tail-sad',
    tongueClassName: '',
    dreamElements: null,
  },
  waiting: {
    lipsClassName: 'lips-waiting',
    earClassName: 'ear',
    tearLeft: '',
    tearRight: '',
    tailClassName: 'tail',
    tongueClassName: 'tongue-waiting',
    dreamElements: (
      <>
        <div className="small-dream" />
        <div className="dream">
          <div className="bone" />
          <div className="bone-circle1" />
          <div className="bone-circle2" />
          <div className="bone-circle3" />
          <div className="bone-circle4" />
        </div>
      </>
    ),
  },
  happy: {
    lipsClassName: 'lips',
    earClassName: 'ear',
    tearLeft: '',
    tearRight: '',
    tailClassName: 'tail',
    tongueClassName: '',
    dreamElements: null,
  },
};

const getHuskyClassNames = (state: HuskyProps['state']) => huskyStates[state];

const Husky = ({ state }: HuskyProps) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const rootStyle = { '--mouse-x': mousePosition.x, '--mouse-y': mousePosition.y } as React.CSSProperties;

  const {
    lipsClassName,
    earClassName,
    tearLeft,
    tearRight,
    tailClassName,
    tongueClassName,
    dreamElements,
  } = getHuskyClassNames(state);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      const { clientX, clientY } = e;
      const { body } = document;
      const fullWidth = body.clientWidth;
      const fullHeight = body.clientHeight;
      setMousePosition({ x: clientX / fullWidth, y: clientY / fullHeight });
    };

    document.body.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.body.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="container_husky">
      <div className="husky" style={rootStyle}>
        <div className="mane">
          <div className="coat" />
        </div>
        <div className="body">
          <div className="head paused">
            <div className={earClassName} />
            <div className={earClassName} />
            <div className="face paused">
              <div className="eye paused" />
              <div className="eye paused" />
              {tearLeft && <div className={`${tearLeft} paused`} />}
              {tearRight && <div className={`${tearRight} paused`} />}
              <div className="nose paused" />
              <div className="mouth paused">
                <div className={`${lipsClassName} paused`}>
                  {tongueClassName && <div className={tongueClassName} />}
                </div>
              </div>
            </div>
          </div>
          <div className="torso" />
        </div>
        <div className="legs">
          <div className="front-legs">
            <div className="leg" />
            <div className="leg" />
          </div>
          <div className="hind-leg" />
        </div>
        <div className={tailClassName}>
          <div className={tailClassName}>
            <div className={tailClassName}>
              <div className={tailClassName}>
                <div className={tailClassName}>
                  <div className={tailClassName}>
                    <div className={tailClassName} />
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {dreamElements}
      </div>
      <p className="husky_text">
        <FormattedMessage id="husky.comingSoon" />
      </p>
    </div>
  );
};

export default Husky;
