import '../../../style/scss/style.scss';
import '../Registration.scss';
import { FormattedMessage } from 'react-intl';
import { ErrorMessage } from 'formik';
import backBtnImage from '../../../asset/icons/back-arrow.svg';
import MeasureImage from '../../../asset/icons/measurement.png';
import {
  RegistrationFormValues,
  RegistrationStepProps,
  setRegistrationForNumberField,
} from '../Registration';
import { setMessage } from '../../../redux/slice/messageSlice';
import { useAppDispatch } from '../../../hook/useAppDispatch';
import { convertToErrorMessage } from '../../../service/message/messageConverter';
import validationService from '../../../service/validation/validationService';
import StepsTracker from '../../../component/stepsTracker/StepsTracker';

const FIELD_NAMES_TO_BACKEND_VALIDATE: Array<keyof RegistrationFormValues> = ['birthdayDate', 'height', 'heightUnit'];

const heightUnits = [
  { value: 'CENTIMETER', textIntl: 'form.option.centimetr' },
  { value: 'INCH', textIntl: 'form.option.inch' },
];

function RegistrationStep4({
  onNextStep,
  onPrevStep,
  formikProps,
  currentStep,
}: Readonly<RegistrationStepProps>) {
  const dispatch = useAppDispatch();

  const handleValidateAndSubmit = async () => {
    await validationService.validateRegistrationForm(
      formikProps,
      currentStep,
      FIELD_NAMES_TO_BACKEND_VALIDATE,
    )
      .then(() => {
        if (onNextStep) {
          onNextStep();
        }
      })
      .catch((error) => {
        dispatch(setMessage(convertToErrorMessage(error)));
      });
  };

  const renderErrorMessage = (name: string) => (
    <ErrorMessage name={name} render={(message) => <p className="error-message">{message}</p>} />
  );

  return (
    <div className="window registration-window registration-window--small-padding">
      <StepsTracker currentStep={currentStep - 1} />
      <button
        data-testid="button-back"
        type="button"
        onClick={onPrevStep}
        className="window__back-btn back-btn"
      >
        <img alt="back" className="back-btn__picture" src={backBtnImage} />
      </button>
      <img className="registration-window__measurement-pic" src={MeasureImage} alt="Measure" />
      <h2 className="window__title registration-window__title registration-window__title--small">
        <FormattedMessage id="form.label.measurement" />
      </h2>
      <div className="registration-window__measurement-form measurement-form form-window">
        <div className="measurement-form__item measurement-item">
          <label htmlFor="birth">
            <FormattedMessage id="form.label.birthDate" />
          </label>
          <input
            data-testid="input-date"
            type="date"
            name="birthdayDate"
            id="birth"
            value={formikProps.values.birthdayDate}
            onChange={(e) => formikProps.setFieldValue('birthdayDate', e.target.value)}
          />
        </div>
        {renderErrorMessage('birthdayDate')}
        <div className="measurement-form__item measurement-item">
          <label htmlFor="height">
            <FormattedMessage id="form.label.height" />
          </label>
          <div className="measurement-form__input-wrapper">
            <input
              type="number"
              name="height"
              placeholder="0"
              id="height"
              value={formikProps.values.height}
              onChange={(e) => formikProps.setFieldValue('height', setRegistrationForNumberField(e.target.value))}
            />
            <select
              name="unit"
              id="unit"
              onChange={(e) => formikProps.setFieldValue('heightUnit', e.target.value)}
            >
              {heightUnits.map(({ value, textIntl }) => (
                <option value={value} key={value}>
                  <FormattedMessage id={textIntl} />
                </option>
              ))}
            </select>
          </div>
          {renderErrorMessage('height')}
        </div>
        <button
          onClick={handleValidateAndSubmit}
          type="submit"
          className="registration-window__btn window-btn"
        >
          <FormattedMessage id="next.button" />
        </button>
      </div>
    </div>
  );
}

export default RegistrationStep4;
