import Title from '../title/Title';
import languageService from '../../service/language/languageService';
import './InfoSectionHeader.scss';
import FieldItem from '../form/formField/typesField/FieldItem';

const InfoSectionHeader = () => {
  const appSupportedLanguage = [
    {
      label: 'form.label.language',
      value: languageService.getCurrentLocale(),
      type: 'select',
      option: languageService.getSupportedLanguage(),
      onChange: (newValue: string) => {
        languageService.setCurrentLocale(newValue.split('-')[0]);
        languageService.updateLocal(newValue.split('-')[0]);
      },
      isEdit: true,
    },
  ];

  return (
    <header className="legal__header">
      <Title />
      {appSupportedLanguage.map(({
        label, value, type, option, onChange, isEdit,
      }) => (
        <FieldItem
          key={label}
          className="header__select"
          label={label}
          value={value}
          onChange={onChange}
          isEdit={isEdit}
          type={type}
          option={option}
          code={value}
        />
      ))}
    </header>
  );
};

export default InfoSectionHeader;
