import React from 'react';
import './StepsTracker.scss';

interface StepsTrackerProps {
  currentStep: number;
  totalSteps?: number;
  className?: string;
}

const StepsTracker = ({
  currentStep,
  className = '',
  totalSteps = 4, /* default ammount of Steps, but we can change it using totalSteps props */
}:StepsTrackerProps) => {
  const progress = (currentStep / totalSteps) * 100;

  return (
    <div className={`steps-bar ${className}`}>
      <div className="steps-bar__progress">
        <div
          className="steps-bar__fill"
          style={{ width: `${progress}%` }}
        />
      </div>
      <div className="steps-bar__indicator">
        {`${currentStep}/${totalSteps}`}
      </div>
    </div>
  );
};

export default StepsTracker;
