import { createAsyncThunk } from '@reduxjs/toolkit';
import s3Service from '../../service/s3/s3Service';
import { FILE_TYPE } from '../../common/enum/enum';
import { setMessage } from './messageSlice';

export const uploadImage = createAsyncThunk<string, File>(
  's3/uploadImage',
  async (file, thunkAPI) => {
    try {
      const preSignedUrl = await s3Service.generatePreSignedUrl(FILE_TYPE.PROFILE_IMAGE);
      const imageVersion = await s3Service.uploadImage(preSignedUrl, file);

      return s3Service.buildS3ImageUrlWithVersion(preSignedUrl, imageVersion);
    } catch (error: any) {
      const { message } = error;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  },
);
