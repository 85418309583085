import './Calculator.scss';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Reset from '../../asset/icons/calculate/reset.svg';
import GradientButton from '../../component/button/GradientButton';
import Explanation from '../../asset/icons/calculate/explanation.svg';
import {
  CalculatorFormData,
  clearCalculationData,
  fetchInitData, setCalculatorInputFields,
} from '../../redux/slice/calculatorSlice';
import { RootState } from '../../redux';
import { useAppDispatch } from '../../hook/useAppDispatch';
import { useAppSelector } from '../../hook/useAppSelector';

interface CalculatorProps {
  onSubmit: (formData: CalculatorFormData) => void;
}

const CalculatorForm = ({ onSubmit }: CalculatorProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [isExplanationVisibleActivity, setIsExplanationVisibleActivity] = useState(false);
  const [isExplanationVisibleFormula, setIsExplanationVisibleFormula] = useState(false);
  const selectedFormData = useAppSelector(
    (state: RootState) => state.calculator.selectedFormDataFields,
  );
  const calculatorInitialValues = useAppSelector(
    (state: RootState) => state.calculator.initialValues,
  );
  const setCalculationFormData = (
    formData: CalculatorFormData,
  ) => dispatch(setCalculatorInputFields(formData));

  useEffect(() => {
    dispatch(fetchInitData());

    return () => { dispatch(clearCalculationData()); };
  }, [dispatch]);

  const formatRatioMessage = ():string => `${
    intl.formatMessage({ id: 'calculate.placeholder.ratio' })} ${
    intl.formatMessage({ id: 'meal.item.proteins' })}/${
    intl.formatMessage({ id: 'meal.item.fats' })}/${
    intl.formatMessage({ id: 'calculate.placeholder.carbs' })}, %`;

  const handleBaseInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setCalculationFormData({ ...selectedFormData, [name]: value });
  };

  const handleActivityLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const activityData = calculatorInitialValues.activityLevels[
      value as keyof typeof calculatorInitialValues.activityLevels
    ];
    setCalculationFormData({
      ...selectedFormData,
      proteinRatio: activityData.proteinRatio,
      carboRatio: activityData.carboRatio,
      fatRatio: activityData.fatRatio,
      activityLevel: value,
    });
  };

  const handleFormulaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setCalculationFormData({
      ...selectedFormData,
      formula: value,
      bodyFatPercentage: value === 'KATCH_MACARDLE' ? 0 : null,
    });
  };

  const handleGoalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const goalData = calculatorInitialValues.goals[
      value as keyof typeof calculatorInitialValues.goals
    ];

    setCalculationFormData({
      ...selectedFormData,
      goal: value,
      adjustingCalories: goalData
        ? goalData.caloriesAdjustment
        : selectedFormData.adjustingCalories,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(selectedFormData);
  };

  const handleReset = () => {
    dispatch(clearCalculationData());
  };

  return (
    <form
      className="calculator"
      id="calculator"
      onSubmit={handleSubmit}
    >
      <div className="calculator__input-groups">
        <div className="input-group input-group--gender">
          <h4 className="input-group__header">
            <FormattedMessage id="calculate.placeholder.gender" />
            :
          </h4>
          <div className="input-group__wrapper">
            <div className="input-group__item">
              <input
                type="radio"
                id="male"
                name="gender"
                value="MALE"
                checked={selectedFormData.gender === 'MALE'}
                onChange={handleBaseInputChange}
              />
              <label htmlFor="male">
                <FormattedMessage id="male.button" />
              </label>
            </div>
            <div className="input-group__item">
              <input
                type="radio"
                id="female"
                name="gender"
                value="FEMALE"
                checked={selectedFormData.gender === 'FEMALE'}
                onChange={handleBaseInputChange}
              />
              <label htmlFor="female">
                <FormattedMessage id="female.button" />
              </label>
            </div>
          </div>
        </div>
        <div className="input-group input-group--numbers">
          <label htmlFor="age">
            <FormattedMessage id="calculate.placeholder.age" />
            :
          </label>
          <input
            type="number"
            required
            id="age"
            name="age"
            value={selectedFormData.age}
            onChange={handleBaseInputChange}
          />
        </div>
        <div className="input-group input-group--numbers">
          <label htmlFor="height">
            <FormattedMessage id="form.label.height" />
            :
          </label>
          <div className="input-group__unit-container">
            <input
              required
              type="number"
              id="height"
              name="height"
              value={selectedFormData.height}
              onChange={handleBaseInputChange}
            />
            <select
              className="input-group__units"
              id="height-unit"
              name="heightUnit"
              value={selectedFormData.heightUnit}
              onChange={handleBaseInputChange}
            >
              <option value="CENTIMETER">
                <FormattedMessage id="form.option.centimetr" />
              </option>
              <option value="INCH">
                <FormattedMessage id="form.option.inch" />
              </option>
            </select>
          </div>
        </div>
        <div className="input-group input-group--numbers">
          <label htmlFor="weight">
            <FormattedMessage id="form.label.weight" />
            :
          </label>
          <div className="input-group__unit-container">
            <input
              required
              type="number"
              id="weight"
              name="weight"
              value={selectedFormData.weight}
              onChange={handleBaseInputChange}
            />
            <select
              className="input-group__units"
              id="weight-unit"
              name="weightUnit"
              value={selectedFormData.weightUnit}
              onChange={handleBaseInputChange}
            >
              <option value="KILOGRAM">
                <FormattedMessage id="form.option.kilogram" />
              </option>
              <option value="POUND">
                <FormattedMessage id="form.option.pound" />
              </option>
            </select>
          </div>
        </div>
        <div className="input-group input-group--selectors input-group--explanation">
          <label htmlFor="activity-level">
            <FormattedMessage id="calculate.placeholder.activity" />
            :
            <button
              className="label-descr"
              type="button"
              onClick={() => setIsExplanationVisibleActivity(!isExplanationVisibleActivity)}
            >
              <img
                src={Explanation}
                alt="Description of the field"
              />
              <div className={`label-descr__txt ${isExplanationVisibleActivity ? 'visible' : ''}`}>
                <FormattedMessage id="calculate.explain.activity" />
              </div>
            </button>
          </label>
          <select
            id="activity-level"
            name="activityLevel"
            value={selectedFormData.activityLevel}
            onChange={handleActivityLevelChange}
          >
            {Object.keys(calculatorInitialValues.activityLevels).map((level: string) => (
              <option
                key={level}
                value={level}
              >
                <FormattedMessage id={`calculate.activityLevel.${level}`} />
              </option>
            ))}
          </select>
        </div>
        <div className="input-group input-group--selectors input-group--explanation">
          <label htmlFor="formula">
            <FormattedMessage id="calculate.placeholder.formula" />
            :
            <button
              className="label-descr"
              type="button"
              onClick={() => setIsExplanationVisibleFormula(!isExplanationVisibleFormula)}
            >
              <img
                src={Explanation}
                alt="Description of the field"
              />
              <div className={`label-descr__txt ${isExplanationVisibleFormula ? 'visible' : ''}`}>
                <FormattedMessage id="calculate.explain.formula" />
              </div>
            </button>
          </label>
          <select
            id="formula"
            name="formula"
            value={selectedFormData.formula}
            onChange={handleFormulaChange}
          >
            {calculatorInitialValues.formulas.map((formula: string) => (
              <option
                key={formula}
                value={formula}
              >
                <FormattedMessage id={`calculate.formula.${formula}`} />
              </option>
            ))}
          </select>
        </div>
        <div className="input-group input-group--percentage">
          <label htmlFor="proteins fats carbs">{formatRatioMessage()}</label>
          <div
            className="input-group__ratio"
            id="ratio"
          >
            <input
              type="number"
              id="proteins"
              name="proteins"
              value={selectedFormData.proteinRatio}
              disabled
            />
            <input
              type="number"
              id="fats"
              name="fats"
              value={selectedFormData.fatRatio}
              disabled
            />
            <input
              type="number"
              id="carbs"
              name="carbs"
              value={selectedFormData.carboRatio}
              disabled
            />
          </div>
        </div>

        <div className="input-group input-group--selectors">
          <label htmlFor="goal">
            <FormattedMessage id="calculate.placeholder.target" />
            :
          </label>
          <select
            id="goal"
            name="goal"
            value={selectedFormData.goal}
            onChange={handleGoalChange}
          >
            {Object.keys(calculatorInitialValues.goals).map((target: string) => (
              <option
                key={target}
                value={target}
              >
                <FormattedMessage id={`calculate.target.${target}`} />
              </option>
            ))}
          </select>
        </div>
        {selectedFormData.bodyFatPercentage !== null && (
          <div className="input-group input-group--numbers">
            <label htmlFor="body-fat-percentage">
              <FormattedMessage id="calculate.placeholder.body.fat" />
              , %:
            </label>
            <input
              type="number"
              id="body-fat-percentage"
              name="bodyFatPercentage"
              required
              min={3}
              value={selectedFormData.bodyFatPercentage}
              onChange={handleBaseInputChange}
            />
          </div>
        )}
        <div className="input-group input-group--numbers">
          <label htmlFor="adjusting-cal">
            <FormattedMessage id="calculate.placeholder.adjusting" />
            , %:
          </label>
          <input
            placeholder="%"
            type="number"
            id="adjusting-cal"
            name="adjusting-cal"
            value={selectedFormData.adjustingCalories}
            disabled
          />
        </div>
      </div>
      <GradientButton
        type="submit"
        messageId="calculate.button"
      />
      <button
        className="calculator__reset"
        type="reset"
        onClick={handleReset}
      >
        <FormattedMessage id="calculate.button.reset" />
        <img
          src={Reset}
          alt="Reset inputs"
        />
      </button>
    </form>
  );
};

export default CalculatorForm;
