import { FormikProps } from 'formik';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../../../hook/useAppSelector';
import { UserProfile } from '../../../../../service/user/userService';
import FieldItem from '../../../../../component/form/formField/typesField/FieldItem';

interface ParametersFormProps {
  formikProps: FormikProps<any>
}

const ParametersForm = ({ formikProps }: ParametersFormProps) => {
  const user: UserProfile = useAppSelector((state) => state.user);
  const currentUser = formikProps.values;

  const parametersFields = [
    {
      label: 'form.label.gender',
      value: formikProps.values.gender,
      type: 'select',
      option: [
        { value: 'MALE', label: 'male.button' },
        { value: 'FEMALE', label: 'female.button' },
      ],
      onChange: (value: string) => formikProps.setValues({ ...currentUser, gender: value }),
      isEdit: true,
    },
    {
      label: 'form.label.birthday',
      value: formikProps.values.birthdayDate,
      type: 'date',
      onChange: (value: string) => formikProps.setValues({ ...currentUser, birthdayDate: value }),
      isEdit: true,
    },
    {
      label: 'form.label.height',
      value: formikProps.values.height,
      type: 'unit',
      unit: formikProps.values.heightUnit,
      option: [
        { value: 'CENTIMETER', label: 'form.option.centimetr' },
        { value: 'INCH', label: 'form.option.inch' },
      ],
      onChange: (value: string) => formikProps.setValues({
        ...currentUser,
        height: parseFloat(value),
      }),
      onChangeUnit: (unit: string) => formikProps.setValues({
        ...currentUser,
        heightUnit: unit,
        height: '',
      }),
      isEdit: true,
    },
    {
      label: 'form.label.weight',
      value: formikProps.values.weight,
      type: 'unit',
      unit: formikProps.values.weightUnit,
      placeholder: '0',
      option: [
        { value: 'KILOGRAM', label: 'form.option.kilogram' },
        { value: 'POUND', label: 'form.option.pound' },
      ],
      onChange: (value: string) => formikProps.setValues({
        ...currentUser,
        weight: parseFloat(value),
      }),
      onChangeUnit: (unit: string) => formikProps.setValues({
        ...currentUser,
        weightUnit: unit,
        weight: '',
        weightGoal: '',
      }),
      isEdit: true,
    },
    {
      label: 'form.label.weightGoal',
      value: formikProps.values.weightGoal,
      type: 'number',
      placeholder: '0',
      onChange: (value: number) => formikProps.setValues({ ...currentUser, weightGoal: value }),
      isEdit: true,
    },
    {
      label: 'form.label.activityLevel',
      value: formikProps.values.activityLevel,
      type: 'select',
      option: [
        { value: 'NOT_ACTIVE', label: 'form.label.activity.1200.text' },
        { value: 'LOW_ACTIVE', label: 'form.label.activity.1375.text' },
        { value: 'MODERATE_ACTIVE', label: 'form.label.activity.1550.text' },
        { value: 'VERY_ACTIVE', label: 'form.label.activity.1725.text' },
        { value: 'EXTREMELY_ACTIVE', label: 'form.label.activity.1900.text' },
      ],
      onChange: (value: string) => formikProps.setValues({ ...currentUser, activityLevel: value }),
      isEdit: true,
    },
  ];

  useEffect(() => {
    formikProps.setValues(user);
  }, [user, formikProps.setValues]);

  return (
    <div className="parameters-form">
      <h2 className="form-title">
        <FormattedMessage id="profile.parameters" />
      </h2>
      {parametersFields.map((field) => (
        <FieldItem
          data-testid={field.label}
          className="form-group"
          key={field.label}
          option={field.option}
          placeholder={field.placeholder}
          type={field.type}
          label={field.label}
          unit={field.unit}
          value={field.value}
          onChange={field.onChange}
          onChangeUnit={field.onChangeUnit}
          isEdit={field.isEdit}
        />
      ))}
    </div>
  );
};

export default ParametersForm;
