import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import ProteinIcon from '../../../../asset/icons/protein.svg';
import CaloriesIcon from '../../../../asset/icons/calories.svg';
import FatIcon from '../../../../asset/icons/fat.svg';
import CarboIcon from '../../../../asset/icons/carbo.svg';
import IconPrepTime from '../../../../asset/icons/MealItem/prep.svg';
import IconCookTime from '../../../../asset/icons/MealItem/cook.svg';
import TagList from '../../../../component/tag/TagList';
import { Tag } from '../../../../common/constant/interface/interfaces';

interface MealItemProps {
  image: string;
  title: string;
  description: string;
  tags: Tag[];
  weight: number;
  cookTime: number;
  prepTime: number;
  kcal: number;
  proteins: number;
  fats: number;
  carbohydrates: number;
  mealId: string;
}

const MealItem = ({
  image,
  title,
  description,
  kcal,
  weight,
  cookTime,
  prepTime,
  tags,
  proteins,
  fats,
  carbohydrates,
  mealId,
}: MealItemProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const navigate = useNavigate();

  const handleReadMore = () => {
    navigate(`${mealId}`);
  };

  return (
    <div className="meal__item">
      <button
        type="button"
        className="meal__item-header"
        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
        data-testid="header-button"
      >
        <div className="meal__item-header-left">
          <img className="meal__item-image" src={image} alt="meal" />
          <div className="meal__item-content-block">
            <div className='meal__title'>
              <h3 className="meal__item-text-bold">
                {title}
              </h3>
              <TagList tags={tags} />
            </div>
            <span className="meal__item-text">
              {kcal}
              {' '}
              <FormattedMessage id="meal.item.kcal" />
            </span>
          </div>
        </div>
        <div className="meal__item-header-right">
          <div id='prep-time-header' className='meal__prep-time prep-time'>
            <div className='prep-time__value'>
              <img src={IconPrepTime} alt="time" />
              {prepTime}
              <FormattedMessage id="meal.time.minutes" />
            </div>
            <p className='prep-time__title'>
              <FormattedMessage id="meal.time.prep" />
            </p>
          </div>
          <div id='cook-time-header' className='meal__cook-time cook-time'>
            <div className='cook-time__value'>
              <img src={IconCookTime} alt="time" />
              {cookTime}
              <FormattedMessage id="meal.time.minutes" />
            </div>
            <p className='cook-time__title'>
              <FormattedMessage id="meal.time.cook" />
            </p>
          </div>
          <div className='meal__weight'>
            {weight}
            <FormattedMessage id="calculate.unit.gram" />
          </div>
        </div>
      </button>
      <div data-testid="accordion-body" className={`meal__item-body ${isAccordionOpen && 'open'}`}>
        <div className="meal__item-description">
          <p>
            {description}
          </p>
          <div id='descr-time' className='meal_time-descr'>
            <div className='meal__prep-time prep-time'>
              <div className='prep-time__value'>
                <img src={IconPrepTime} alt="time" />
                {prepTime}
                <FormattedMessage id="meal.time.minutes" />
              </div>
              <p className='prep-time__title'>
                <FormattedMessage id="meal.time.prep" />
              </p>
            </div>
            <div className='meal__cook-time cook-time'>
              <div className='cook-time__value'>
                <img src={IconCookTime} alt="time" />
                {cookTime}
                <FormattedMessage id="meal.time.minutes" />
              </div>
              <p className='cook-time__title'>
                <FormattedMessage id="meal.time.cook" />
              </p>
            </div>
          </div>
        </div>
        <div className="meal__item-energy-value">
          <div className="meal__item-value-block">
            <span className="meal__item-text-small">
              <FormattedMessage id="meal.item.kcal" />
            </span>
            <span className="meal__item-text-bold-nutr">
              <img src={CaloriesIcon} alt="Calories" />
              {kcal}
            </span>
          </div>
          <div className="meal__item-value-block">
            <span className="meal__item-text-small">
              <FormattedMessage id="meal.item.proteins" />
            </span>
            <span className="meal__item-text-bold-nutr">
              <img src={ProteinIcon} alt="Proteins" />
              {proteins}
            </span>
          </div>
          <div className="meal__item-value-block">
            <span className="meal__item-text-small">
              <FormattedMessage id="meal.item.fats" />
            </span>
            <span className="meal__item-text-bold-nutr">
              <img src={FatIcon} alt="Fats" />
              {fats}
            </span>
          </div>
          <div className="meal__item-value-block">
            <span className="meal__item-text-small">
              <FormattedMessage id="calculate.placeholder.carbs" />
            </span>
            <span className="meal__item-text-bold-nutr">
              <img src={CarboIcon} alt="Carbohydrates" />
              {carbohydrates}
            </span>
          </div>
        </div>
        <div className="meal__item-bottom">
          <button className="meal__item-read-more" onClick={handleReadMore}>
            <FormattedMessage id="readMore.button" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MealItem;
