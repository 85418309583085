import { FormattedMessage } from 'react-intl';
import Husky from '../../../component/husky/Husky';

interface FeedbackSentProps {
  className?: string;
}

const FeedbackSent = ({ className }: FeedbackSentProps) => (
  <div data-testid="feedback-sent" className={`feedback-form-sent ${className}`}>
    <Husky state="happy" />
    <h3>
      <FormattedMessage id="feedback.send.title1" />
      <br />
      <FormattedMessage id="feedback.send.title2" />
    </h3>
    <p>
      <FormattedMessage id="feedback.send.paragraph" />
    </p>
  </div>
);

export default FeedbackSent;
