import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactCountryFlag from 'react-country-flag';

interface FieldItemProps {
  value: string | number
  label: any
  code?: any
  type?: string
  name?: string
  title?: string
  placeholder?: string
  unit?: string
  option?: { value: string; label: string }[]
  isEdit?: boolean
  className?: string
  onChange?: (value: any) => void
  onChangeUnit?: (value: any) => void
  onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FieldItem = ({
  label,
  value,
  title,
  name,
  placeholder,
  unit,
  type = 'text',
  option = [],
  isEdit = false,
  code,
  className,
  onChange,
  onChangeUnit,
  onChangeInput,
}: FieldItemProps) => {
  const getCountryCode = (code: string) => {
    if (code.split('-')[0] === 'uk' || code === 'ua') {
      return 'UA';
    }
    return 'US';
  };

  const renderInput = () => {
    if (type === 'radio') {
      return option.map((field) => (
        <label key={field.value} htmlFor={field.value}>
          <input
            id={field.value}
            type="radio"
            value={field.value}
            checked={value === field.value}
            disabled={!isEdit}
            onChange={(e) => onChange?.(e.target.value)}
          />
          <FormattedMessage id={field.label} />
        </label>
      ));
    }

    if (type === 'select') {
      return (
        <select
          id={label}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
          disabled={!isEdit}
        >
          {option.map((field) => (
            <option key={field.label} value={field.value.split('-')[0]}>
              <FormattedMessage id={field.label} />
            </option>
          ))}
        </select>
      );
    }
    if (type === 'unit') {
      return (
        <div className="unit-wrapper">
          <input
            id={label}
            type="number"
            placeholder="0"
            value={value}
            onChange={(e) => onChange?.(e.target.value)}
            disabled={!isEdit}
            className="unit-wrapper__input"
          />
          <select
            value={unit}
            onChange={(e) => onChangeUnit?.(e.target.value)}
            disabled={!isEdit}
            className="unit-wrapper__select"
          >
            {option.map((field) => (
              <option key={field.label} value={field.value}>
                <FormattedMessage id={field.label} />
              </option>
            ))}
          </select>
        </div>
      );
    }
    if (type === 'name' || type === 'email') {
      return (
        <input
          id={label}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          title={title}
          onChange={onChangeInput}
          disabled={!isEdit}
          required
        />
      );
    }
    if (!isEdit) {
      return (
        <span title={title}>
          {value}
        </span>
      );
    }

    return (
      <input
        id={label}
        type={type}
        value={value}
        placeholder={placeholder}
        title={title}
        onChange={(e) => onChange?.(e.target.value)}
        disabled={!isEdit}
      />
    );
  };

  return (
    <div className={className}>
      <label htmlFor={label}>
        <FormattedMessage id={label} />
      </label>
      <div>
        {renderInput()}
        {code && (
          <ReactCountryFlag
            style={{
              width: '40px',
              height: '20px',
            }}
            countryCode={getCountryCode(code)}
            svg
          />
        )}
      </div>
    </div>
  );
};

export default FieldItem;
