import React, { useCallback, useEffect, useState } from 'react';
import '../../AddMealsModal.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../../../hook/useAppDispatch';
import LoadingSpinner from '../../../../../../component/spinner/LoadingSpinner';
import { useAppSelector } from '../../../../../../hook/useAppSelector';
import { getIngredients, MyRationItemContent, searchIngredient } from '../../../../../../redux/slice/myRationSlice';
import {
  Ingredient, MealTypeEnum, Nutrition, Unit,
} from '../../../../../../common/constant/interface/interfaces';
import languageService from '../../../../../../service/language/languageService';
import { calculateNutrition } from '../../../../../../service/myRation/calculateNutrition';
import AddIngredientItem from './AddIngredientItem';

const INGREDIENT_PAGINATION_COUNT_SIZE = 10;

const mapMealToSelectedItem = (
  day: string,
  food: string,
  ingredient: Ingredient,
  unit: Unit,
  nutrition: Nutrition,
): MyRationItemContent => ({
  day,
  language: languageService.getCurrentLocale(),
  foodType: food,
  item: {
    ingredientId: ingredient.id,
    name: ingredient.name,
    language: languageService.getCurrentLocale(),
    isEaten: false,
    nutrition,
    unit: {
      id: unit.id,
      name: unit.name,
      value: unit.value,
    },
    type: MealTypeEnum.Ingredient,
  },
});

interface AddIngredientItemsProps {
    selectedItems: MyRationItemContent[];
    setSelectedItems: (items: MyRationItemContent[]) => void;
    day: string;
    foodType: string;
}

const AddIngredientItemList = ({
  selectedItems, setSelectedItems, day, foodType,
}: AddIngredientItemsProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValueByName, setSearchValueByName] = useState('');

  const allIngredient = useAppSelector((state) => (
    state.myRation.ingredient.allIngredient.content));
  const nextCursorId = useAppSelector((state) => (
    state.myRation.ingredient.allIngredient.nextCursorId));
  const searchResultIngredient = useAppSelector((state) => (
    state.myRation.ingredient.searchResult.content));
  const searchNextCursorId = useAppSelector((state) => (
    state.myRation.ingredient.searchResult.nextCursorId));
  const ingredientsToDisplay = searchValueByName ? searchResultIngredient : allIngredient;

  useEffect(() => {
    if (allIngredient.length === 0) {
      setIsLoading(true);
      dispatch(getIngredients({
        cursorId: '',
        size: INGREDIENT_PAGINATION_COUNT_SIZE,
      }))
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, allIngredient.length]);

  const debouncedSearch = useCallback(debounce(async (searchValueByName: string) => {
    if (searchValueByName === '') return;
    setIsLoading(true);
    try {
      await dispatch(searchIngredient({
        cursorId: '',
        size: INGREDIENT_PAGINATION_COUNT_SIZE,
        searchParam: `name=${searchValueByName}`,
      }));
    } finally {
      setIsLoading(false);
    }
  }, 500), [dispatch]);

  useEffect(() => {
    debouncedSearch(searchValueByName);
  }, [searchValueByName, debouncedSearch]);

  const loadMore = () => {
    if (searchValueByName) {
      dispatch(searchIngredient({
        cursorId: searchNextCursorId,
        size: INGREDIENT_PAGINATION_COUNT_SIZE,
        searchParam: '',
      }));
    } else {
      dispatch(getIngredients({
        cursorId: nextCursorId,
        size: INGREDIENT_PAGINATION_COUNT_SIZE,
      }));
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValueByName(e.target.value);
  };

  const handleSelectItem = (params:{
    ingredient: Ingredient,
    selectedUnitName: string,
    selectedValue: number,
  }) => {
    const { ingredient, selectedUnitName, selectedValue } = params;
    const { unit } = (ingredient.units.find((el) => el.unit.name === selectedUnitName)!);
    const nutrition: Nutrition = calculateNutrition(
      ingredient.units[0].nutrition,
      selectedUnitName,
      ingredient.units[0].unit.value,
      selectedValue,
    );
    const selectedItem = mapMealToSelectedItem(day, foodType, ingredient, {
      ...unit,
      value: Number(selectedValue),
      name: selectedUnitName,
    }, nutrition);
    const isAlreadySelected = selectedItems
      .find((el) => el.item.ingredientId === selectedItem.item.ingredientId);

    setSelectedItems(isAlreadySelected
      ? selectedItems.filter((item) => item.item.ingredientId !== ingredient.id)
      : [...selectedItems, selectedItem]);
  };

  const updateSelectedItem = (params:{
    ingredient: Ingredient,
    selectedUnitName: string,
    selectedValue: number,
  }) => {
    const { ingredient, selectedUnitName, selectedValue } = params;
    const nutrition: Nutrition = calculateNutrition(
      ingredient.units[0].nutrition,
      selectedUnitName,
      ingredient.units[0].unit.value,
      selectedValue,
    );

    const itemIndex = selectedItems.findIndex((item) => item.item.ingredientId === ingredient.id);
    if (itemIndex === -1) return;

    const updatedItems = [...selectedItems];
    updatedItems[itemIndex] = {
      ...selectedItems[itemIndex],
      item: {
        ...selectedItems[itemIndex].item,
        unit: {
          ...selectedItems[itemIndex].item.unit,
          name: selectedUnitName,
          value: selectedValue,
        },
        nutrition,
      },
    };

    setSelectedItems(updatedItems);
  };

  const renderedContent = (ingredients: Ingredient[]) => {
    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (ingredients.length === 0) {
      return (
        <div className="meals-not-found">
          <FormattedMessage id="dashboard.modal.button.noItemsFound" />
        </div>
      );
    }

    return ingredients.map((ingredient) => {
      const selected = selectedItems.some((item) => item.item.ingredientId === ingredient.id);

      return (
        <AddIngredientItem
          key={ingredient.id}
          ingredient={ingredient}
          selected={selected}
          value={ingredient.units[0].unit.value}
          unitName={ingredient.units[0].unit.name}
          onValueChange={updateSelectedItem}
          onUnitChange={updateSelectedItem}
          onSelect={handleSelectItem}
        />
      );
    });
  };

  return (
    <>
      <input
        data-testid="search-ingredient-input"
        type="search"
        className="modal-meal-search"
        placeholder="Search"
        name="modal-meal-search"
        id="modal-meal-search"
        autoComplete="off"
        autoCorrect="off"
        value={searchValueByName}
        onChange={handleSearchChange}
      />
      <div className="tab-items">
        <InfiniteScroll
          dataLength={ingredientsToDisplay.length}
          next={loadMore}
          hasMore={!!(searchValueByName ? searchNextCursorId : nextCursorId)}
          height="350px"
          className="tab-items-infiniteScroll"
          loader={<LoadingSpinner />}
        >
          {renderedContent(ingredientsToDisplay)}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default AddIngredientItemList;
