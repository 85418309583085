import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from '../../navBar/NavBar';
import Aside from '../../aside/Aside';
import ContentWrapper from '../contentWrapper/ContentWrapper';
import FeedbackForm from '../../../container/feedback/FeedbackForm';

const AppLayout = () => (
  <>
    <NavBar />
    <Aside />
    <FeedbackForm />
    <ContentWrapper>
      <Outlet />
    </ContentWrapper>
  </>
);

export default AppLayout;
