import { useState } from 'react';
import './TagList.scss';
import { Tag } from '../../common/constant/interface/interfaces';

interface TagsProps {
    tags: Tag[];
}

const TagList = ({ tags }: TagsProps) => {
  const [isTagVisible, setIsTagVisible] = useState(false);

  const shouldRenderTagList = tags && tags.length > 0;

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <button
      type="button"
      className="tag"
      onMouseEnter={() => shouldRenderTagList && setIsTagVisible(true)}
      onMouseLeave={() => shouldRenderTagList && setIsTagVisible(false)}
      onClick={handleClick}
      data-testid="tag-button"
    >
      <span>#</span>
      {shouldRenderTagList && (
        <ul className={`tag__list ${isTagVisible ? 'visible' : ''}`} data-testid="tag-list">
          {tags.map((tag) => (
            <li
              key={tag.id}
              className="tag__item"
              data-testid={`tag-item-${tag.name}`}
            >
              <span
                style={{ backgroundColor: tag.color }}
              />
              {tag.name}
            </li>
          ))}
        </ul>
      )}
    </button>
  );
};

export default TagList;
