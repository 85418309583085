import React from 'react';
import './NutritionFilter.scss';
/* eslint-disable */
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
/* eslint-enable */

export interface KeyValuePair {
  key: string;
  availableValue: number;
  selectedValue: number
}

interface NutritionFilterProps {
  min: KeyValuePair;
  max: KeyValuePair;
  title: string;
  onChange: (...params: { key: string; value: any }[]) => void
}

const NutritionFilter: React.FC<NutritionFilterProps> = ({
  min, max, title, onChange,
}) => {
  const handleMinChange = (minValue: number) => {
    onChange({ key: min.key, value: minValue });
  };

  const handleMaxChange = (maxValue: number) => {
    onChange({ key: max.key, value: maxValue });
  };

  const handleSliderChange = (value: number | number[]) => {
    if (Array.isArray(value)) {
      onChange(
        { key: max.key, value: value[1] },
        { key: min.key, value: value[0] },
      );
    } else {
      handleMaxChange(value);
    }
  };

  return (
    <div className="nutrition-filter">
      <h3 className="nutrition-filter__title">{title}</h3>
      <div className="nutrition-filter__inputs">
        <input
          type="number"
          value={min.selectedValue}
          onChange={(e) => handleMinChange(Number(e.target.value))}
        />
        <span />
        <input
          type="number"
          value={max.selectedValue}
          onChange={(e) => handleMaxChange(Number(e.target.value))}
        />
      </div>
      <Slider
        min={min.availableValue}
        max={max.availableValue}
        range
        value={[min.selectedValue, max.selectedValue]}
        onChange={handleSliderChange}
      />
    </div>
  );
};

export default NutritionFilter;
