import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../hook/useAppDispatch';
import { useAppSelector } from '../../hook/useAppSelector';
import {
  getMyRationForDay, PostMyRationItem, postMyRationItemsOnDay, resetState,
} from '../../redux/slice/myRationSlice';
import languageService from '../../service/language/languageService';
import { setMessage } from '../../redux/slice/messageSlice';
import { convertToErrorMessage } from '../../service/message/messageConverter';
import CalendarDatePicker, { DATE_PICKER_FORMAT } from '../../container/dashboard/component/calendar/CalendarDatePicker';
import LoadingSpinner from '../spinner/LoadingSpinner';
import MyRationsList from '../../container/dashboard/component/myRation/MyRationsList';
import './AddMealItemsToMyRation.scss';
import { Ingredient, Meal, MealTypeEnum } from '../../common/constant/interface/interfaces';

interface AddMealItemsToMyRationProps {
    item: Meal | Ingredient
    itemType: MealTypeEnum.Meal | MealTypeEnum.Ingredient
}

const AddMealItemsToMyRation = ({ item, itemType }: AddMealItemsToMyRationProps) => {
  const dispatch = useAppDispatch();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const formatSelectedDate = format(selectedDate, DATE_PICKER_FORMAT);
  const myRations = useAppSelector((store) => store.myRation.myRations);

  const findRationOnDay = myRations.find((item) => item.day === formatSelectedDate);

  useEffect(() => {
    if (!findRationOnDay) {
      dispatch(getMyRationForDay(formatSelectedDate));
    }
    return () => {
      dispatch(resetState());
    };
  }, []);

  const getMyRationOnDay = async () => {
    if (!findRationOnDay) {
      setIsLoading(true);
      await dispatch(getMyRationForDay(formatSelectedDate))
        .unwrap()
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const createMealItemData = (foodType: string): PostMyRationItem['content'][0] => ({
    day: formatSelectedDate,
    foodType,
    language: languageService.getCurrentLocale(),
    item: {
      mealId: item.id,
      name: item.name,
      language: languageService.getCurrentLocale(),
      nutrition: item.units[0].nutrition,
      unit: {
        id: item.units[0].unit.id,
        name: item.units[0].unit.name,
        value: item.units[0].unit.value,
      },
      type: MealTypeEnum.Meal,
    },
  });

  const createIngredientItemData = (foodType: string): PostMyRationItem['content'][0] => ({
    day: formatSelectedDate,
    foodType,
    language: languageService.getCurrentLocale(),
    item: {
      ingredientId: item.id,
      name: item.name,
      language: languageService.getCurrentLocale(),
      nutrition: item.units[0].nutrition,
      unit: {
        id: item.units[0].unit.id,
        name: item.units[0].unit.name,
        value: item.units[0].unit.value,
      },
      type: MealTypeEnum.Ingredient,
    },
  });

  const handleAddMealsItem = (collectSelectedItemData: PostMyRationItem) => {
    dispatch(postMyRationItemsOnDay({ day: formatSelectedDate, items: collectSelectedItemData }))
      .unwrap()
      .catch((error: any) => {
        dispatch(setMessage(convertToErrorMessage(error)));
      });
  };

  const addMealsItem = (foodType: string) => {
    const collectSelectedItemData = itemType === MealTypeEnum.Meal
      ? {
        language: languageService.getCurrentLocale(),
        content: [{ ...createMealItemData(foodType) }],
      }
      : {
        language: languageService.getCurrentLocale(),
        content: [{ ...createIngredientItemData(foodType) }],
      };

    handleAddMealsItem(collectSelectedItemData);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="loading">
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <MyRationsList
        myRationOnDay={findRationOnDay}
        addMealToRation={addMealsItem}
        itemId={item.id}
      />
    );
  };

  return (
    <>
      <CalendarDatePicker
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        getMyRationOnDay={getMyRationOnDay}
      />
      <h2 className="title-side">
        <FormattedMessage id="dashboard.title.myRation" />
      </h2>
      <div className="content-wrapper">
        {renderContent()}
      </div>
    </>
  );
};

export default AddMealItemsToMyRation;
