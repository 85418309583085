import { FormikProps } from 'formik';
import { AxiosError } from 'axios';
import {
  REGISTRATION_FORM_CONF,
  registrationValidationSchemas,
} from './schema/registration/registrationSchema';
import userService from '../user/userService';
import { RegistrationFormValues } from '../../container/registration/Registration';

interface BackendError {
  target: keyof RegistrationFormValues;
  message: string;
}

const populateErrorMessageFromResponseToFormik = (
  errors: BackendError[],
  formikProps: FormikProps<RegistrationFormValues>,
) => {
  errors.map((err) => formikProps.setFieldError(err.target, err.message));
};

export const validateRegistrationForm = async (
  formikProps: FormikProps<RegistrationFormValues>,
  step: number,
  fieldsToValidateOnBackend: Array<keyof RegistrationFormValues>,
): Promise<void> => {
  const { values } = formikProps;
  await registrationValidationSchemas[step].validate(values, REGISTRATION_FORM_CONF);

  const result = fieldsToValidateOnBackend.reduce((acc: Partial<RegistrationFormValues>, field) => {
    if (field in values) {
      // @ts-ignore
      acc[field] = values[field];
    }
    return acc;
  }, {});

  await userService.validateUserRegistrationFormData(result)
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        populateErrorMessageFromResponseToFormik(err.response.data.errors, formikProps);
      }

      throw err;
    });
};

const validationService = {
  validateRegistrationForm,
};

export default validationService;
