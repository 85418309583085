import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'formik';
import FormField from '../../../../component/form/formField/formikField/FormField';
import doneSvg from '../../../../asset/icons/done.svg';
import { Button, ButtonType } from '../../../../component/form/button/Button';
import { useAppDispatch } from '../../../../hook/useAppDispatch';
import {
  ForgotPasswordFormType,
  ForgotPasswordStepProps,
  TIMER_SHOW_MESSAGE,
} from '../ForgotPassword';
import { APP_PATH } from '../../../../common/constant/pathConstant';
import authService from '../../../../service/auth/authService';
import { setMessage } from '../../../../redux/slice/messageSlice';
import { convertToErrorMessage } from '../../../../service/message/messageConverter';
import { forgotPasswordSchema } from '../../../../service/validation/schema/login/forgotPasswordSchema';
import { REGISTRATION_FORM_CONF } from '../../../../service/validation/schema/registration/registrationSchema';

const ForgotPasswordStep2 = ({ formikProps, handleNextStep }: ForgotPasswordStepProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccesMessage, setShowSuccesMessage] = useState<boolean>(false);
  let timer: NodeJS.Timeout;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (showSuccesMessage) {
      timer = setTimeout(() => setShowSuccesMessage(false), TIMER_SHOW_MESSAGE);
    }

    return () => clearTimeout(timer);
  }, [showSuccesMessage]);

  const resetPassword = async (values: ForgotPasswordFormType) => {
    setLoading(true);
    try {
      const { username, verificationCode, password } = values;
      await forgotPasswordSchema.validate(values, REGISTRATION_FORM_CONF);
      await authService
        .forgotPasswordSubmit(username, verificationCode, password)
        .then(() => {
          setShowSuccesMessage(true);
          timer = setTimeout(() => navigate(APP_PATH.LOGIN), TIMER_SHOW_MESSAGE);
        })
        .catch((error: any) => {
          dispatch(setMessage(convertToErrorMessage(error)));
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error: any) {
      dispatch(setMessage(convertToErrorMessage(error)));
      setLoading(false);
    }
  };
  return (
    <Form aria-controls="form" className="window__form" data-testid="form-forgotPassword">
      {showSuccesMessage ? (
        <p className="confirm-message">
          <img src={doneSvg} alt="icon" />
          <FormattedMessage id="profile.update.password" />
        </p>
      ) : (
        <div>
          <p className="window__subtitle forgot__title">
            <FormattedMessage id="form.label.enter.verificationCodeNew" />
          </p>
          <div className="forgot__form">
            <FormField placeholder="form.label.verificationCode" name="verificationCode" />
            <FormField placeholder="form.label.newPassword" name="password" type="password" />
            <FormField
              placeholder="form.label.confirmNewPassword"
              name="confirmPassword"
              type="password"
            />
            <Button
              type={ButtonType.BUTTON}
              loading={loading}
              label="form.label.updatePassword"
              onClick={() => resetPassword(formikProps.values)}
            />
          </div>
        </div>
      )}
    </Form>
  );
};
export default ForgotPasswordStep2;
