import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AdvancedSettingsIcon from '../../../../../asset/icons/setting/advanced-settingd.svg';
import DeleteAccountButton from '../DeleteAccountButton';
import './ExpandableAdvancedSettingList.scss';
import userService from '../../../../../service/user/userService';
import ConfirmationModal from '../../../../../component/confirmationModal/ConfirmationModal';
import { useAppSelector } from '../../../../../hook/useAppSelector';
import { convertToErrorMessage } from '../../../../../service/message/messageConverter';
import { setMessage } from '../../../../../redux/slice/messageSlice';
import { useAppDispatch } from '../../../../../hook/useAppDispatch';

const ExpandableAdvancedSettingList = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const email = useAppSelector((store) => store.user.email);
  const ExpandButtonRef = useRef<HTMLDivElement>(null);

  const confirmationModalTitle = intl.formatMessage({ id: 'confirmation.delete.acc.title' });

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (ExpandButtonRef.current && !ExpandButtonRef.current.contains(event.target as Node)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  const deleteAccountAndShowModal = async () => {
    setIsLoading(true);
    try {
      await userService.deleteUserAccount();
      setIsConfirmationModalOpen(true);
    } catch (error: any) {
      dispatch(setMessage(convertToErrorMessage(error)));
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => (
    isConfirmationModalOpen && (
      <ConfirmationModal
        handleClose={() => setIsConfirmationModalOpen(false)}
        title={confirmationModalTitle}
        showDeleteAccount
        email={email}
      />
    )
  );

  return (
    <>
      <div ref={ExpandButtonRef} className='advanced-settings'>
        <button
          data-testid="advanced-settings-btn"
          className="advanced-settings__button setting-btn"
          type="button"
          onClick={() => setIsVisible(!isVisible)}
        >
          <FormattedMessage id="profile.settings.advanced" />
          <img className={`${isVisible ? 'active' : ''}`} src={AdvancedSettingsIcon} alt="Advanced Settings" />
        </button>
        <ul data-testid='advanced-settings-list' className={`advanced-settings__list ${isVisible ? 'active' : ''}`}>
          <li>
            <DeleteAccountButton
              isLoading={isLoading}
              deleteRequestAndShowModal={deleteAccountAndShowModal}
            />
          </li>
        </ul>
      </div>
      {renderContent()}
    </>
  );
};

export default ExpandableAdvancedSettingList;
