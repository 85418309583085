import '../../../style/scss/style.scss';
import '../Registration.scss';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import backBtnImage from '../../../asset/icons/back-arrow.svg';
import { RegistrationFormValues, RegistrationStepProps } from '../Registration';
import { useAppDispatch } from '../../../hook/useAppDispatch';
import { setMessage } from '../../../redux/slice/messageSlice';
import { APP_PATH } from '../../../common/constant/pathConstant';
import userService from '../../../service/user/userService';
import { convertToErrorMessage } from '../../../service/message/messageConverter';
import authService, { CurrentUser } from '../../../service/auth/authService';
import { RootState } from '../../../redux';
import { setRegistrationCompleted } from '../../../redux/slice/authSlice';
import validationService from '../../../service/validation/validationService';
import StepsTracker from '../../../component/stepsTracker/StepsTracker';

const FIELD_NAMES_TO_BACKEND_VALIDATE: Array<keyof RegistrationFormValues> = ['activityLevel'];

const activityLevels = [
  {
    value: 'NOT_ACTIVE',
    coefficient: '1.200',
    textIntl: 'form.label.activity.1200',
    titleIntl: 'form.label.activity.1200.text',
  },
  {
    value: 'LOW_ACTIVE',
    coefficient: '1.375',
    textIntl: 'form.label.activity.1375',
    titleIntl: 'form.label.activity.1375.text',
  },
  {
    value: 'MODERATE_ACTIVE',
    coefficient: '1.550',
    textIntl: 'form.label.activity.1550',
    titleIntl: 'form.label.activity.1550.text',
  },
  {
    value: 'VERY_ACTIVE',
    coefficient: '1.725',
    textIntl: 'form.label.activity.1725',
    titleIntl: 'form.label.activity.1725.text',
  },
  {
    value: 'EXTREMELY_ACTIVE',
    coefficient: '1.900',
    textIntl: 'form.label.activity.1900',
    titleIntl: 'form.label.activity.1900.text',
  },
];

function RegistrationStep6({
  onPrevStep,
  formikProps,
  loading,
  currentStep,
  setLoading,
}: Readonly<RegistrationStepProps>) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user: CurrentUser = useSelector((state: RootState) => state.auth.login.currentUser)!;

  const callLoading = (toggle: boolean) => {
    if (setLoading) {
      setLoading(toggle);
    }
  };

  const handleValidateAndSubmit = async () => {
    callLoading(true);
    try {
      await validationService.validateRegistrationForm(
        formikProps,
        currentStep,
        FIELD_NAMES_TO_BACKEND_VALIDATE,
      );
      const userData = { ...formikProps.values, email: user.email, username: user.username };
      userService.createUser(userData)
        .then(() => {
          dispatch(setRegistrationCompleted());
          authService.setCurrentUserCompletedRegistration();
          navigate(APP_PATH.APP_PROFILE);
        })
        .catch((error: any) => {
          dispatch(setMessage(convertToErrorMessage(error)));
          callLoading(false);
        });
    } catch (error: any) {
      dispatch(setMessage(convertToErrorMessage(error)));
      callLoading(false);
    }
  };

  return (
    <div className="window registration-window registration-window--activity registration-window--small-padding">
      <StepsTracker currentStep={currentStep - 1} />
      <button
        data-testid="button-back"
        type="button"
        onClick={onPrevStep}
        className="window__back-btn back-btn"
      >
        <img alt="back" className="back-btn__picture" src={backBtnImage} />
      </button>
      <h2 className="window__title registration-window__title registration-window__title--small">
        <FormattedMessage id="form.label.yourActivity" />
      </h2>
      <div className="activity-form form-window">
        {activityLevels.map((level, index) => (
          <div className="activity-form__item item-activity" key={index}>
            <input
              type="radio"
              checked={formikProps.values.activityLevel === level.value}
              name="activityLevel"
              id={`coefficient-${index + 1}`}
              data-testid={`coefficient-${index + 1}`}
              value={level.value}
              onChange={(e) => formikProps.setFieldValue('activityLevel', e.target.value)}
            />
            <label htmlFor={`coefficient-${index + 1}`} className="item-activity__label">
              <span className="item-activity__coefficient"><FormattedMessage id={level.titleIntl} /></span>
              <p className="item-activity__description">
                <FormattedMessage id={level.textIntl} />
              </p>
            </label>
          </div>
        ))}
        <button
          onClick={handleValidateAndSubmit}
          disabled={loading}
          type="submit"
          className="activity-form__btn window-btn"
          data-testid="submit-all-form"
        >
          {loading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            <FormattedMessage id="next.button" />
          )}
        </button>
      </div>
    </div>
  );
}

export default RegistrationStep6;
