import React, { useState } from 'react';
import './AddMealsModal.scss';
import { FormattedMessage } from 'react-intl';
import ModalTypeButton from './modalButton/ModalTypeButton';
import AddMealItemList from './tab/meal/AddMealItemList';
import AddIngredientItemList from './tab/ingredient/AddIngredientItemList';
import { MyRationItemContent, postMyRationItemsOnDay } from '../../../../redux/slice/myRationSlice';
import { useAppDispatch } from '../../../../hook/useAppDispatch';
import { setMessage } from '../../../../redux/slice/messageSlice';
import { convertToErrorMessage } from '../../../../service/message/messageConverter';
import languageService from '../../../../service/language/languageService';

const MEALS = 'nav.menu.meals';
const INGREDIENTS = 'nav.menu.ingredients';

const foodTypesButton = [{ name: MEALS }, { name: INGREDIENTS }];

interface AddMealsModalProps {
  selectedDate: string;
  foodType: string;
  handleCloseModal: () => void;
}

const AddMealsModal: React.FC<AddMealsModalProps> = ({
  selectedDate,
  foodType,
  handleCloseModal,
}) => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<string>(MEALS);
  const [selectedMeals, setSelectedMeals] = useState<MyRationItemContent[]>([]);
  const [selectedIngredients, setSelectedIngredients] = useState<MyRationItemContent[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const activeTabToComponent: { [key: string]: React.ReactNode } = {
    [MEALS]: (
      <AddMealItemList
        day={selectedDate}
        foodType={foodType}
        selectedItems={selectedMeals}
        setSelectedItems={setSelectedMeals}
      />
    ),
    [INGREDIENTS]: (
      <AddIngredientItemList
        day={selectedDate}
        foodType={foodType}
        selectedItems={selectedIngredients}
        setSelectedItems={setSelectedIngredients}
      />
    ),
  };

  const handleSubmit = () => {
    setIsLoading(true);
    dispatch(
      postMyRationItemsOnDay({
        day: selectedDate,
        items: {
          language: languageService.getCurrentLocale(),
          content: [...selectedMeals, ...selectedIngredients],
        },
      }),
    )
      .unwrap()
      .catch((error: any) => {
        dispatch(setMessage(convertToErrorMessage(error)));
      })
      .finally(() => {
        setIsLoading(false);
        handleCloseModal();
      });
  };

  return (
    <div className="modal-window" id="modal-open">
      <div className="add-meal-window">
        <h2 className="add-meal-window__title">
          <FormattedMessage id="dashboard.modal.title.addRation" />
        </h2>
        <div className="add-meal-window__flex">
          <div className="add-meal-window__meal-or-product">
            {foodTypesButton.map((item) => (
              <ModalTypeButton
                key={item.name}
                name={item.name}
                className={activeTab === item.name ? 'active' : ''}
                onClick={() => setActiveTab(item.name)}
              />
            ))}
          </div>
          <div className="add-meal-window__content content">
            {activeTabToComponent[activeTab]}
            <button className="apply animated-gradient" onClick={handleSubmit}>
              {isLoading ? (
                <span className="spinner-border spinner-border-sm" />
              ) : (
                <FormattedMessage id="dashboard.modal.button.applyRation" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMealsModal;
