import { FormattedMessage } from 'react-intl';
import DeleteAcc from '../../../../asset/icons/setting/trashbox.svg';

interface DeleteAccountButtonProps {
  isLoading: boolean;
  deleteRequestAndShowModal: () => Promise<void>;
}

const DeleteAccountButton = ({
  deleteRequestAndShowModal,
  isLoading,
}: DeleteAccountButtonProps) => (
  <button
    onClick={deleteRequestAndShowModal}
    className='delete-acc setting-btn setting-btn--advanced'
    type='button'
    data-testid="delete-account-btn"
  >
    {isLoading ? (
      <span data-testid="loading-indicator" className='spinner-border spinner-border-sm' />
    ) : (
      <>
        <FormattedMessage id='button.deleteAccount' />
        <div className='delete-acc__wrapper-img'>
          <img
            src={DeleteAcc}
            alt='delete'
          />
        </div>
      </>
    )}
  </button>
);

export default DeleteAccountButton;
