import '../../../style/scss/style.scss';
import '../Registration.scss';
import { FormattedMessage } from 'react-intl';
import { ErrorMessage } from 'formik';
import backBtnImage from '../../../asset/icons/back-arrow.svg';
import DetailsImage from '../../../asset/icons/details.png';
import {
  RegistrationFormValues,
  RegistrationStepProps,
  setRegistrationForNumberField,
} from '../Registration';
import { useAppDispatch } from '../../../hook/useAppDispatch';
import { setMessage } from '../../../redux/slice/messageSlice';
import { convertToErrorMessage } from '../../../service/message/messageConverter';
import validationService from '../../../service/validation/validationService';
import StepsTracker from '../../../component/stepsTracker/StepsTracker';

const FIELD_NAMES_TO_BACKEND_VALIDATE: Array<keyof RegistrationFormValues> = ['weight', 'weightGoal', 'weightUnit'];

const weightUnitsArray = [
  { value: 'KILOGRAM', textIntl: 'form.option.kilogram' },
  { value: 'POUND', textIntl: 'form.option.pound' },
];

function RegistrationStep5({
  onNextStep,
  onPrevStep,
  formikProps,
  currentStep,
}: Readonly<RegistrationStepProps>) {
  const dispatch = useAppDispatch();

  const renderedYourGoalText = <FormattedMessage id="form.label.yourGoal" />;
  const renderedCurrentWeightText = <FormattedMessage id="form.label.currentWeight" />;

  const handleValidateAndSubmit = async () => {
    await validationService.validateRegistrationForm(
      formikProps,
      currentStep,
      FIELD_NAMES_TO_BACKEND_VALIDATE,
    )
      .then(() => {
        if (onNextStep) {
          onNextStep();
        }
      })
      .catch((error) => {
        dispatch(setMessage(convertToErrorMessage(error)));
      });
  };

  const renderErrorMessage = (name: string) => (
    <ErrorMessage name={name} render={(message) => <p className="error-message">{message}</p>} />
  );

  return (
    <div className="window registration-window registration-window--small-padding">
      <StepsTracker currentStep={currentStep - 1} />
      <button
        data-testid="button-back"
        type="button"
        onClick={onPrevStep}
        className="window__back-btn back-btn"
      >
        <img alt="back" className="back-btn__picture" src={backBtnImage} />
      </button>
      <img className="registration-window__measurement-pic" src={DetailsImage} alt="Measure" />
      <h2 className="window__title registration-window__title registration-window__title--small">
        <FormattedMessage id="form.label.enterDetails" />
      </h2>
      <div className="registration-window__measurement-form measurement-form form-window">
        <div className="measurement-form__item measurement-item">
          <label htmlFor="weight">{renderedCurrentWeightText}</label>
          <div className="measurement-form__input-wrapper">
            <input
              data-testid="input-weight"
              type="number"
              name="weight"
              placeholder="0"
              id="weight"
              value={formikProps.values.weight}
              onChange={(e) => formikProps.setFieldValue('weight', setRegistrationForNumberField(e.target.value))}
            />
            <select
              name="weightUnit"
              id="weightUnit"
              value={formikProps.values.weight}
              onChange={(e) => formikProps.setFieldValue('weightUnit', e.target.value)}
            >
              {weightUnitsArray.map(({ value, textIntl }) => (
                <option value={value} key={value}>
                  <FormattedMessage id={textIntl} />
                </option>
              ))}
            </select>
          </div>
          {renderErrorMessage('weight')}
        </div>
        <div className="measurement-form__item measurement-item">
          <label htmlFor="form.label.yourGoal">{renderedYourGoalText}</label>
          <div className="measurement-form__input-wrapper">
            <input
              data-testid="input-weightGoal"
              type="number"
              name="weightGoal"
              placeholder="0"
              id="form.label.yourGoal"
              value={formikProps.values.weightGoal}
              onChange={(e) => formikProps.setFieldValue('weightGoal', setRegistrationForNumberField(e.target.value))}
            />
            <select
              name="unit"
              id="form.label.yourGoal"
              value={formikProps.values.weightUnit}
              onChange={(e) => formikProps.setFieldValue('weightUnit', e.target.value)}
            >
              {weightUnitsArray.map(({ value, textIntl }) => (
                <option value={value} key={value}>
                  <FormattedMessage id={textIntl} />
                </option>
              ))}
            </select>
          </div>
          {renderErrorMessage('weightGoal')}
        </div>
        <button
          onClick={handleValidateAndSubmit}
          type="submit"
          className="registration-window__btn window-btn"
        >
          <FormattedMessage id="next.button" />
        </button>
      </div>
    </div>
  );
}

export default RegistrationStep5;
