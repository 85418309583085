import { forwardRef, useImperativeHandle } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { useAppSelector } from '../../../../hook/useAppSelector';
import { ReactComponent as Reset } from '../../../../asset/icons/filter/reset.svg';
import TimeFiler from '../../../../component/filter/time/TimeFilter';
import Duration from '../../../../asset/icons/MealItem/duration.svg';
import NutritionFilter from '../../../../component/filter/nutrition/NutritionFilter';
import { useAppDispatch } from '../../../../hook/useAppDispatch';
import {
  clearSelectedSearchParam, getAvailableIngredientsToSelect,
  getAvailableMealsToSelect,
  getAvailableTagsToSelect,
  RationSearchSelectedParam,
  searchAvailableIngredientsToSelect,
  searchAvailableMealsToSelect,
  searchAvailableTagsToSelect,
  searchRations,
  setSelectedSearchObject,

} from '../../../../redux/slice/rationSlice';
import rationMapperService from '../../../../service/mapper/ration/rationMapperService';
import DynamicSearchFilterWrapper from '../../../../component/filter/search/wrapper/DynamicSearchFilterWrapper';

const INGREDIENTS_FILTERS_SEARCH_COUNT_SIZE = 10;
const TAGS_FILTERS_SEARCH_COUNT_SIZE = 10;
const MEALS_FILTERS_SEARCH_COUNT_SIZE = 10;

interface RationListFilterProps {
  setShowSearchResult: (showSearchResult: boolean) => void;
  onFilterClick: () => void;
  setIsFilterOpen: (isFilterOpen: boolean) => void;
}

const RationListFilter = forwardRef<any, RationListFilterProps>((props, ref) => {
  const {
    setShowSearchResult,
    onFilterClick,
    setIsFilterOpen,
  } = props;
  const dispatch = useAppDispatch();

  const availableSearchObj = useAppSelector((state) => state.ration.search.availableSearchParam);
  const appliedSearchObj = useAppSelector((state) => state.ration.search.appliedSearchParam);
  const selectedSearchObj = useAppSelector((state) => state.ration.search.selectedSearchParam);
  const ingredients = useAppSelector((state) => state.ration.search.ingredients);
  const tags = useAppSelector((state) => state.ration.search.tags);
  const meals = useAppSelector((state) => state.ration.search.meals);
  const intl = useIntl();

  const isAnyFiltersApplied = () => {
    const searchObj = rationMapperService.mapSelectedSearchObjToRationSearchRequestDto(
      selectedSearchObj,
      availableSearchObj,
    );
    return Object.keys(searchObj).length > 0;
  };

  const handleNameSearch = (query: string) => {
    const appliedFilters = rationMapperService.mapToRationSearchSelectedParam(appliedSearchObj);
    const updatedSearchObj = {
      ...appliedFilters,
      name: query,
    };

    dispatch(setSelectedSearchObject({
      ...selectedSearchObj,
      name: query,
    }));

    dispatch(searchRations({
      selectedParam: updatedSearchObj,
      availableParam: availableSearchObj,
    }));
    if (isAnyFiltersApplied()) {
      setShowSearchResult(true);
    } else {
      setShowSearchResult(false);
    }
  };

  useImperativeHandle(ref, () => ({
    handleNameSearch(query: string) {
      handleNameSearch(query);
    },
  }));

  const handleSearchObjUpdate = (
    ...params: { key: string; value: any }[]
  ) => {
    const updatedSearchObj: RationSearchSelectedParam = { ...selectedSearchObj };

    params.forEach(({
      key,
      value,
    }) => {
      // @ts-ignore
      updatedSearchObj[key] = value;
    });

    dispatch(setSelectedSearchObject(updatedSearchObj));
  };

  const handleSearch = () => {
    if (isMobile) {
      setIsFilterOpen(false);
    }
    dispatch(searchRations({
      selectedParam: selectedSearchObj,
      availableParam: availableSearchObj,
    }));
    setShowSearchResult(true);
  };

  const translate = (id:string) => intl.formatMessage({ id });

  const renderFilters = () => (
    <>
      <div className="ration__time-filters">
        <TimeFiler
          title={translate('ration.duration.title')}
          min={{
            key: 'durationDaysMin',
            availableValue: availableSearchObj.durationDaysMin,
            selectedValue: selectedSearchObj.durationDaysMin,
          }}
          max={{
            key: 'durationDaysMax',
            availableValue: availableSearchObj.durationDaysMax,
            selectedValue: selectedSearchObj.durationDaysMax,
          }}
          timeStep={1}
          icon={<img src={Duration} alt="Time" />}
          onChange={handleSearchObjUpdate}
          timeUnit="days"
        />
      </div>
      <div className="meal__nutrition-filters">
        <NutritionFilter
          min={{
            key: 'calorieMin',
            selectedValue: selectedSearchObj.calorieMin,
            availableValue: availableSearchObj.calorieMin,
          }}
          max={{
            key: 'calorieMax',
            selectedValue: selectedSearchObj.calorieMax,
            availableValue: availableSearchObj.calorieMax,
          }}
          onChange={handleSearchObjUpdate}
          title={translate('dashboard.title.kcal')}
        />
        <NutritionFilter
          min={{
            key: 'proteinMin',
            selectedValue: selectedSearchObj.proteinMin,
            availableValue: availableSearchObj.proteinMin,
          }}
          max={{
            key: 'proteinMax',
            selectedValue: selectedSearchObj.proteinMax,
            availableValue: availableSearchObj.proteinMax,
          }}
          onChange={handleSearchObjUpdate}
          title={translate('meal.item.proteins')}
        />
        <NutritionFilter
          min={{
            key: 'fatMin',
            selectedValue: selectedSearchObj.fatMin,
            availableValue: availableSearchObj.fatsMin,
          }}
          max={{
            key: 'fatMax',
            selectedValue: selectedSearchObj.fatMax,
            availableValue: availableSearchObj.fatsMax,
          }}
          onChange={handleSearchObjUpdate}
          title={translate('dashboard.title.fats')}
        />
        <NutritionFilter
          min={{
            key: 'carboMin',
            selectedValue: selectedSearchObj.carboMin,
            availableValue: availableSearchObj.carboMin,
          }}
          max={{
            key: 'carboMax',
            selectedValue: selectedSearchObj.carboMax,
            availableValue: availableSearchObj.carboMax,
          }}
          onChange={handleSearchObjUpdate}
          title={translate('meal.item.carbs')}
        />
      </div>
      <div className="ration__search-filters">
        <DynamicSearchFilterWrapper
          filterType="ingredients"
          searchObj={ingredients.search}
          availableObj={{
            content: ingredients.availableIngredients,
            cursorId: ingredients.cursorId,
          }}
          selectedObj={selectedSearchObj}
          countSize={INGREDIENTS_FILTERS_SEARCH_COUNT_SIZE}
          handleSearchObjUpdate={handleSearchObjUpdate}
          searchItems={(params) => dispatch(searchAvailableIngredientsToSelect(params))}
          getItems={(params) => dispatch(getAvailableIngredientsToSelect(params))}
        />
        <DynamicSearchFilterWrapper
          filterType="meals"
          searchObj={meals.search}
          availableObj={{ content: meals.availableMeals, cursorId: meals.cursorId }}
          selectedObj={selectedSearchObj}
          countSize={MEALS_FILTERS_SEARCH_COUNT_SIZE}
          handleSearchObjUpdate={handleSearchObjUpdate}
          searchItems={(params) => dispatch(searchAvailableMealsToSelect(params))}
          getItems={(params) => dispatch(getAvailableMealsToSelect(params))}
        />
        <DynamicSearchFilterWrapper
          filterType="tags"
          searchObj={tags.search}
          availableObj={{ content: tags.availableTags, cursorId: tags.cursorId }}
          selectedObj={selectedSearchObj}
          countSize={TAGS_FILTERS_SEARCH_COUNT_SIZE}
          handleSearchObjUpdate={handleSearchObjUpdate}
          searchItems={(params) => dispatch(searchAvailableTagsToSelect(params))}
          getItems={(params) => dispatch(getAvailableTagsToSelect(params))}
        />
      </div>
    </>
  );

  return (
    <>
      <div className="ration__header">
        <button
          type="button"
          className="ration__filters-button ration__filters-button--close-cross"
          onClick={onFilterClick}
          aria-label="ration-filter-button"
          data-testid="ration-mobile-filter-button"
        >
          <span className="cross-icon" />
        </button>
        <h2 className="ration__filters-title">
          <FormattedMessage id="ration.list.filters" />
        </h2>
        <button
          type="button"
          className="ration__reset-button"
          onClick={() => dispatch(clearSelectedSearchParam())}
        >
          <FormattedMessage id="filters.reset" />
          <Reset />
        </button>
      </div>
      {renderFilters()}
      <button
        type="button"
        className="ration__button"
        aria-label="ration__button"
        onClick={handleSearch}
      >
        <FormattedMessage id="apply.button" />
      </button>
    </>
  );
});

export default RationListFilter;
