import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import authService from '../../service/auth/authService';
import { getUserProfile, getWeightProgress } from '../../redux/slice/userSlice';
import { UserProfile } from '../../service/user/userService';
import { useAppSelector } from '../../hook/useAppSelector';
import { useAppDispatch } from '../../hook/useAppDispatch';
import UserAvatar from '../../component/userAvatar/UserAvatar';
import { APP_PATH } from '../../common/constant/pathConstant';
import Facebook from '../../asset/icons/profile/socials-fb.svg';
import Linkedin from '../../asset/icons/profile/socials-in.svg';
import Instagram from '../../asset/icons/profile/socials-inst.svg';
import Telegram from '../../asset/icons/profile/socials-tg.svg';
import ProgressBar from '../../component/progressBar/ProgressBar';
import Publication from './publication/Publication';
import './Profile.scss';
import { AVATAR_SIZES } from '../../common/constant/sizesAvatar';
import Husky from '../../component/husky/Husky';

interface Publication {
  id: number;
  title: string;
  content: string;
}

type IconType = typeof Facebook | typeof Linkedin | typeof Instagram | typeof Telegram;

interface SocialLink {
  platform: string;
  url: string;
  icon: IconType;
}

interface Achievement {
  id: number;
  description: string;
}

const achievements: Achievement[] = [
  { id: 1, description: 'Achieved 10,000 steps in a day' },
  { id: 2, description: 'Lost 5 kg in a month' },
  { id: 3, description: 'Completed 30-day workout challenge' },
];

function Profile() {
  const dispatch = useAppDispatch();
  const { userId } = authService.getCurrentUser();
  const user: UserProfile = useAppSelector((state) => state.user);
  const weightProgress = useAppSelector((state) => state.user.weightProgress);
  const publications: Publication[] = [];
  const intl = useIntl();
  const socials: SocialLink[] = [
    { platform: 'Facebook', url: 'https://www.facebook.com', icon: Facebook },
    { platform: 'Linkedin', url: 'https://www.linkedin.com', icon: Linkedin },
    { platform: 'Instagram', url: 'https://www.instagram.com', icon: Instagram },
    { platform: 'Telegram', url: 'https://www.telegram.org', icon: Telegram },
  ];

  useEffect(() => {
    dispatch(getUserProfile(userId));
    dispatch(getWeightProgress());
  }, [dispatch, userId]);

  const [activeAchievement, setActiveAchievement] = useState(null);
  const handleAchievementClick = (id: any) => {
    setActiveAchievement((prevActiveAchievement) => (prevActiveAchievement === id ? null : id));
  };

  const formatWeightUnit = (unit: string) => {
    let formattedUnit = '';
    if (unit === 'KILOGRAM') {
      formattedUnit = intl.formatMessage({ id: 'form.option.kilogram' });
    } else if (unit === 'POUND') {
      formattedUnit = intl.formatMessage({ id: 'form.option.pound' });
    }
    return formattedUnit;
  };

  const renderPublications = () => {
    if (publications.length === 0) {
      return <Husky state="waiting" />;
    }

    return (
      <ul className="product-database__items">
        {publications.map((publication) => (
          <li key={publication.id} className="product-database__item">
            <Publication />
          </li>
        ))}
      </ul>
    );
  };

  const renderSocialLinks = () => {
    if (!socials || socials.length === 0) {
      return null;
    }

    return (
      <ul className="user-profile__socials">
        {socials.map((social) => (
          <li key={social.platform} className="user-profile__socials-item item-socials">
            <a
              href={social.url}
              className="item-socials__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={social.icon} alt={social.platform} />
            </a>
          </li>
        ))}
      </ul>
    );
  };

  const renderAchievements = () => {
    if (achievements.length === 0) {
      return <p>No achievements yet.</p>;
    }

    return (
      <div className="user-profile__achievements">
        {achievements.map((achievement) => (
          <button
            key={achievement.id}
            className={activeAchievement === achievement.id ? 'active' : ''}
            onClick={() => handleAchievementClick(achievement.id)}
          >
            <span>{achievement.description}</span>
          </button>
        ))}
      </div>
    );
  };

  return (
    <section className="publications profile">
      <h2 className="publications__title">
        <FormattedMessage id="profile.button" />
      </h2>
      <div className="publications__top-container user-publications">
        <div className="user-publications__profile user-profile">
          <div className="user-profile__info">
            <UserAvatar
              className="user-profile__avatar"
              name={user.name}
              srcImage={user.imageUrl}
              size={AVATAR_SIZES.LARGE}
            />
            <div className="user-profile__descr">
              <div className="user-profile__top">
                <h3 className="user-profile__name">{user.name}</h3>
                {renderSocialLinks()}
              </div>
              <hr />
              {renderAchievements()}
            </div>
          </div>
          <Link to={APP_PATH.APP_SETTINGS}>
            <button className="user-profile__button" type="button">
              <FormattedMessage id="edit.button" />
            </button>
          </Link>
        </div>
        <div className="user-publications__goal user-goal">
          <ProgressBar
            currentWeight={user.weight}
            targetWeight={user.weightGoal}
            startingWeight={weightProgress.firstWeight}
            weightUnit={user.weightUnit}
            formatWeightUnit={formatWeightUnit}
          />
        </div>
      </div>
      <div className="publications__product-container product-database">
        <h2 className="product-database__title">
          <FormattedMessage id="profile.publications" />
          <span>{publications.length}</span>
        </h2>
        {renderPublications()}
      </div>
    </section>
  );
}

export default Profile;
