import React, { useState, useEffect, useRef } from 'react';
import './CalendarDayPicker.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as DatePrev } from '../../../asset/icons/dashboard/arrow-left.svg';
import { ReactComponent as DateNext } from '../../../asset/icons/dashboard/arrow-right.svg';

interface CalendarDayProps {
  durationDays: number;
  selectedDay: number;
  setSelectedDay: (day: number) => void;
}

const CalendarDayPicker = ({ selectedDay, setSelectedDay, durationDays }: CalendarDayProps) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const dateContainerRef = useRef<HTMLDivElement>(null);

  const handleDayClick = (day: number) => {
    setSelectedDay(day);
    setIsDatePickerOpen(false);
  };

  const generateOptions = (days: number) => {
    const options = [];
    for (let i = 1; i <= days; i += 1) {
      options.push(
        <button
          key={i}
          type="button"
          onClick={() => handleDayClick(i)}
          className={`date-dropdown-item ${selectedDay === i ? 'active' : ''}`}
        >
          <FormattedMessage id="calendar.title.day" />
          {' '}
          {i}
        </button>,
      );
    }
    return options;
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dateContainerRef.current && !dateContainerRef.current.contains(event.target as Node)) {
        setIsDatePickerOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="date" ref={dateContainerRef}>
      <button
        className="date__prev date-arrows"
        type="button"
        onClick={() => setSelectedDay(selectedDay - 1)}
        disabled={selectedDay <= 1}
        aria-label="Previous Day"
      >
        <DatePrev />
      </button>
      <div className="date__data">
        <button
          type="button"
          className="date-button"
          onClick={toggleDatePicker}
          aria-label={`Selected Day ${selectedDay}`}
        >
          <FormattedMessage id="calendar.title.day" />
          {' '}
          {selectedDay}
        </button>
      </div>
      <button
        className="date__next date-arrows"
        type="button"
        onClick={() => setSelectedDay(selectedDay + 1)}
        disabled={selectedDay >= durationDays}
        aria-label="Next Day"
      >
        <DateNext />
      </button>
      {isDatePickerOpen && (
      <div className="date-dropdown">
        {generateOptions(durationDays)}
      </div>
      )}
    </div>
  );
};

export default CalendarDayPicker;
