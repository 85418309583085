import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { MessageType, clearMessage, setMessage } from '../../redux/slice/messageSlice';
import { login } from '../../redux/slice/authSlice';
import FormField from '../../component/form/formField/formikField/FormField';
import { APP_PATH } from '../../common/constant/pathConstant';
import { useAppDispatch } from '../../hook/useAppDispatch';
import AuthBackgroudLayout from '../../component/layout/authBackgroudLayout/AuthBackgroudLayout';
import FormContainer from '../../component/form/formContainer/FormContainer';
import { Button, ButtonType } from '../../component/form/button/Button';
import backBtnImage from '../../asset/icons/back-arrow.svg';
import './Login.scss';
import '../../asset/font/fonts.scss';
import { loginSchema } from '../../service/validation/schema/login/loginSchema';
import authService from '../../service/auth/authService';

const initialValues = {
  username: '',
  password: '',
};

const USER_IS_NOT_CONFIRMED_EXCEPTION_NAME = 'UserNotConfirmedException';

function Login() {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const handleLogin = (formValue: { username: string; password: string }) => {
    const { username, password } = formValue;
    setLoading(true);

    dispatch(login({ username, password }))
      .unwrap()
      .then((res) => {
        if (res.currentUser?.isCompletedRegistration) {
          navigate(APP_PATH.DASHBOARD);
        } else {
          navigate(APP_PATH.LOGIN_USER_DATA_IS_NOT_FILLED);
        }
      })
      .catch((error: any) => {
        if (error.name === USER_IS_NOT_CONFIRMED_EXCEPTION_NAME) {
          navigate(APP_PATH.LOGIN_EMAIL_IS_NOT_CONFIRMED, {
            replace: true,
            state: {
              username,
              password,
            },
          });
        } else {
          const newMessage = {
            type: MessageType.ERROR,
            text: error.message,
          };
          dispatch(setMessage(newMessage));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function handleSignInFacebookClick() {
    authService.loginWithFacebook();
  }

  function handleSignInGoogleClick() {
    authService.loginWithGoogle();
  }

  return (
    <AuthBackgroudLayout>
      <FormContainer>
        <button
          type="button"
          className="window__back-btn back-btn"
          onClick={() => navigate(APP_PATH.DEFAULT)}
        >
          <img alt="back" className="back-btn__picture" src={backBtnImage} />
        </button>
        <h1 className="window__title">
          <FormattedMessage id="form.label.title" />
        </h1>
        <p className="window__subtitle">
          <FormattedMessage id="form.label.subtitle" />
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={loginSchema}
          onSubmit={handleLogin}
          validateOnChange
        >
          <Form aria-controls="form" className="window__form form-window">
            <FormField placeholder="form.label.username" name="username" />
            <FormField placeholder="form.label.password" name="password" type="password" />
            <Button type={ButtonType.SUBMIT} loading={loading} label="login.button" />
          </Form>
        </Formik>
        <Link to={APP_PATH.LOGIN_FORGOT_PASSWORD} className="window__forgotPassword">
          <FormattedMessage id="form.label.forgotPassword" />
        </Link>
        <div className="window__socials socials">
          <button
            data-testid="google"
            type="button"
            className="socials__item socials__item--google"
            onClick={handleSignInGoogleClick}
          />
          <button
            data-testid="facebook"
            type="button"
            className="socials__item socials__item--facebook"
            onClick={handleSignInFacebookClick}
          />
        </div>
        <div className="window__disabled-acc disabled-acc">
          <p className="disabled-acc__descr">
            <FormattedMessage id="form.label.description" />
          </p>
          <strong>
            <FormattedMessage id="signup.button">
              {(text) => (
                <Link to={APP_PATH.REGISTRATION} className="disabled-acc__link">
                  {text}
                </Link>
              )}
            </FormattedMessage>
          </strong>
        </div>
      </FormContainer>
    </AuthBackgroudLayout>
  );
}

export default Login;
