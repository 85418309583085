import './AppliedRationItem.scss';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import RationBanner from '../../../ration/component/RationBanner/RationBanner';
import { AppliedRation } from '../../../../redux/slice/myRationSlice';
import languageService from '../../../../service/language/languageService';
import TagList from '../../../../component/tag/TagList';
import ApplyRationModal from '../../../ration/addRationModal/ApplyRationModal';
import ModalWindow from '../../../../component/layout/modalWindow/ModalWindow';

interface AppliedRationItemProps {
  ration: AppliedRation;
  onCancelRation: (rationId: string) => void;
  showContinueButton?: boolean;
  showApplyButton?: boolean;
  showCancelButton?: boolean;
}

const AppliedRationItem = ({
  ration,
  onCancelRation,
  showContinueButton = false,
  showApplyButton = false,
  showCancelButton = false,
}: AppliedRationItemProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const locale = languageService.getDateFormatBasedOnLocale();

  return (
    <>
      <div className="ration-item">
        <div className="ration-item__title">
          <h2 className="ration-item__name">{ration.ration.name}</h2>
          <TagList tags={ration.ration.tags} />
        </div>
        <div className="ration-item__banner">
          <RationBanner ration={ration.ration} />
        </div>
        <div className="ration-item__dates dates">
          <div className="dates__start">
            <span>
              <FormattedMessage id="applied.ration.date.start" />
            </span>
            {format(new Date(ration.start), 'dd MMMM yyyy', { locale })}
          </div>
          <div className="dates__end">
            <span>
              <FormattedMessage id="applied.ration.date.end" />
            </span>
            {format(new Date(ration.finish), 'dd MMMM yyyy', { locale })}
          </div>
        </div>
        <div className="ration-item__actions-btn actions-btn">
          {showContinueButton && (
          <button className="actions-btn__continue" onClick={() => setIsModalOpen(true)}>
            <FormattedMessage id="applied.ration.button.continue" />
          </button>
          )}
          {showApplyButton && (
          <button className="actions-btn__apply" onClick={() => setIsModalOpen(true)}>
            <FormattedMessage id="applied.ration.button.again" />
          </button>
          )}
          {showCancelButton && (
          <button className="actions-btn__cancel" onClick={() => onCancelRation(ration.id)}>
            <FormattedMessage id="cancel.button" />
          </button>
          )}
        </div>
      </div>
      {isModalOpen && (
        <ModalWindow handleCloseModal={() => setIsModalOpen(false)}>
          <ApplyRationModal
            setIsModalOpen={setIsModalOpen}
            rationId={ration.ration.id}
            durationDays={ration.ration.durationDays}
          />
        </ModalWindow>
      )}
    </>
  );
};

export default AppliedRationItem;
