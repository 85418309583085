import { FormattedMessage } from 'react-intl';
import './DifficultyFilter.scss';
import { ReactComponent as Arrow } from '../../../asset/icons/filter/select-arrow.svg';

export interface KeyValuePair {
  key: string;
  availableValue: number;
  selectedValue: number
}

interface DifficultyFilterProps {
  onChange: (...params: { key: string; value: any }[]) => void;
  min: number;
  max: KeyValuePair;
}

function DifficultyFilter({
  min,
  max,
  onChange,
}: DifficultyFilterProps) {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    onChange({ key: max.key, value: newValue });
  };

  const handleIncrement = () => {
    if (max.selectedValue + 1 > max.availableValue) {
      return;
    }
    onChange({ key: max.key, value: max.selectedValue + 1 });
  };

  const handleDecrement = () => {
    if (max.selectedValue - 1 < min) {
      return;
    }
    onChange({ key: max.key, value: max.selectedValue - 1 });
  };

  const spans = [];
  for (let i = 0; i < 5; i += 1) {
    spans.push(
      <span
        key={i}
        className={`difficulty__bar-span ${i < max.selectedValue ? 'active' : ''}`}
      />,
    );
  }

  return (
    <div className="difficulty">
      <h4 className="difficulty__title">
        <FormattedMessage id="meal.difficulty.level" />
      </h4>
      <div className="difficulty__input-container">
        <button className="difficulty__button difficulty__button--prev" onClick={handleDecrement}><Arrow /></button>
        <input
          type="number"
          className="difficulty__input"
          value={max.selectedValue}
          onChange={handleInputChange}
          min={min}
          max={max.availableValue}
        />
        <button className="difficulty__button difficulty__button--next" onClick={handleIncrement}><Arrow /></button>
      </div>
      <div className="difficulty__bar">
        {spans}
      </div>
    </div>
  );
}

export default DifficultyFilter;
