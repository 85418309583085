import { FormattedMessage } from 'react-intl';
import AppliedRationItem from '../rationItem/AppliedRationItem';
import { AppliedRation } from '../../../../redux/slice/myRationSlice';
import ChoosePic from '../../../../asset/icons/ration/applied-ration-no-info.svg';

interface FutureRationsTabProps {
  rations: AppliedRation[];
  onCancelRation: (id: string) => void;
}

const FutureRationsTab = ({
  rations,
  onCancelRation,
}:FutureRationsTabProps) => (
  <div className="applied-rations__tab-content">
    {rations.length > 0 ? (
      <div className="past-rations__group">
        {rations.map((ration) => (
          <AppliedRationItem
            key={ration.id}
            ration={ration}
            onCancelRation={() => onCancelRation(ration.id)}
            showCancelButton
          />
        ))}
      </div>
    ) : (
      <div className="empty-info">
        <p><FormattedMessage id="ration.not.applied.message" /></p>
        <img src={ChoosePic} alt="Apply ration" />
      </div>
    )}
  </div>
);

export default FutureRationsTab;
