import React from 'react';
/* eslint-disable
 */
import Select, { StylesConfig } from 'react-select';
import { IdToName } from '../../../redux/slice/mealSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import './MealTimeFilter.scss';
/* eslint-enable
 */
export const selectStyle: StylesConfig = {
  control: (provided) => ({
    ...provided,
    borderColor: '#dedede',
    fontWeight: '400',
    textAlign: 'center',
    width: '150px',
    color: '#77838f;',
    minHeight: '26px',
    height: '26px',
    fontSize: '14px',
    backgroundColor: '#f7f8f9',
    borderRadius: '8px',
    '&:hover': {
      borderColor: '#dedede',
      backgroundColor: '#f1f2f3',
    },
    zIndex: 5,
  }),
  menuList: (base) => ({
    ...base,
    height: 'fit-content',
    padding: '0px 0 10px 0',
    margin: '0',
    '::-webkit-scrollbar': {
      width: '4px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#06c78c',
      borderRadius: 4,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '26px',
    padding: '0 6px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '26px',
    padding: '0px',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: 'center',
    /* eslint-disable-next-line */
    backgroundColor: state.isSelected ? '#06C78C' : state.isFocused ? '#f0f0f0' : 'white',
    color: state.isSelected ? 'white' : '#77838f',
    '&:hover': {
      backgroundColor: state.isSelected ? '#06C78C' : '#f0f0f0',
      color: state.isSelected ? 'white' : 'black',
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 4,
    marginTop: '-10px',
    paddingTop: '15px',
    width: '98%',
    marginLeft: '1px',
    color: '#77838f;',
    borderRadius: '8px',
    overflow: 'hidden',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#77838f',
  }),
};

export interface KeyValuePair {
  key: string;
  availableValue: number;
  selectedValue: number;
}

interface FilterComponentProps {
  availableMealTimes: IdToName[],
  selectedMealTime: { key: string, value: string[] },
  onChange: (...params: { key: string; value: any }[]) => void
}

const MealTimeFilter = ({
  availableMealTimes,
  selectedMealTime,
  onChange,
}: FilterComponentProps) => {
  const intl = useIntl();

  function generateOptions(availableMealTimes: IdToName[]) {
    return availableMealTimes.map((mealTime) => {
      const name = mealTime.name.toLowerCase();
      return {
        value: mealTime.name,
        label: intl.formatMessage({ id: `meal.list.${name}` }),
      };
    });
  }

  const mealTimeOptions = generateOptions(availableMealTimes);

  const selectedOption = mealTimeOptions.find(
    (option) => option.value === selectedMealTime.value[0],
  ) || mealTimeOptions[0];

  return (
    <div className="mealtime-filter">
      <label htmlFor="meal-time">
        <p>
          <FormattedMessage id='filters.meal.time' />
        </p>
        <Select
          id="meal-time"
          value={selectedOption}
          options={mealTimeOptions}
          styles={selectStyle}
          className="time-filter__select"
          classNamePrefix="time-filter"
          // @ts-ignore
          onChange={(e) => onChange({ key: selectedMealTime.key, value: [e.value] })}
        />
      </label>
    </div>
  );
};

export default MealTimeFilter;
