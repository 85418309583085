import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Ingredient } from '../../../../../../common/constant/interface/interfaces';
import Counter from '../../unitCounter/UnitCounter';
import UnitSelector from '../../unitSelect/UnitSelect';
import { APP_PATH, BACKEND_INGREDIENTS_PARAMS } from '../../../../../../common/constant/pathConstant';

const MIN_SELECT_VALUE = 1;
const MAX_SELECT_VALUE = 999;

export interface IngredientChangeParams {
  ingredient: Ingredient,
  selectedUnitName: string,
  selectedValue: number
}

interface AddIngredientItemProps {
    ingredient: Ingredient;
    selected: boolean;
    value: number;
    unitName: string;
    onValueChange: (params:IngredientChangeParams) => void;
    onUnitChange: (params:IngredientChangeParams) => void;
    onSelect: (params:IngredientChangeParams) => void;
}

const AddIngredientItem = ({
  ingredient, selected, value, unitName, onSelect, onValueChange, onUnitChange,
}: AddIngredientItemProps) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedUnitName, setSelectedUnitName] = useState(unitName);

  const selectedCalorie = ingredient.units[0].nutrition.calorie;

  useEffect(() => {
    onValueChange({ ingredient, selectedUnitName, selectedValue });
  }, [selectedValue, ingredient.id]);

  useEffect(() => {
    onUnitChange({ ingredient, selectedUnitName, selectedValue });
  }, [selectedUnitName, ingredient.id]);

  return (
    <button
      type="button"
      onClick={() => onSelect({ ingredient, selectedUnitName, selectedValue })}
      className={`tab-items__item ${selected ? 'active' : ''}`}
    >
      <div className="tab-items__left">
        <img
          className="tab-items__pic"
          src={ingredient.images?.[0]?.url}
          alt={ingredient.name}
        />
        <div className="tab-items__descr">
          <Link
            to={`${APP_PATH.APP_INGREDIENTS}?${BACKEND_INGREDIENTS_PARAMS.INGREDIENT_ID}=${ingredient.id}`}
            title={ingredient.name}
            target='blank'
          >
            {ingredient.name}
          </Link>
          <p>
            {selectedCalorie}
            {' '}
            <FormattedMessage id="meal.item.kcal" />
          </p>
        </div>
      </div>
      <div className="tab-items__right">
        <button
          className="tab-items__weight"
          onClick={(e) => e.stopPropagation()}
        >
          <Counter
            min={MIN_SELECT_VALUE}
            max={MAX_SELECT_VALUE}
            initialValue={selectedValue}
            onChange={(newValue) => setSelectedValue(Number(newValue))}
          />
          <UnitSelector
            units={ingredient.units}
            onChange={(newUnitName) => setSelectedUnitName(newUnitName)}
          />
        </button>
      </div>
    </button>
  );
};

export default AddIngredientItem;
