import '../StarRating.scss';

export interface StarRatingProps {
  stars: number;
  amount?: number;
}

const StarRating = ({ stars, amount }: StarRatingProps) => {
  const renderedStars = [];
  for (let i = 1; i <= 5; i += 1) {
    if (i <= stars) {
      renderedStars.push(<span key={i} className="star filled" />);
    } else {
      renderedStars.push(<span key={i} className="star" />);
    }
  }
  return (
    <div className="star-rating">
      {renderedStars}
      {amount && <span className="feedback-ammount">{amount}</span>}
    </div>
  );
};

export default StarRating;
