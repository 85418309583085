import React from 'react';
import './ModalWindow.scss';

interface ModalWindowProps {
  handleCloseModal: () => void;
  children: React.ReactNode;
  customClassName?: string;
}

const ModalWindow = ({ handleCloseModal, children, customClassName }: ModalWindowProps) => (
  <>
    <div className={`modal-wrapper ${customClassName ?? ''}`}>
      <div className="modal-content">
        <button data-testid="btnModalClose" className="modal-content__close" onClick={handleCloseModal} />
        {children}
      </div>
    </div>
    <div className="background-layout" />
  </>
);

export default ModalWindow;
