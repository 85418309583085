import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { Form } from 'formik';
import FormField from '../../../../component/form/formField/formikField/FormField';
import emailSvg from '../../../../asset/icons/email.svg';
import { Button, ButtonType } from '../../../../component/form/button/Button';
import { useAppDispatch } from '../../../../hook/useAppDispatch';
import {
  ForgotPasswordFormType,
  ForgotPasswordStepProps,
  TIMER_SHOW_MESSAGE,
} from '../ForgotPassword';
import authService from '../../../../service/auth/authService';
import { setMessage } from '../../../../redux/slice/messageSlice';
import { convertToErrorMessage } from '../../../../service/message/messageConverter';

const ForgotPasswordStep1 = ({ formikProps, handleNextStep }: ForgotPasswordStepProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccesMessage, setShowSuccesMessage] = useState<boolean>(false);
  let timer: NodeJS.Timeout;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (showSuccesMessage) {
      timer = setTimeout(() => setShowSuccesMessage(false), TIMER_SHOW_MESSAGE);
    }

    return () => clearTimeout(timer);
  }, [showSuccesMessage]);

  const forgotPassword = async (values: ForgotPasswordFormType) => {
    setLoading(true);
    try {
      await authService
        .forgotPassword(values.username)
        .then(() => {
          setShowSuccesMessage(true);
          timer = setTimeout(() => handleNextStep(), TIMER_SHOW_MESSAGE);
        })
        .catch((error: any) => {
          dispatch(setMessage(convertToErrorMessage(error)));
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error: any) {
      dispatch(setMessage(convertToErrorMessage(error)));
      setLoading(false);
    }
  };
  return (
    <Form aria-controls="form" className="window__form" data-testid="form-forgotPassword">
      {showSuccesMessage ? (
        <p className="confirm-message">
          <img src={emailSvg} alt="icon" />
          <FormattedMessage id="profile.check.email.verificationCode" />
        </p>
      ) : (
        <div>
          <h1 className="window__title forgot__title">
            <FormattedMessage id="form.label.forgotPassword" />
          </h1>
          <p className="window__subtitle forgot__subtitle">
            <FormattedMessage id="form.label.forgot.desc" />
          </p>
          <div className="forgot__form">
            <FormField placeholder="form.label.usernameOrEmail" name="username" />
            <Button
              type={ButtonType.BUTTON}
              loading={loading}
              label="form.label.sendVerificationCode"
              onClick={() => forgotPassword(formikProps.values)}
            />
          </div>
        </div>
      )}
    </Form>
  );
};
export default ForgotPasswordStep1;
