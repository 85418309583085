import * as Yup from 'yup';
import {
  confirmPasswordValidRules,
  notEmptyStringValidRule,
  passwordValidationRules,
  userNameValidRules,
} from '../../rule/validationRules';

export const forgotPasswordSchema = Yup.object().shape({
  username: notEmptyStringValidRule,
  verificationCode: notEmptyStringValidRule,
  password: passwordValidationRules,
  confirmPassword: confirmPasswordValidRules,
});
