function mapObjectToSearchParams(dto: any): string {
  const params = new URLSearchParams();

  Object.keys(dto).forEach((key) => {
    const value = dto[key];
    if (value !== undefined && value !== null) {
      if (Array.isArray(value) && value.length > 0) {
        params.append(key, value.join(','));
      } else if (typeof value === 'number') {
        params.append(key, value.toString());
      } else if (typeof value === 'string' && value !== '') {
        params.append(key, value);
      }
    }
  });

  return params.toString();
}
const filterService = {
  mapObjectToSearchParams,
};

export default filterService;
