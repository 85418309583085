import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import MediaQuery from 'react-responsive';
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Search from '../../../component/search/Search';
import IngredientInfo from '../IngredientInfo';
import './IngredientList.scss';
import ChoosePic from '../../../asset/icons/ingredient/choose.svg';
import { useAppDispatch } from '../../../hook/useAppDispatch';
import {
  getIngredientGroups,
  getIngredients,
  searchIngredients,
  getIngredientById,
} from '../../../redux/slice/ingredientSlice';
import { useAppSelector } from '../../../hook/useAppSelector';
import LoadingSpinner from '../../../component/spinner/LoadingSpinner';
import { RootState } from '../../../redux';
import { MOBILE_WIDTH } from '../../../common/constant/deviceCheckpoints';
import { BACKEND_INGREDIENTS_PARAMS } from '../../../common/constant/pathConstant';
import { Ingredient, IngredientGroup } from '../../../common/constant/interface/interfaces';

const INGREDIENTS_SEARCH_COUNT_SIZE = 10;

const IngredientList = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedGroup, setSelectedGroup] = useState<IngredientGroup | null>(null);
  const [selectedIngredient, setSelectedIngredient] = useState<Ingredient | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>(searchParams.get(BACKEND_INGREDIENTS_PARAMS.NAME) || '');
  const [loading, setLoading] = useState(false);
  const ingredientListRef = useRef<HTMLDivElement>(null);

  const ingredientGroups = useAppSelector((state: RootState) => state.ingredient.ingredientGroups);
  const nextGroupCursorId = useAppSelector((state) => state.ingredient.nextGroupCursorId);
  const searchResults = useAppSelector((state) => state.ingredient.search.searchResults);
  const nextSearchCursorId = useAppSelector((state) => state.ingredient.search.searchNextCursorId);

  useEffect(() => {
    if (!ingredientGroups.length) {
      dispatch(getIngredientGroups({
        cursorId: '',
        size: INGREDIENTS_SEARCH_COUNT_SIZE,
        level: 1,
      }));
    }
  }, [dispatch, ingredientGroups]);

  useEffect(() => {
    if (ingredientListRef.current) {
      ingredientListRef.current.style.overflow = searchQuery ? 'hidden' : 'visible';
    }
  }, [searchQuery]);

  useEffect(() => {
    if (searchQuery) {
      setLoading(true);
      dispatch(searchIngredients({
        query: searchQuery,
        cursorId: '',
        size: INGREDIENTS_SEARCH_COUNT_SIZE,
      })).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, searchQuery]);

  function setIngredient(ingredient: Ingredient) {
    if (ingredient) {
      setSelectedIngredient(ingredient);
      const ingredientGroup = ingredientGroups.find((el) => el.id === ingredient.group?.id) || null;
      setSelectedGroup(ingredientGroup);
      if (ingredientGroup && !ingredientGroup.ingredients) {
        dispatch(
          getIngredients({
            cursorId: '',
            size: INGREDIENTS_SEARCH_COUNT_SIZE,
            groupId: ingredientGroup.id,
          }),
        );
      }
    }
  }

  useEffect(() => {
    const ingredientId = searchParams.get(BACKEND_INGREDIENTS_PARAMS.INGREDIENT_ID);
    if (ingredientId && !selectedIngredient) {
      dispatch(getIngredientById(ingredientId))
        .then((response) => {
          setIngredient(response.payload as Ingredient);
        });
    }
  }, [dispatch, searchParams]);

  const loadMoreSearchResults = () => {
    if (!nextSearchCursorId) return;
    setLoading(true);
    dispatch(searchIngredients({
      query: searchQuery,
      cursorId: nextSearchCursorId,
      size: INGREDIENTS_SEARCH_COUNT_SIZE,
    })).catch(() => {
    }).finally(() => setLoading(false));
  };

  const loadMoreIngredientGroups = () => {
    if (!nextGroupCursorId) return;
    dispatch(
      getIngredientGroups({
        cursorId: nextGroupCursorId,
        size: INGREDIENTS_SEARCH_COUNT_SIZE,
        level: 1,
      }),
    );
  };

  const onIngredientClickHandler = (ingredient: Ingredient) => {
    setSelectedIngredient(ingredient);
    setSearchParams(
      (prev) => {
        prev.set(BACKEND_INGREDIENTS_PARAMS.INGREDIENT_ID, ingredient.id);
        return prev;
      },
      { replace: true },
    );

    if (window.innerWidth <= MOBILE_WIDTH) {
      setTimeout(() => {
        const section = document.querySelector(`[id="${ingredient.id}"]`);
        section?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 300);
    }
  };

  const onGroupClickHandler = (group: IngredientGroup) => {
    if (selectedGroup?.id !== group.id) {
      setSelectedGroup(group);
      if (!group.ingredients) {
        dispatch(
          getIngredients({
            cursorId: '',
            size: INGREDIENTS_SEARCH_COUNT_SIZE,
            groupId: group.id,
          }),
        );
      }
    }
  };

  return (
    <section className="ingredient">
      <div className="ingredient__left-container">
        <Search
          searchQueryKey={BACKEND_INGREDIENTS_PARAMS.NAME}
          onSearch={(query) => setSearchQuery(query)}
        />
        <div className="ingredient__list">
          {searchQuery ? (
            <InfiniteScroll
              dataLength={searchResults?.length || 0}
              next={loadMoreSearchResults}
              hasMore={!!nextSearchCursorId}
              height="400px"
              loader={loading && <LoadingSpinner />}
            >
              <div className="ingredient__search-results">
                {searchResults?.map((ingredient) => (
                  <div key={ingredient.id}>
                    <button
                      type="button"
                      onClick={() => onIngredientClickHandler(ingredient)}
                      className={`ingredient__sublist-item ${
                        selectedIngredient?.name === ingredient.name
                          && 'ingredient__sublist-item_active'
                      }`}
                    >
                      {ingredient.name}
                    </button>
                    <div
                      className={`ingredient__info-wrapper ${
                        selectedIngredient?.name === ingredient.name
                          && 'ingredient__info-wrapper_active'
                      }`}
                    >
                      {selectedIngredient && (
                        <MediaQuery maxWidth={MOBILE_WIDTH}>
                          <IngredientInfo ingredient={selectedIngredient} />
                        </MediaQuery>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              dataLength={ingredientGroups.length}
              next={loadMoreIngredientGroups}
              hasMore={!!nextGroupCursorId}
              height="400px"
              loader={loading && <LoadingSpinner />}
            >
              {ingredientGroups.map((group) => (
                <div key={group.name}>
                  <button
                    type="button"
                    onClick={() => onGroupClickHandler(group)}
                    className={`ingredient__group-item ${
                      selectedGroup?.id === group.id && 'ingredient__group-item_active'
                    }`}
                    id={`ingredient__group-item_${group.id}`}
                  >
                    <span>{group.name}</span>
                  </button>
                  <div
                    className={`ingredient__sublist ${
                      selectedGroup?.id === group.id && 'ingredient__sublist_active'
                    }`}
                  >
                    <div className="ingredient__sublist-item-inner">
                      {group.ingredients?.map((ingredient) => (
                        <div
                          className="ingredient__sublist-item-wrapper"
                          key={ingredient.id}
                        >
                          <button
                            type="button"
                            id={ingredient.id}
                            onClick={() => onIngredientClickHandler(ingredient)}
                            className={`ingredient__sublist-item ${
                              selectedIngredient?.name === ingredient.name
                                && 'ingredient__sublist-item_active'
                            }`}
                          >
                            {ingredient.name}
                          </button>
                          <div
                            className={`ingredient__info-wrapper ${
                              selectedIngredient?.name === ingredient.name
                                && 'ingredient__info-wrapper_active'
                            }`}
                          >
                            {selectedIngredient && (
                              <MediaQuery maxWidth={MOBILE_WIDTH}>
                                <IngredientInfo ingredient={selectedIngredient} />
                              </MediaQuery>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </div>
      <div className="ingredient__right-container">
        {selectedIngredient ? (
          <MediaQuery minWidth={MOBILE_WIDTH}>
            <IngredientInfo ingredient={selectedIngredient} />
          </MediaQuery>
        ) : (
          <div className="ingredient__empty-info">
            <p>
              <FormattedMessage id="ingredient.empty.info" />
            </p>
            <img src={ChoosePic} alt="Choose ingredient" />
          </div>
        )}
      </div>
    </section>
  );
};

export default IngredientList;
