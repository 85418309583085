import React from 'react';
import SearchFilter from '../SearchFilter';
import { Ingredient, Meal, Tag } from '../../../../common/constant/interface/interfaces';
import { RationSearchSelectedParam } from '../../../../redux/slice/rationSlice';
import { MealSearchSelectedParam } from '../../../../redux/slice/mealSlice';

export interface AvailableObject {
  content: Meal[] | Tag[] | Ingredient[],
  cursorId: string
}

export interface SearchObject {
  name: string;
  cursorId: string;
  searchResult: Meal[] | Tag[] | Ingredient[];
}

export interface SearchFilterWrapperProps {
  filterType: 'tags' | 'meals' | 'ingredients';
  searchObj: SearchObject;
  availableObj: AvailableObject;
  selectedObj: RationSearchSelectedParam | MealSearchSelectedParam;
  countSize: number;
  handleSearchObjUpdate: (param: { key: string; value: any }) => void;
  searchItems: (params: any) => void;
  getItems: (params: any) => void;
}

const DynamicSearchFilterWrapper: React.FC<SearchFilterWrapperProps> = ({
  filterType,
  searchObj,
  availableObj,
  selectedObj,
  countSize,
  handleSearchObjUpdate,
  searchItems,
  getItems,
}) => {
  const hasMoreItemsToLoad = searchObj.name
    ? searchObj.cursorId !== undefined
    : availableObj.cursorId !== undefined;
  // @ts-ignore
  const selectedItems = selectedObj[filterType];

  const getItemsToSelect = () => (searchObj.name
    ? searchObj.searchResult.map((el: any) => ({
      label: el.name,
      id: el.id,
      color: el.color,
    }))
    : availableObj.content.map((el: any) => ({
      label: el.name,
      id: el.id,
      color: el.color,
    })));

  const loadItems = async () => {
    if (searchObj.name) {
      await searchItems({
        cursorId: searchObj.cursorId,
        size: countSize,
        name: searchObj.name,
        overridePrev: false,
      });
    } else {
      await getItems({
        cursorId: availableObj.cursorId,
        size: countSize,
      });
    }
  };

  const searchItemsHandler = async (name: string) => {
    await searchItems({
      cursorId: undefined,
      size: countSize,
      name,
      overridePrev: true,
    });
  };

  return (
    <SearchFilter
      titleId={`filters.${filterType}`}
      filterType={filterType}
      itemsToSelect={getItemsToSelect()}
      loadItems={loadItems}
      searchItems={searchItemsHandler}
      hasMoreItemsToLoad={hasMoreItemsToLoad}
      selectedItems={selectedItems}
      onChange={(value: any) => handleSearchObjUpdate({ key: filterType, value })}
    />
  );
};

export default DynamicSearchFilterWrapper;
