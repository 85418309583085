import { useState } from 'react';
import '../StarRating.scss';

export interface StarRatingProps {
  stars: number;
  amount?: number;
  onRatingChange: (rating: number) => void;
}

const SelectableStarRating = ({ stars, amount, onRatingChange }: StarRatingProps) => {
  const [hoveredStar, setHoveredStar] = useState<number | null>(null);
  const [selectedRating, setSelectedRating] = useState<number>(stars);

  const handleMouseEnter = (index: number) => {
    setHoveredStar(index);
  };

  const handleMouseLeave = () => {
    setHoveredStar(null);
  };

  const handleStarClick = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    e.preventDefault();
    setSelectedRating(index);
    onRatingChange(index);
  };

  const renderedStars = [];
  for (let i = 1; i <= 5; i += 1) {
    const isFilled = hoveredStar ? i <= hoveredStar : i <= selectedRating;
    renderedStars.push(
      <button
        key={i}
        className={`selectable-star ${isFilled ? 'filled' : ''}`}
        onMouseEnter={() => handleMouseEnter(i)}
        onMouseLeave={handleMouseLeave}
        onClick={(e) => handleStarClick(e, i)}
      />,
    );
  }

  return (
    <div className="star-rating">
      {renderedStars}
      {amount && <span className="feedback-amount">{amount}</span>}
    </div>
  );
};

export default SelectableStarRating;
