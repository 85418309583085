import React, { useState, useRef, useEffect } from 'react';
import '../Ration.scss';
import { FormattedMessage } from 'react-intl';
import DishItem from './DishItem';
import { ReactComponent as ArrowUp } from '../../../asset/icons/dashboard/arrow-down.svg';
import { FoodType } from '../../../common/constant/interface/interfaces';

interface RationItemProps {
  titleMealType: { title: string; time: string };
  foodType: FoodType;
}

const FoodTypeItem = ({ titleMealType, foodType }: RationItemProps) => {
  const { items, nutrition } = foodType;
  const [isExpanded, setIsExpanded] = useState(true);
  const dishesRef = useRef<HTMLDivElement>(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (!isExpanded && dishesRef.current) {
      dishesRef.current.style.maxHeight = '0';
    } else if (dishesRef.current) {
      dishesRef.current.style.maxHeight = `${dishesRef.current.scrollHeight}px`;
    }
  }, [isExpanded, foodType]);

  return (
    <div className="meal-item">
      <div className="meal-item__header">
        <div className="meal-nameAndTime">
          <div className="meal-nameAndTime__name">
            <FormattedMessage id={titleMealType.title} />
          </div>
          <div className="meal-nameAndTime__time">{titleMealType.time}</div>
        </div>
        <button
          type="button"
          onClick={toggleExpand}
          className={`expand-btn ${isExpanded ? 'expanded' : ''}`}
        >
          <ArrowUp />
        </button>
      </div>
      <div ref={dishesRef} className={`dishes ${isExpanded ? 'expanded' : ''}`}>
        {items.map((dish) => (
          <DishItem
            key={dish.id}
            image={dish.images[0].url}
            name={dish.name}
            kcal={dish.nutrition.calorie}
            weight={dish.unit.value}
            mealId={dish.mealId ?? ''}
            ingredientId={dish.ingredientId ?? ''}
          />
        ))}
        <div className="macronutrients">
          <ul className="macronutrients__list">
            <li className="macronutrients__item">
              <span className="macronutrients__name">
                <FormattedMessage id="dashboard.title.kcal" />
              </span>
              <span className="macronutrients__value" data-testid="Kcal">
                {nutrition.calorie}
              </span>
            </li>
            <li className="macronutrients__item">
              <span className="macronutrients__name">
                <FormattedMessage id="dashboard.title.proteins" />
              </span>
              <span className="macronutrients__value" data-testid="Proteins">
                {nutrition.protein}
              </span>
            </li>
            <li className="macronutrients__item">
              <span className="macronutrients__name">
                <FormattedMessage id="dashboard.title.fats" />
              </span>
              <span className="macronutrients__value" data-testid="Fats">
                {nutrition.fat}
              </span>
            </li>
            <li className="macronutrients__item">
              <span className="macronutrients__name">
                <FormattedMessage id="dashboard.title.carbohydrates" />
              </span>
              <span className="macronutrients__value" data-testid="Carbohydrates">
                {nutrition.carbo}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FoodTypeItem;
