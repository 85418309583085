import './AuthBackgroudLayout.scss';

interface AuthBackgroudLayoutProps {
  children: React.ReactNode;
  currentStep?: number;
}

const AuthBackgroudLayout = ({ children, currentStep }: AuthBackgroudLayoutProps) => (
  <div className={`sign-in ${currentStep !== undefined && currentStep >= 3 ? 'sign-in--minimal' : ''}`}>
    <div className="sign-in__container">{children}</div>
  </div>
);

export default AuthBackgroudLayout;
